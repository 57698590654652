import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScriptService {
  private readonly renderer: Renderer2;

  public constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
    this.document = document;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public createScriptFromText(scriptText: string) {
    const script: HTMLScriptElement = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = scriptText;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  public createScriptsFromUrls(scriptsUrls: string[], mode: 'serial' | 'parallel' = 'serial'): Promise<void> {
    if (mode === 'serial') {
      return this.createScriptsFromUrlsInSeries(scriptsUrls);
    }
    return this.loadScriptsFromUrlInParallel(scriptsUrls);
  }

  public async createScriptsFromUrlsInSeries(urls: string[]): Promise<void> {
    for (const url of urls) {
      await this.createScriptFromUrl(url);
    }
  }

  public async loadScriptsFromUrlInParallel(urls: string[]): Promise<void> {
    urls.forEach(async url => {
      await this.createScriptFromUrl(url);
    });
  }

  public createScriptFromUrl(scriptUrl: string): Promise<HTMLScriptElement> {
    return new Promise(resolve => {
      const script: HTMLScriptElement | any = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = scriptUrl;
      if (script.readyState) {
        // IE
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            resolve(script);
          }
        };
      } else {
        // Others
        script.onload = () => {
          resolve(script);
        };
      }
      script.onerror = () => resolve(script);
      this.renderer.appendChild(this.document.head, script);
    });
  }
}
