import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ICriarSimulacaoPlanoSiteResponse, ICriarSimulacaoSiteRequest } from '@quantaprevidencia/quanta-domains';
import { SimulationType } from '@pages/plan-simulation/plan-simulation.enum';
import { PlanSimulationPayloadCreator } from '@pages/plan-simulation/plan-simulation-payload-creator';
import { MixPanelService } from '@shared/services/mix-panel/mix-panel.service';
import { PlanSimulationStoreService } from '@pages/plan-simulation/plan-simulation-store.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { dayMonthYearLimitValidator } from '@components/forms/date.validator';
import { formatDateBRToEN } from '@shared/functions/functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseSimulationComponent } from '@pages/plan-simulation/base-simulation.component';
import { EventsMixPanelEnum } from '@shared/mixpanel/enums/events-mixpanel-enum';
import { ParametrizationService } from '@shared/services/parametrization/parametrization.service';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';
import { disableFormOnLoading } from '@shared/functions/functions';

@Component({
  selector: 'app-fast-simulation',
  templateUrl: './fast-simulation.component.html',

  styleUrls: ['../plan-simulation.component.scss'],
  providers: [PlanSimulationPayloadCreator],
})
export class FastSimulationComponent extends BaseSimulationComponent implements OnInit {
  @Output()
  public changeSimulationEvent = new EventEmitter<ICriarSimulacaoSiteRequest>();

  public simulation: ICriarSimulacaoPlanoSiteResponse;
  public isLoading: boolean;
  public form: FormGroup;

  public constructor(
    private fb: FormBuilder,
    private planSimulationPayloadCreator: PlanSimulationPayloadCreator,
    public planSimulationStoreService: PlanSimulationStoreService,
    public mixPanelService: MixPanelService,
    public modalService: NgbModal,
    public parametrizationService: ParametrizationService,
    public analyticsService: AnalyticsService
  ) {
    super(planSimulationStoreService, mixPanelService, modalService, parametrizationService, analyticsService);
    this.form = this.fb.group({
      birthdate: [
        null,
        [
          Validators.required,
          dayMonthYearLimitValidator(this.calculateMinYear(), 'min'),
          dayMonthYearLimitValidator(new Date(), 'max'),
        ],
      ],
      income: [null, [Validators.required, Validators.min(0), Validators.max(10000000)]],
    });
  }

  public ngOnInit() {
    this.mixpanelEvent = EventsMixPanelEnum.gosteiQueroContratarRapida;
    this.planSimulationStoreService.setData('selectedSimulation', SimulationType.fast);

    this.planSimulationStoreService.data$.subscribe(data => {
      this.isLoading = data.isLoading;
      disableFormOnLoading(this.form.controls, this.isLoading);
      this.simulation = data.simulation;
    });
  }

  public simulate() {
    if (this.form.invalid) {
      return;
    }

    const birthdate = formatDateBRToEN(this.form.getRawValue().birthdate);
    const defaultRisk = this.form.value.income * 60;

    const request = this.planSimulationPayloadCreator
      .setProponent(Number(this.form.value.income), birthdate)
      .setBaseBenefit()
      .setRisk('morte', 'valorCapitalSegurado', defaultRisk)
      .setRisk('invalidez', 'valorCapitalSegurado', defaultRisk)
      .create();

    this.lastRequest = request;
    this.changeSimulationEvent.emit(request);
  }

  public changeSimulation() {
    if (!this.simulationResult?.form) {
      return;
    }

    const resultForm = this.simulationResult.form.value;
    const birthdate = formatDateBRToEN(this.form.getRawValue().birthdate);
    const investmentProfile = this.getInvestmentProfile();

    let creator = this.planSimulationPayloadCreator
      .setProponent(resultForm.futureIncome, birthdate, investmentProfile)
      .setBaseBenefit()
      .setEmployerBenefit(resultForm.companyMonthlyContribution);
    creator = this.setProponentBenefit(creator);
    creator = this.setRisk(creator, 'death', this.simulationResult.deathSwitch.nativeElement.checked);
    creator = this.setRisk(creator, 'invalidity', this.simulationResult.invaliditySwitch.nativeElement.checked);

    const request = creator.create();

    this.lastRequest = request;
    this.changeSimulationEvent.emit(request);
  }

  public startFullSimulation() {
    this.planSimulationStoreService.setData('simulation', null);
    this.planSimulationStoreService.setData('selectedSimulation', SimulationType.full);
  }
}
