import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent {
  @Input()
  public displayProperty = 'name';

  @Input()
  public valueProperty = 'id';

  @Input()
  public options: any[];

  @Input()
  public selectedOption: any;

  @Input()
  public disabled = false;

  @Input()
  public customOptionClass: string;

  @Output()
  public optionSelected = new EventEmitter<any>();

  public onOptionSelected(option: any) {
    this.selectedOption = option;
    this.optionSelected.emit(option);
  }
}
