import { Injectable } from '@angular/core';
import packageInfo from '../../../../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  public get version(): string {
    return packageInfo.version;
  }
}
