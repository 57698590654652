import { GroupMixPanelEnum } from '@shared/mixpanel/enums/group-mixpanel-enum';

export abstract class EventMixPanel<T> {
  public group: GroupMixPanelEnum;
  public eventName: string;
  public props?: T;

  protected constructor(group: GroupMixPanelEnum, eventName: string, props?: T) {
    this.group = group;
    this.eventName = eventName;
    this.props = props;
  }
}
