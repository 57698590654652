import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MixPanelService } from '../mix-panel/mix-panel.service';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService implements OnDestroy {
  private statusChangeSubscription: Subscription;

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private translate: TranslateService,
    private mixPanel: MixPanelService,
    private cookieConsentService: NgcCookieConsentService
  ) {}

  public initialize() {
    this.translateCookieConsent();
    this.trackCookieConsent();
    this.checkCookieCreation();
  }

  public checkCookieConsent() {
    if (!this.cookieConsentService.hasConsented()) {
      this.clearCookies();
    }
  }

  public ngOnDestroy(): void {
    this.statusChangeSubscription?.unsubscribe();
  }

  private translateCookieConsent() {
    this.translate
      .get([
        'cookie.header',
        'cookie.message',
        'cookie.dismiss',
        'cookie.allow',
        'cookie.deny',
        'cookie.link',
        'cookie.policy',
      ])
      .subscribe(data => {
        this.cookieConsentService.getConfig().content = this.cookieConsentService.getConfig().content || {};
        // Override default messages with the translated ones
        this.cookieConsentService.getConfig().content.header = data['cookie.header'];
        this.cookieConsentService.getConfig().content.message = data['cookie.message'];
        this.cookieConsentService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.cookieConsentService.getConfig().content.allow = data['cookie.allow'];
        this.cookieConsentService.getConfig().content.deny = data['cookie.deny'];
        this.cookieConsentService.getConfig().content.link = data['cookie.link'];
        this.cookieConsentService.getConfig().content.policy = data['cookie.policy'];

        this.cookieConsentService.destroy(); // remove previous cookie bar (with default messages)
        this.cookieConsentService.init(this.cookieConsentService.getConfig()); // update config with translated messages
      });
  }

  private trackCookieConsent() {
    this.statusChangeSubscription = this.cookieConsentService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      switch (event.status) {
        case 'allow':
          break;
        case 'deny':
          this.clearCookies();
          break;
      }
    });
  }

  private checkCookieCreation() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.checkCookieConsent();
    });
  }

  private clearCookies() {
    if (!this.document.cookie) {
      return;
    }

    const cookies = this.document.cookie.split(';');
    cookies.forEach(cookie => {
      const [name] = cookie.split('=');
      if (name.trim() === this.cookieConsentService.getConfig().cookie.name) {
        return;
      }

      this.document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;max-age=0;path=/;`;
    });
  }
}
