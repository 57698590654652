import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimulationType } from '@pages/plan-simulation/plan-simulation.enum';
import { PlanSimulationStoreService } from '@pages/plan-simulation/plan-simulation-store.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface IEmailModalEvent {
  nome: string;
  email: string;
}

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['../plan-simulation.component.scss'],
})
export class EmailModalComponent implements OnInit {
  @Output()
  public emailModalEvent = new EventEmitter<IEmailModalEvent>();

  public form: FormGroup;
  public selectedSimulation: SimulationType;
  public simulationType = SimulationType;

  public constructor(
    private fb: FormBuilder,
    private planSimulationStoreService: PlanSimulationStoreService,
    private activeModal: NgbActiveModal
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      nome: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      emailConfirm: [null, [Validators.required, Validators.email]],
    });

    this.planSimulationStoreService.data$.subscribe(data => {
      this.selectedSimulation = data.selectedSimulation;
    });
  }

  public onClickSendSimulation() {
    const { nome, email } = this.form.value;
    this.emailModalEvent.emit({ nome, email });
    this.closeModal();
  }

  public closeModal() {
    this.activeModal.close();
  }
}
