<div class="w-100 h-100 d-flex flex-column simulator-panel-item {{ containerClass }} ">
  <label #valueRangeTooltip="ngbTooltip" class="label title {{ titleClass }}" ngbTooltip [triggers]="'manual'">{{ title }}</label>
  <div
    class="d-flex flex-row align-items-center input-container {{ valueClass }}"
    [style.fontSize.rem]="fontSize"
    [style.lineHeight.rem]="order === 0 ? 4.5 : 2.25"
    [style.justifyContent]="order === 0 ? 'center' : ''"
  >
    <label
      *ngIf="type === simulatorPanelItemEnum.currency"
      class="mr-1 label"
      >R$</label
    >
    <input
      #input
      class="input"
      inputmode="numeric"
      [disabled]="disabled"
      [maxLength]="maximumLength"
      (keypress)="onKeyPress($event)"
      (input)="onInput()"
    />
    <label *ngIf="type === simulatorPanelItemEnum.integer" class="ml-1 label">{{
      (value > 1 ? 'simulationPanelItem.years' : 'simulationPanelItem.year') | translate
    }}</label>
  </div>
  <div class="slider-container" *ngIf="!disabled">
    <app-slider-range
      [minimumValue]="minimumValue"
      [maximumValue]="maximumValue"
      [(value)]="value"
      [customClass]="customSliderClass"
      [lineColor]="sliderLineColor"
    ></app-slider-range>
  </div>
</div>
