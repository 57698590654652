<form [formGroup]="form">
  <div class="modal d-block d-flex align-items-center">
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          class="modal-header border-bottom-0 bg-primary py-2 d-flex align-items-center"
          [ngClass]="selectedSimulation === simulationType.fast ? 'bg-primary' : 'bg-purple-base'"
        >
          <span class="font-medium-1 text-white">{{ 'planSimulation.emailModal.title' | translate }}</span>
          <div (click)="closeModal()" class="cursor-pointer">
            <i class="ft-x text-white font-medium-1" aria-hidden="true"></i>
          </div>
        </div>
        <div class="modal-body">
          <span class="font-medium-1">{{ 'planSimulation.emailModal.subtitle' | translate }}</span>
          <div class="row mt-2">
            <div class="col-12">
              <label for="nameInput" class="font-weight-500 font-small-3">{{ 'common.name' | translate }}</label>
              <input formControlName="nome" class="w-100 input-white-darker-border rounded input-line-height pl-2 w-50" type="email" id="nameInput" placeholder="Romário de Oliveira"/>
            </div>
            <div class="col-xs-12 col-sm-6 mt-2">
              <label for="emailAddressInput" class="font-weight-500 font-small-3">{{ 'common.email' | translate }}</label>
              <input formControlName="email" class="w-100 input-white-darker-border rounded input-line-height pl-2" type="email" id="emailAddressInput" placeholder="romario@gmail.com"/>
            </div>
            <div class="col-xs-12 col-sm-6 mt-2">
              <label for="confirmEmailAddressInput" class="font-weight-500 font-small-3">{{ 'planSimulation.emailModal.confirmEmail' | translate }}</label>
              <input formControlName="emailConfirm" class="w-100 input-white-darker-border rounded input-line-height pl-2" type="email" id="confirmEmailAddressInput" placeholder="romario@gmail.com"/>
              <span class="font-small-1 text-danger" *ngIf="form.controls.emailConfirm.touched && form.controls.email.value !== form.controls.emailConfirm.value">*{{ 'planSimulation.emailModal.emailShouldBeEqual' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer border-top-0 d-flex justify-content-end">
          <button
            type="button"
            class="btn font-small-3"
            (click)="onClickSendSimulation()"
            onClick="this.disabled=true; this.innerText='Enviando…'"
            [disabled]="form.invalid || form.controls.email.value !== form.controls.emailConfirm.value"
            [ngClass]="selectedSimulation === simulationType.fast ? 'btn-primary' : 'btn-purple-base'"
          >
            {{ 'planSimulation.emailModal.sendSimulation' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
