import { Injectable } from '@angular/core';
import { TiposDeInvestimentoNomeEnum } from '@shared/services/investment/models/perfis-de-investimento.enum';
import { PlanosEnum } from '@shared/services/investment/models/planos.enum';
import {
  DEFAULT_RETIREMENT_AGE,
  DEFAULT_CPF,
  DEFAULT_UF,
  DEFAULT_CBO,
  DEFAULT_GENDER,
} from '@pages/plan-simulation/plan-simulation.enum';
import {
  ISimulacaoSiteProponenteRequest,
  ISimulacaoSiteBeneficioRequest,
  ISimulacaoSiteRiscos,
  ICriarSimulacaoSiteRequest,
} from '@quantaprevidencia/quanta-domains';

@Injectable()
export class PlanSimulationPayloadCreator {
  private proponent: ISimulacaoSiteProponenteRequest;
  private benefit: ISimulacaoSiteBeneficioRequest;
  private risk: ISimulacaoSiteRiscos;

  public constructor() {}

  public setProponent(income: number, birthdate: string, investmentProfile = TiposDeInvestimentoNomeEnum.MODERADO) {
    this.proponent = {
      valorRendaDeclarada: income,
      dataNascimento: birthdate,
      // TODO: remover ts-ignore após usar Domains V2
      // @ts-ignore
      perfilInvestimento: investmentProfile,
      cpf: DEFAULT_CPF,
      siglaUF: DEFAULT_UF,
      codGenero: DEFAULT_GENDER,
      ocupacao: {
        idCBO: DEFAULT_CBO,
      },
    };
    return this;
  }

  public setBaseBenefit(retirementAge: number = DEFAULT_RETIREMENT_AGE) {
    this.benefit = {
      idadeAposentadoria: retirementAge,
      previdencia: {
        codigoPlano: PlanosEnum.COOPREV,
      },
    };

    return this;
  }

  public setProponentBenefit(field: 'valorSaldoFuturo' | 'valorSaldoAtual' | 'valorContribuicaoMensal', value: number) {
    if (!value) {
      return this;
    }

    this.benefit.previdencia.valoresProponente = {
      ...this.benefit.previdencia.valoresProponente,
      [field]: value,
    };

    return this;
  }

  public setEmployerBenefit(companyContribution: number) {
    if (!companyContribution) {
      return this;
    }

    this.benefit.previdencia = {
      ...this.benefit.previdencia,
      valoresEmpregador: {
        valorContribuicaoMensal: companyContribution,
      },
    };

    return this;
  }

  public setRisk(
    risk: 'morte' | 'invalidez',
    field: 'valorContribuicaoMensal' | 'valorCapitalSegurado',
    value: number
  ) {
    this.risk = {
      ...this.risk,
      [risk]: {
        [field]: value,
      },
    };

    return this;
  }

  public create(): ICriarSimulacaoSiteRequest {
    let response: ICriarSimulacaoSiteRequest = {
      simulacao: {
        dataReferenciaSimulacao: new Date().toISOString().slice(0, 10),
        proponente: this.proponent,
        beneficio: this.benefit,
      },
    };

    response = {
      ...response,
      tipoSimulacao: this.getSimulationType(response.simulacao.beneficio.previdencia.valoresProponente),
    };

    if (this.risk) {
      response.simulacao = {
        ...response.simulacao,
        risco: this.risk,
      };
    }

    this.proponent = null;
    this.benefit = null;
    this.risk = null;

    return response;
  }

  private getSimulationType(proponent) {
    if (!proponent) {
      return 'Renda Futura';
    }

    if (proponent.valorSaldoAtual && !proponent.valorContribuicaoMensal && !proponent.valorSaldoFuturo) {
      return 'Renda Futura com Aporte';
    }

    if (proponent.valorContribuicaoMensal) {
      return 'Contribuição Mensal';
    }

    if (proponent.valorSaldoFuturo) {
      return 'Saldo Futuro';
    }

    return '';
  }
}
