import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

export const SIMULATOR_URL = new InjectionToken<string>('SIMULATOR_URL');

@Component({
  selector: 'app-simulators',
  templateUrl: './simulators.component.html',
  styleUrls: ['./simulators.component.scss'],
})
export class SimulatorsComponent implements OnInit {
  public simulatorUrl: string;

  public constructor(
    @Inject(SIMULATOR_URL) private readonly apiUrl: string,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public ngOnInit() {
    const url = this.router.url;
    const param = '/frame.php?';
    this.route.queryParams.subscribe(params => {
      if (url.includes(param)) {
        this.router.navigate(['/simuladores'], { queryParams: { ...params } });
      } else {
        this.simulatorUrl = this.getSimulatorUrl(params);
      }
    });
  }

  private getSimulatorUrl(params: Params) {
    if (!Object.entries(params).length) {
      return this.apiUrl;
    }
    const { acesso, codigo, method, pagina, plano } = params;
    return `${this.apiUrl}/frame.php?class=${params.class}&method=${method}&plano=${plano}&acesso=${acesso}&pagina=${pagina}&codigo=${codigo}`;
  }
}
