import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BFF_API_URL } from '../../../api/api.urls';
import { endpoints } from '../../../api/api.endpoints';
import { ICriarEventoRDStationV2 } from './models/create-rds-event.request';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private httpClient: HttpClient;

  public constructor(httpBackend: HttpBackend, @Inject(BFF_API_URL) private readonly apiUrl: string) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async createRDStationEvent(body: ICriarEventoRDStationV2): Promise<void> {
    const endpoint = `${this.apiUrl}${endpoints.analytics.createRDStationEvent}`;
    await this.httpClient.post(endpoint, body).toPromise();
  }
}
