/* eslint-disable no-underscore-dangle */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { from, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SimulacaoResponse } from '../../services/simulator/models/simulacao-response';
import { SimulatorService } from '../../services/simulator/simulator.service';
import { SimulatorPanelItemComponent } from '../simulator-panel-item/simulator-panel-item.component';
import { handleSimulationBirthDate } from '@pages/home/components/simulator-chat/simulator-chat.component';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';
import {
  SITE_QUANTA_CONTRATE_PLANO_URL,
  ID_CANAL,
  RDS_SIMULATION_EVENT_ID,
  RDS_SIMULATION_EVENT_TYPE,
} from '@shared/constants';
import {
  ICriarEventoRDStationV2,
  IEventoRDStationMetadado,
} from '@shared/services/analytics/models/create-rds-event.request';
import { SimulatorPanelItemEnum } from '../simulator-panel-item/simulator-panel-item.enum';
import { EventsMixPanelEnum } from '@shared/mixpanel/enums/events-mixpanel-enum';
import { MixPanelService } from '@shared/services/mix-panel/mix-panel.service';
import { GenericSimulatorEvent } from '@shared/mixpanel/events/simulator-events';
import { PlanProfileService } from '@shared/services/plans/plans.service';
import { Profile } from '@shared/services/plans/models/profiles.response';
import { ParametrizationService } from '@shared/services/parametrization/parametrization.service';
import { PlanosEnum, ProviderId } from '@shared/services/investment/models/planos.enum';
import { ToastrService } from 'ngx-toastr';

export type InvestmentObjective = 'monthlyIncome' | 'futureTotalBalance' | 'monthlyInvestment';

@Component({
  selector: 'app-simulator-panel',
  templateUrl: './simulator-panel.component.html',
  styleUrls: ['./simulator-panel.component.scss'],
})
export class SimulatorPanelComponent implements AfterViewInit, OnDestroy {
  @Input()
  public investmentKnowledge: string;

  @Input()
  public providerAccount: string;

  @Input()
  public contributionTime: number;

  @Input()
  public futureTotalBalance: number;

  @Input()
  public monthlyIncome: number;

  @Input()
  public monthlyInvestment: number;

  @Input()
  public retirementAge: number;

  @Input()
  public simulation: SimulacaoResponse;

  @Input()
  public investorProfile: string;

  @Output()
  public hirePlan = new EventEmitter();

  @Output()
  public receiveSimulationResultOnEmail = new EventEmitter<SimulacaoResponse>();

  @Output()
  public restartSimuation = new EventEmitter();

  @ViewChild('contributionTimeComponent', { static: true })
  public contributionTimeComponent: SimulatorPanelItemComponent;

  @ViewChild('futureTotalBalanceComponent', { static: true })
  public futureTotalBalanceComponent: SimulatorPanelItemComponent;

  @ViewChild('monthlyInvestmentComponent', { static: true })
  public monthlyInvestmentComponent: SimulatorPanelItemComponent;

  @ViewChild('monthlyIncomeComponent', { static: true })
  public monthlyIncomeComponent: SimulatorPanelItemComponent;

  @ViewChild('retirementAgeComponent', { static: true })
  public retirementAgeComponent: SimulatorPanelItemComponent;

  public simulationResponse: SimulacaoResponse;
  public personalInfo: Record<string, string>;
  public title: string;
  public simulatorPanelItemEnum = SimulatorPanelItemEnum;
  public profiles: Profile[];

  private age: number;
  private loadingSimulation: boolean;
  private readonly simulationSubject = new Subject();
  private simulationSource: InvestmentObjective;
  private simulationSubscription: Subscription;
  private _simulationType: InvestmentObjective;

  private get simulationType(): InvestmentObjective {
    return this._simulationType;
  }

  private set simulationType(value: InvestmentObjective) {
    this._simulationType = value;
    this.updateTitle();
    this.sortSimulatorPanelItems();
  }

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly simulatorService: SimulatorService,
    private readonly translateService: TranslateService,
    private readonly analyticsService: AnalyticsService,
    private readonly mixPanelService: MixPanelService,
    private readonly planProfileService: PlanProfileService,
    private readonly parametrizationService: ParametrizationService,
    private readonly toastrService: ToastrService,
    private ngZone: NgZone
  ) {
    this.simulationSubject.pipe(debounceTime(400)).subscribe(() => {
      this.simulationSubscription?.unsubscribe();
      this.simulationSubscription = from(this.simulate()).subscribe(simulationResponse => {
        this.loadSimulationResponse(simulationResponse);
      });
    });
  }

  public ngAfterViewInit(): void {
    this.loadSimulationParameters();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  public async showSimulation(simulation: SimulacaoResponse, simulationType: InvestmentObjective) {
    this.loadingSimulation = true;

    await this.setProfiles();

    this.simulationResponse = null;
    this.simulation = simulation;
    this.simulationType = simulationType;
    this.simulationSource = simulationType;

    const beneficio = simulation.simulacao.beneficio;
    const previdencia = simulation.simulacao.beneficio.previdencia;

    this.monthlyIncome = previdencia?.totalPrevidencia?.valorRendaPrazoIndeterminado;
    this.futureTotalBalance = previdencia?.totalPrevidencia?.valorSaldoFuturo;
    this.monthlyInvestment = previdencia?.valoresProponente?.valorContribuicaoMensal;
    this.retirementAge = beneficio?.idadeAposentadoria;

    // contribution time
    const now = DateTime.local();
    const birthDate = DateTime.fromISO(simulation.simulacao.proponente.dataNascimento);
    this.age = Math.floor(now.diff(birthDate, 'years').years);
    this.contributionTime = beneficio.idadeAposentadoria - this.age;

    // a idade mínima de recebimento é a idade atual + 1
    this.retirementAgeComponent.minimumValue = this.age + 1;

    this.sendRDStationEvent(RDS_SIMULATION_EVENT_TYPE.simulou);

    // isso foi adicionado para evitar que a mudança dos valores das variáveis dispare uma nova simulação
    // ngZone corrige o problema do iOS
    this.ngZone.run(() => {
      this.changeDetector.detectChanges();
    });

    this.loadingSimulation = false;
  }

  public onMonthlyIncomeChange(value: number) {
    this.monthlyIncome = value;
    if (this.loadingSimulation) {
      return;
    }

    this.simulationSource = 'monthlyIncome';
    this.simulationSubject.next();
  }

  public onFutureTotalBalanceChange(value: number) {
    if (this.loadingSimulation) {
      return;
    }

    this.simulationSource = 'futureTotalBalance';
    this.simulationSubject.next();
  }

  public onRetirementAgeChange(value: number) {
    if (this.loadingSimulation) {
      return;
    }

    this.simulationSubject.next();
  }

  public onmonthlyInvestmentChange(value: number) {
    if (this.loadingSimulation) {
      return;
    }

    this.simulationSource = 'monthlyInvestment';
    this.simulationSubject.next();
  }

  public onContributionTimeChange(value: number) {
    if (this.loadingSimulation) {
      return;
    }

    this.retirementAge = this.age + value;
    this.simulationSubject.next();
  }

  public onInvestorProfileChange(value: string) {
    if (this.loadingSimulation) {
      return;
    }

    this.investorProfile = value.toUpperCase();
    this.simulationSubject.next();
  }

  public async hirePlanButtonClick() {
    window.open(SITE_QUANTA_CONTRATE_PLANO_URL, '_blank');

    this.sendRDStationEvent(RDS_SIMULATION_EVENT_TYPE.contratar);

    this.mixPanelService.track(new GenericSimulatorEvent(EventsMixPanelEnum.gosteiQueroContratar));
  }

  public restartSimulationButtonClick() {
    this.restartSimuation.emit();
  }

  public receiveSimulationResultOnEmailButtonClick() {
    this.sendRDStationEvent(RDS_SIMULATION_EVENT_TYPE.email);
    this.receiveSimulationResultOnEmail.emit(this.simulationResponse);
  }

  private updateTitle() {
    switch (this._simulationType) {
      case 'futureTotalBalance':
        this.title = this.translateService.instant('simulationPanel.futureTotalBalance');
        break;
      case 'monthlyInvestment':
        this.title = this.translateService.instant('simulationPanel.monthlyInvestment');
        break;
      case 'monthlyIncome':
        this.title = this.translateService.instant('simulationPanel.monthlyIncome');
        break;
    }
  }

  private loadSimulationParameters() {
    this.simulatorService.getSimulationParameters().then(parameters => {
      const {
        simulationInvestmentTimeMin,
        simulationInvestmentTimeMax,
        simulationFutureTotalBalanceMin,
        simulationFutureTotalBalanceMax,
        simulationMonthlyInvestmentMin,
        simulationMonthlyInvestmentMax,
        simulationMonthlyIncomeMin,
        simulationMonthlyIncomeMax,
        simulationAgeToReceiveMax,
      } = parameters;

      this.contributionTimeComponent.minimumValue = simulationInvestmentTimeMin;
      this.contributionTimeComponent.maximumValue = simulationInvestmentTimeMax;

      this.futureTotalBalanceComponent.minimumValue = simulationFutureTotalBalanceMin;
      this.futureTotalBalanceComponent.maximumValue = simulationFutureTotalBalanceMax;

      this.monthlyInvestmentComponent.minimumValue = simulationMonthlyInvestmentMin;
      this.monthlyInvestmentComponent.maximumValue = simulationMonthlyInvestmentMax;

      this.monthlyIncomeComponent.minimumValue = simulationMonthlyIncomeMin;
      this.monthlyIncomeComponent.maximumValue = simulationMonthlyIncomeMax;

      // aqui é definido apenas a idade máxima, a idade mínima varia conforme a simulação apresentada
      this.retirementAgeComponent.maximumValue = simulationAgeToReceiveMax;
    });
  }

  private sortSimulatorPanelItems() {
    this.contributionTimeComponent.show();
    this.retirementAgeComponent.show();

    switch (this.simulationType) {
      case 'monthlyIncome':
        this.sortMonthlyIncome();
        break;
      case 'monthlyInvestment':
        this.sortMonthlyInvestment();
        break;
      case 'futureTotalBalance':
        this.sortFutureTotalBalance();
        break;
      default:
        break;
    }
  }

  private sortFutureTotalBalance() {
    this.futureTotalBalanceComponent.order = 0;
    this.monthlyInvestmentComponent.order = 1;
    this.retirementAgeComponent.order = 2;
    this.monthlyIncomeComponent.order = 3;
    this.contributionTimeComponent.hide();
  }

  private sortMonthlyIncome() {
    this.monthlyIncomeComponent.order = 0;
    this.futureTotalBalanceComponent.order = 1;
    this.retirementAgeComponent.order = 2;
    this.monthlyInvestmentComponent.order = 3;
    this.contributionTimeComponent.hide();
  }

  private sortMonthlyInvestment() {
    this.monthlyInvestmentComponent.order = 0;
    this.futureTotalBalanceComponent.order = 1;
    this.contributionTimeComponent.order = 2;
    this.monthlyIncomeComponent.order = 3;
    this.retirementAgeComponent.hide();
  }

  private async simulate(): Promise<SimulacaoResponse> {
    let monthlyIncome: number;
    let monthlyInvestment: number;
    let futureTotalBalance: number;

    const simulationType = this.simulationSource;
    switch (simulationType) {
      case 'monthlyIncome':
        monthlyIncome = this.monthlyIncome;
        break;
      case 'monthlyInvestment':
        monthlyInvestment = this.monthlyInvestment;
        break;
      case 'futureTotalBalance':
        futureTotalBalance = this.futureTotalBalance;
        break;
    }

    const birthDate = handleSimulationBirthDate(this.age);

    const simulation = {
      monthlyInvestment,
      monthlyIncome,
      futureTotalBalance,
      investorProfile: this.investorProfile,
    };

    const simulationResponse = await this.simulatorService.simulateAsync(birthDate, this.retirementAge, simulation);
    return simulationResponse;
  }

  private async loadSimulationResponse(response: SimulacaoResponse) {
    this.loadingSimulation = true;
    this.simulationResponse = response;

    const simulationType = this.simulationSource;
    const previdencia = response.simulacao.beneficio.previdencia;
    if (simulationType === 'monthlyInvestment') {
      this.monthlyIncome = previdencia.totalPrevidencia.valorRendaPrazoIndeterminado;
      this.futureTotalBalance = previdencia.totalPrevidencia.valorSaldoFuturo;
    } else if (simulationType === 'monthlyIncome') {
      this.monthlyInvestment = previdencia.valoresProponente.valorContribuicaoMensal;
      this.futureTotalBalance = previdencia.totalPrevidencia.valorSaldoFuturo;
    } else if (simulationType === 'futureTotalBalance') {
      this.monthlyInvestment = previdencia.valoresProponente.valorContribuicaoMensal;
      this.monthlyIncome = previdencia.totalPrevidencia.valorRendaPrazoIndeterminado;
    }

    this.sendRDStationEvent(RDS_SIMULATION_EVENT_TYPE.simulou);

    if (!(this.changeDetector as ViewRef).destroyed) {
      this.changeDetector.detectChanges();
    }

    this.loadingSimulation = false;
  }

  private generateMetadata() {
    return [
      {
        nome: 'cf_idade',
        valor: String(this.age),
      },
      {
        nome: 'cf_idade_futura',
        valor: String(this.retirementAge),
      },
      {
        nome: 'cf_renda_futura_desejada',
        valor: String(this.monthlyIncome),
      },
      {
        nome: 'cf_conhecimento_em_investimento',
        valor: this.investmentKnowledge,
      },
      {
        nome: 'cf_cooperativa_que_voce_tem_conta',
        valor: this.providerAccount,
      },
    ];
  }

  private sendRDStationEvent(tag: string) {
    if (!this.personalInfo) {
      return;
    }

    const metadados = this.generateMetadata();

    const event = this.generateRDStationEvent(tag, metadados);
    this.analyticsService.createRDStationEvent(event);
  }

  private generateRDStationEvent(tag: string, metadados: IEventoRDStationMetadado[] = []): ICriarEventoRDStationV2 {
    return {
      nome: this.personalInfo.name,
      email: this.personalInfo.email,
      idCanal: ID_CANAL,
      idTipoEvento: RDS_SIMULATION_EVENT_ID,
      tags: [tag],
      metadados,
    };
  }

  private async setProfiles() {
    try {
      const profiles = await this.planProfileService.getProfiles();
      this.profiles = await this.sortProfiles(profiles);
    } catch (_) {
      const title = this.translateService.instant('simulation.toast.fechProfilesError');
      this.toastrService.show('', title, null, 'toast-error');
    }
  }

  private async sortProfiles(profiles: Profile[]): Promise<Profile[]> {
    const profilesSequence = await this.getProfilesSequence();
    const orderedProfiles = profilesSequence?.map(profileId =>
      profiles.find(profile => profile.idPerfil === profileId)
    );
    return orderedProfiles;
  }

  private async getProfilesSequence() {
    const response = await this.parametrizationService.getParametersByKey('RENTABILIDADE_ORDENACAO_PERFIS');
    const profileSequence = response.valor.find(
      sequences => sequences.idPlano === (ProviderId[this.providerAccount?.toUpperCase()] || PlanosEnum.PRECAVER)
    );

    return profileSequence?.ordemPerfis;
  }
}
