import { NgModule } from '@angular/core';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '../../../../environments/environment';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
    name: 'cookieconsent_status',
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#f2ffe2',
      text: '#98bf3e',
      link: '#98bf3e',
    },
    button: {
      background: '#98bf3e',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'opt-out',
  content: {
    href: `http://${environment.cookieDomain}/home/politica-de-privacidade`,
  },
};

@NgModule({
  imports: [NgcCookieConsentModule.forRoot(cookieConfig)],
  exports: [],
})
export class CookieConsentModule {}
