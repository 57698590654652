import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private readonly headScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W788KZ4');`;
  private readonly bodyScript = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W788KZ4"height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  private readonly gtagScript = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date());gtag("config", "UA-150365001-1");`;

  private renderer: Renderer2;
  public constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
    private environmentService: EnvironmentService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public initialize() {
    if (!this.environmentService.production) {
      return;
    }
    const headElement = this.renderer.createElement('script');
    headElement.type = 'text/javascript';
    headElement.text = `${this.headScript}${this.gtagScript}`;
    this.renderer.appendChild(this.document.head, headElement);
    const bodyElement = this.renderer.createElement('noscript');
    bodyElement.type = 'text/javascript';
    bodyElement.text = this.bodyScript;
    this.renderer.appendChild(this.document.body, bodyElement);
  }
}
