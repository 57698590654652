/* eslint-disable no-underscore-dangle */
import { AnimationEvent } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OverlayService } from '../../../../shared/services/overlay/overlay.service';
import {
  exploreIconAnimation,
  exploreTextAnimation,
  exploreToggleAnimation,
  toggleExploreAnimation,
} from './explore.animations';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
  animations: [toggleExploreAnimation, exploreToggleAnimation, exploreIconAnimation, exploreTextAnimation],
})
export class ExploreComponent {
  @Input()
  public closeExploreHandler: (emitEvent?: boolean) => boolean;

  @Output()
  public closed = new EventEmitter();

  @Output()
  public closing = new EventEmitter();

  @Output()
  public opened = new EventEmitter();

  @Output()
  public opening = new EventEmitter();

  public right: number;
  public showCloseButton: boolean;
  public state: 'closed' | 'open';
  public width: number;

  public get isOpen() {
    return this._isOpen;
  }

  public set isOpen(value: boolean) {
    if (this._isOpen === value) {
      return;
    }

    this._isOpen = value;
    this.onOpenChanged(value);
  }

  private _isOpen: boolean;

  public constructor(deviceDetectorService: DeviceDetectorService, private modalService: OverlayService) {
    this.state = 'closed';
    this.isOpen = false;
    this.showCloseButton = deviceDetectorService.isMobile();
    this.updateWidth();
  }

  @HostListener('window:resize', [])
  public onResize() {
    this.updateWidth();
  }

  public toggleExplore() {
    if (this.isOpen) {
      this.closeExplore(true);
    } else {
      this.openExplore();
    }
  }

  public openExplore() {
    this.isOpen = true;
  }

  public closeExplore(emitEvent: boolean = false) {
    if (!this.closeExploreHandler) {
      this.isOpen = false;
      return;
    }

    this.isOpen = !this.closeExploreHandler(emitEvent);
  }

  public onOpenAnimationStart($event: AnimationEvent) {
    if (this.isClosingAnimation($event)) {
      this.closing.emit();
      return;
    }

    if (this.isOpeningAnimation($event)) {
      this.opening.emit();
    }
  }

  public onOpenAnimationDone($event: AnimationEvent) {
    if (this.isClosingAnimation($event)) {
      this.closed.emit();
      return;
    }

    if (this.isOpeningAnimation($event)) {
      this.opened.emit();
    }
  }

  private onOpenChanged(value: boolean) {
    this.state = value ? 'open' : 'closed';
    this.modalService.setOverlayVisibility(value);
    this.updateWidth();
  }

  private isClosingAnimation($event: AnimationEvent): boolean {
    return $event.fromState === 'open' && $event.toState === 'closed';
  }

  private isOpeningAnimation($event: AnimationEvent): boolean {
    return $event.fromState === 'closed' && $event.toState === 'open';
  }

  private updateWidth() {
    const borderWidth = 10;
    const maxWidth = 480;
    const width = Math.min(maxWidth, window.innerWidth);
    this.width = this.state === 'open' ? width + borderWidth : width;
    this.right = this.state === 'open' ? 0 : (width - borderWidth) * -1;
  }
}
