import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '@environments/environment';
import { BFF_API_URL, QUANTA_API_URL } from '@api/api.urls';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SIMULATOR_URL } from '@pages/simulators/simulators.component';
import { WORDPRESS_URL } from '@components/wordpress/services/wordpress.service';
import { CookieConsentModule } from '@shared/services/cookie-consent/cookie-consent.module';
import { NEO_ASSIST_DOMAIN } from '@shared/services/neo-assist/neo-assist.service';
import { LottieModule } from 'ngx-lottie';
import { ToastrModule } from 'ngx-toastr';
import player from 'lottie-web';

const appInitializerFactory = (translate: TranslateService, injector: Injector) => {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        translate.setDefaultLang('pt');
        translate.use('pt').subscribe(
          _next => {},
          _error => {},
          () => {
            resolve(null);
          }
        );
      });
    });
};

const createHttpLoaderFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
};

export const playerFactory = () => player;

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CookieConsentModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    LottieModule.forRoot({ player: playerFactory }),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: BFF_API_URL, useValue: environment.bffApiUrl },
    { provide: QUANTA_API_URL, useValue: environment.quantaApiUrl },
    { provide: NEO_ASSIST_DOMAIN, useValue: environment.neoAssistDomain },
    { provide: SIMULATOR_URL, useValue: environment.simulatorUrl },
    { provide: WORDPRESS_URL, useValue: environment.wordpressUrl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
