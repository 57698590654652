import { AbstractControl } from '@angular/forms';

export const joinParams = (params = {}): string => {
  const result = [];

  Object.keys(params).forEach(key => {
    if (params[key]) {
      result.push(`${key}=${params[key]}`);
    }
  });

  return result.join('&');
};

export const buildHttpRequestQueryParams = (params = {}): string => {
  const fromObject = Object.keys(params).reduce((accumulator, key) => {
    accumulator[key] = `${params[key]}`;
    return accumulator;
  }, {});

  return new URLSearchParams(fromObject).toString();
};

export const flatMap = (f, arr) => arr.reduce((x, y) => [...x, ...f(y)], []);

export const capitalize = (value: string) => {
  const lowerValue = value.toLowerCase();
  const capitalizedValue = lowerValue.charAt(0).toUpperCase() + lowerValue.slice(1);

  return capitalizedValue;
};

export const scrollBottom = (divId: string) => {
  const element = document.getElementById(divId);
  element.scrollTop = element.scrollHeight;
};

export const parseCurrency = (currencyValue: string, decimals = 2) => {
  const cleanedValue = currencyValue.replace(',', '').replace('.', '');
  const formattedNumber = parseFloat((Number(cleanedValue) / 100).toFixed(decimals));
  return formattedNumber;
};

export const formatDateBRToEN = (date: string) => {
  const [day, month, year] = date.split('/');
  return `${year}-${month}-${day}`;
};

export const disableFormOnLoading = (formControl: { [key: string]: AbstractControl }, isLoading: boolean) => {
  Object.keys(formControl).forEach(controlName => {
    const control = formControl[controlName];

    if (isLoading) {
      control.disable();
    } else {
      control.enable();
    }
  });
};
