/* eslint-disable @typescript-eslint/naming-convention */
export enum IndicadorAcumuladoEnum {
  Ano = 'ano',
  Dia = 'dia',
  Mes = 'mes',
  M12 = '12m',
  M24 = '24m',
  M36 = '36m',
  M48 = '48m',
  M60 = '60m',
}
