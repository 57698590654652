import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieConsentService } from './shared/services/cookie-consent/cookie-consent.service';
import { MixPanelService } from './shared/services/mix-panel/mix-panel.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  public constructor(
    translateService: TranslateService,
    private cookieService: CookieConsentService,
    private mixPanel: MixPanelService
  ) {
    translateService.setDefaultLang('pt');
    translateService.use('pt');
  }

  public ngOnInit(): void {
    this.mixPanel.init(environment.credentials.mixPanelToken);
    this.cookieService.initialize();
  }
}
