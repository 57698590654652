import { HttpParams } from '@angular/common/http';
import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSimulatorContentHeight]',
})
export class SimulatorContentHeightDirective implements OnInit, OnDestroy {
  public get element() {
    return this.elementRef.nativeElement;
  }

  private listener: () => void;

  public constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngOnInit() {
    this.listener = this.renderer.listen(this.element, 'load', () => {
      const url = this.element.src;
      const page = this.getPageNameFromUrl(url);
      const contentHeight = this.getContentHeightFromPageName(page);
      this.setFrameHeight(contentHeight);
    });
  }

  public ngOnDestroy() {
    this.listener();
  }

  private getPageNameFromUrl(url: string) {
    if (!url) {
      return null;
    }

    let paramValue: string;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get('pagina');
    }
    return paramValue;
  }

  private getContentHeightFromPageName(page: string) {
    switch (page) {
      case 'precaver_versus_pgbl':
        return 1728;
      case 'planejamento_previdenciario':
        return 1512;
      case 'precaver_versus_vgbl':
        return 2484;
      case 'economia_no_ir':
        return 1296;
      case 'perfil':
        return 1080;
      default:
        return 1080;
    }
  }

  private setFrameHeight(height: number) {
    this.renderer.setStyle(this.element, 'height', `${height}px`);
  }
}
