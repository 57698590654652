import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  private readonly cssLoaded = new Set<string>();
  private readonly renderer: Renderer2;

  public constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
    this.document = document;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public createStyleFromUrl(scriptUrl: string): Promise<void> {
    return new Promise(resolve => {
      if (this.cssLoaded.has(scriptUrl)) {
        resolve();
        return;
      }
      this.cssLoaded.add(scriptUrl);
      const link = this.renderer.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = scriptUrl;
      if (link.readyState) {
        // IE
        link.onreadystatechange = () => {
          if (link.readyState === 'loaded' || link.readyState === 'complete') {
            link.onreadystatechange = null;
            resolve();
          }
        };
      } else {
        // Others
        link.onload = () => {
          resolve();
        };
      }
      link.onerror = () => resolve();
      this.renderer.appendChild(this.document.head, link);
    });
  }
}
