import { ValidatorFn } from '@angular/forms';

export interface IQuestionOptions {
  idOpcao?: number;
  texto: string;
  pontuacao?: number;
}

export interface IQuestion {
  idQuestao: number;
  pergunta: string;
  opcoes?: IQuestionOptions[];
  extraMessages?: string[];
  answer?: Answer;
  inputProps?: InputProps;
}

export interface IQuestionSimulador {
  idQuestionario: number;
  nome: string;
  questoes: IQuestion[];
}

export interface IQuestionResponse {
  [key: string]: number | any;
}

export interface Answer extends IQuestionOptions {
  questionId: number;
}

export enum InputTypesEnum {
  text = 'text',
  currency = 'currency',
  typeNumber = 'number',
}

export type InputTypes = 'text' | 'currency' | 'number';

export interface InputProps {
  inputType: InputTypes;
  validations?: ValidatorFn[];
  min?: number;
  max?: number;
  placeholder?: string;
  defaultValue?: string;
}
