/*
 * Eventos criados conforme a documentação
 * https://smartquanta.atlassian.net/wiki/spaces/PP/pages/1600880665/Documenta+o+Mix+Panel+-+Analytics
 */

export enum EventsMixPanelEnum {
  recebimentoRenda = 'RECEBIMENTO_RENDA',
  rendimentosNegativos = 'REACAO_RENDIMENTOS_NEGATIVOS',
  objetivoRetorno = 'OBJETIVO_RETORNO',
  composicaoPatrimonio = 'COMPOSICAO_PATRIMONIO',
  conhecimentoInvestimento = 'CONHECIMENTO_INVESTIMENTO',
  perfilInvestidor = 'TIPO_PERFIL_INVESTIDOR',

  visualizouPagina = 'VISUALIZOU_PAGINA',
  prencheuNome = 'PREENCHEU_NOME',
  prencheuEmail = 'PREENCHEU_EMAIL',
  prencheuIdade = 'PREENCHEU_IDADE',
  sentimentoInvestimento = 'SENTIMENTO_INVESTIMENTO',
  desejoRendaMensalFutura = 'DESEJO_RENDA_MENSAL_FUTURA',
  possuiConta = 'POSSUI_CONTA',
  prencheuCpf = 'PREENCHEU_CPF',
  resultadoDaSimulacao = 'VISUALIZOU_RESULTADO_SIMULACAO',
  queroRefazer = 'QUER_REFAZER_SIMULACAO',
  gosteiQueroContratar = 'QUER_CONTRATAR_SIMULACAO',
  gosteiQueroContratarRapida = 'QUER_CONTRATAR_SIMULACAO_RAPIDA',
  gosteiQueroContratarCompleta = 'QUER_CONTRATAR_SIMULACAO_COMPLETA',
  receberPorEmail = 'ENVIOU_SIMULACAO_EMAIL',
  abandonou = 'ABANDONOU_SIMULACAO',
}
