import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { PROJECT_NAME } from '@shared/constants';
import { StyleService } from '../../../services/style/style.service';
import { WordpressPage } from '../models/wordpress-page';
import { WordPressService } from '../services/wordpress.service';
import { WordPressContainerComponent } from '../wordpress-container/wordpress-container.component';

@Component({
  selector: 'app-wordpress-content',
  templateUrl: './wordpress-content.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class WordPressContentComponent {
  @Input()
  public category: string;

  @Input()
  public name: string | number;

  @Input()
  public priority: number;

  @Input()
  public titleSearchPage: string;

  @Output()
  public ready = new EventEmitter<WordPressContentComponent>();

  public container: WordPressContainerComponent;
  public content: string;
  public contentReady: boolean;
  public get displayName() {
    return this.name || this.category;
  }
  public page: WordpressPage;
  public styles: string[];

  public constructor(
    public element: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private styleService: StyleService,
    private wordPressService: WordPressService
  ) {}

  public onWordPressReady(container: WordPressContainerComponent) {
    this.container = container;
  }

  public async load() {
    await this.loadContent();
    await this.applyStyles();
    this.changeDetector.detectChanges();
    this.contentReady = true;
    this.ready.emit(this);
  }

  private getTitlePage(titleWP: string) {
    if (!this.titleSearchPage) {
      return PROJECT_NAME;
    }

    const title = this.name.toString().trim() === this.titleSearchPage.toString().trim() ? titleWP : PROJECT_NAME;
    return title;
  }

  private async loadContent() {
    let page: WordpressPage;
    if (this.category) {
      // load page content using category
      page = await this.retrievePageByCategoryAsync();
    } else if (this.name) {
      // load page content using name
      page = await this.retrievePageByName();

      if (!['navbar', 'atendimento-footer', 'footer'].includes(this.name.toString())) {
        window.document.title = this.getTitlePage(page?.title?.rendered);
      }
    }

    if (!page?.content) {
      return;
    }

    this.content = page.content.rendered;
    this.page = page;
  }

  private applyStyles() {
    const name = `post-${this.page.id}.css`;
    const styles = this.container.wordpressStyles.filter(link => link.includes(name));
    const promises = styles.map(async style => this.styleService.createStyleFromUrl(style));
    return Promise.all(promises);
  }

  private async retrievePageByCategoryAsync() {
    const [filteredCategory] = this.container.categories.filter(category => category.slug === this.category);
    const categoryId = filteredCategory && filteredCategory.id;
    if (!categoryId) {
      return null;
    }
    const page = await this.wordPressService.getFirstPageByCategory(categoryId);
    return page;
  }

  private async retrievePageByName() {
    return this.wordPressService.getPage(this.name);
  }
}
