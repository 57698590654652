export class Mutex {
  private mutex = Promise.resolve();
  public lock(): PromiseLike<() => void> {
    let start: (unlock: () => void) => void = () => {};
    this.mutex = this.mutex.then(() => {
      return new Promise(start);
    });
    return new Promise(resolve => {
      start = resolve;
    });
  }
  public async dispatch<T>(fn: (() => T) | (() => PromiseLike<T>)): Promise<T> {
    const unlock = await this.lock();
    try {
      return await Promise.resolve(fn());
    } finally {
      unlock();
    }
  }
}
