import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from '@pages/contact-us/contact-us.component';
import { HomeComponent } from '@pages/home/home.component';
import { OmbudsmanComponent } from '@pages/ombudsman/ombudsman.component';
import { SimulatorsComponent } from '@pages/simulators/simulators.component';
import { EmptyComponent } from '@components/empty/empty.component';
import { ProfitabilityChartComponent } from '@components/profitability-chart/profitability-chart.component';
import { PlanSimulationComponent } from '@pages/plan-simulation/plan-simulation.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { name: 'Página Principal WordPress' },
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'fale-conosco',
    component: ContactUsComponent,
    data: { name: 'Página de Fale Conosco WordPress' },
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
  },
  {
    path: 'ouvidoria',
    component: OmbudsmanComponent,
    data: { name: 'Página de Ouvidoria WordPress' },
    loadChildren: () => import('./pages/ombudsman/ombudsman.module').then(m => m.OmbudsmanModule),
  },
  {
    path: 'simuladores',
    component: SimulatorsComponent,
    data: { name: 'Simuladores' },
    loadChildren: () => import('./pages/simulators/simulators.module').then(m => m.SimulatorsModule),
  },
  {
    path: 'simuladores/:simulador',
    component: SimulatorsComponent,
    data: { name: 'Simuladores' },
    loadChildren: () => import('./pages/simulators/simulators.module').then(m => m.SimulatorsModule),
  },
  {
    path: 'home/cooprev/simulador',
    component: PlanSimulationComponent,
    data: { name: 'Simulador Plano' },
    loadChildren: () => import('./pages/plan-simulation/plan-simulation.module').then(m => m.PlanSimulationModule),
  },
  {
    path: 'empty',
    component: EmptyComponent,
    data: { name: 'Simuladores' },
  },
  {
    path: 'precaver',
    redirectTo: '/home/precaver',
  },
  {
    path: 'prevcoop',
    redirectTo: '/home/prevcoop',
  },
  {
    path: 'cooprev',
    redirectTo: '/home/cooprev',
  },
  {
    path: 'home/:pageName/rentabilidade',
    component: ProfitabilityChartComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
