<form [formGroup]="form">
  <div class="row title bg-purple-base rounded text-white d-flex flex-column justify-content-center align-items-end mr-4 ml-5 my-3 pr-4">
    <div class="font-large-1 font-weight-500">{{ 'planSimulation.welcome' | translate }}</div>
    <div class="font-medium-1 font-weight-500 mt-1">{{ 'planSimulation.description' | translate }}</div>
  </div>
  <div class="position-absolute fixed-top mt-5 d-flex justify-content-start header-image">
    <img src="assets/images/pri-ok-simulacao-completa.png"  alt="Pri fazendo sinal de ok">
  </div>

  <div class="row mx-3">
    <div class="col-xs-12 col-sm-6 pr-0">
      <app-simulation-header></app-simulation-header>

      <div class="row mt-4" *ngIf="!simulation">
        <div class="col-xs-12 col-sm-6">
          <span class="font-weight-500 font-small-3 d-block mb-2" [ngClass]="hasEmployerContribution.checked ? '' : 'text-nowrap'">{{ 'planSimulation.hasEmployerContribution' | translate }}</span>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="hasEmployerContribution" #hasEmployerContribution value="yes">
            <label class="form-check-label">{{ 'common.yes' | translate }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="hasEmployerContribution" value="no">
            <label class="form-check-label">{{ 'common.no' | translate }}</label>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="hasEmployerContribution.checked">
          <span for="companyContribution" class="font-weight-500 font-small-3 d-block mb-2">{{ 'planSimulation.companyContributionValue' | translate }}</span>
          <input formControlName="companyContribution" id="companyContribution" class="input-white-darker-border rounded input-line-height w-100 pl-2" prefix="R$ " mask="separator.2" thousandSeparator="." separatorLimit="10000000">
          <span *ngIf="form.controls.companyContribution.touched && form.controls.companyContribution.invalid" class="font-small-1 text-danger">Informe valor entre R$1 e R$10.000.000</span>
        </div>
      </div>

      <div class="row mt-4" *ngIf="!simulation">
        <div class="col-xs-12 col-sm-6">
          <span class="font-weight-500 font-small-3 d-block mb-2" [ngClass]="hasInitialInvestment.checked ? '' : 'text-nowrap'">{{ 'planSimulation.immediateContribution' | translate }}</span>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="immediateContribution" #hasInitialInvestment value="yes">
            <label class="form-check-label">{{ 'common.yes' | translate }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="immediateContribution" value="no">
            <label class="form-check-label">{{ 'common.no' | translate }}</label>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="hasInitialInvestment.checked">
          <span for="immediateContribution" class="font-weight-500 font-small-3 d-block mb-2">{{ 'planSimulation.immediateContributionValue' | translate }}</span>
          <input  formControlName="immediateContribution" id="immediateContribution" class="input-white-darker-border rounded input-line-height w-100 pl-2" prefix="R$ " mask="separator.2" thousandSeparator="." separatorLimit="100000000">
          <span *ngIf="form.controls.immediateContribution.touched && form.controls.immediateContribution.invalid" class="font-small-1 text-danger">Informe valor entre R$1 e R$100.000.000</span>
        </div>
      </div>

      <div class="my-4" *ngIf="simulation">
        <app-simulation-result (changeSimulationEvent)="changeSimulation()">
        </app-simulation-result>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 pl-4" *ngIf="!simulation">
      <div class="row mt-4 pt-3">
        <div class="col-xs-12 col-sm-6">
          <label for="retirementAge" class="d-block font-weight-500 font-small-3">{{ 'planSimulation.retirementAge' | translate }}</label>
          <input formControlName="retirementAge" id="retirementAge" class="w-100 input-white-darker-border rounded input-line-height pl-2" mask="900" [dropSpecialCharacters]="false" separatorLimit="100">
          <span *ngIf="form.controls.retirementAge.touched && form.controls.retirementAge.invalid" class="font-small-1 text-danger">Informe valor entre 18 e 100</span>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="!simulation">
          <label for="birthdate" class="d-block font-weight-500 font-small-3">{{ 'common.birthdate' | translate }}</label>
          <input  formControlName="birthdate" id="birthdate" class="w-100 input-white-darker-border rounded input-line-height pl-2" mask="d0/M0/0000" [dropSpecialCharacters]="false" [leadZeroDateTime]="true">
        </div>
      </div>

      <div class="row" *ngIf="!simulation">
        <div class="col-12 mt-3">
          <label for="income" class="d-block font-weight-500 font-small-3">{{ 'common.monthlyIncome' | translate }}</label>
          <input formControlName="income" id="income" class="w-100 input-white-darker-border rounded input-line-height pl-2" prefix="R$ " mask="separator.2" thousandSeparator="." separatorLimit="10000000">
          <span *ngIf="form.controls.income.touched && form.controls.income.invalid" class="font-small-1 text-danger">Informe valor entre R$0 e R$10.000.000</span>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 pl-4 mb-4" *ngIf="simulation">
      <app-simulation-investment-profile (changeSimulationEvent)="changeSimulation()">
      </app-simulation-investment-profile>
      <button class="btn btn-purple-base w-100 mt-3 font-small-3" (click)="hire()">{{ 'planSimulation.buttons.hire' | translate }}</button>
      <button class="btn btn-outline-purple-base w-100 mt-3 font-small-3" (click)="sendEmail()">{{ 'planSimulation.buttons.receiveEmail' | translate }}</button>
      <button class="btn btn-outline-purple-base w-100 mt-3 font-small-3" (click)="redoSimulation()">{{ 'planSimulation.buttons.redoSimulation' | translate }}</button>
    </div>
  </div>

  <div class="row px-4 mb-4 mt-5 mx-1" *ngIf="!simulation">
    <button class="btn btn-purple-base col-12 mb-3 py-2 w-100" (click)="simulate()" [disabled]="form.invalid || simulationHeader.form.invalid || isLoading">{{ (isLoading ? 'planSimulation.buttons.simulating' : 'planSimulation.buttons.simulate') | translate }}</button>
  </div>
</form>
