import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  InputTypesEnum,
  Answer,
  IQuestionOptions,
  InputProps,
} from '@pages/home/services/simulator/models/questionario-simulacao';
import { parseCurrency } from '@shared/functions/functions';
import { AnswerBackButtonService } from './answer-back-button.service';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
})
export class AnswerComponent implements OnInit {
  @Output() public answerEvent = new EventEmitter();

  @Input() public questionId: number;
  @Input() public answer?: Answer;
  @Input() public options?: IQuestionOptions[];
  @Input() public inputProps?: InputProps;
  @Input() public showBackButton = false;

  public form: FormGroup;

  public constructor(private readonly formBuilder: FormBuilder, private readonly backButton: AnswerBackButtonService) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      actionInput: ['', this.inputProps?.validations || []],
    });

    if (this.inputProps?.defaultValue) {
      const defaultValue =
        this.inputProps.inputType === InputTypesEnum.typeNumber
          ? Number(this.inputProps.defaultValue)
          : this.inputProps.defaultValue;

      this.form.controls.actionInput.setValue(defaultValue);
    }
  }

  public onClickOption(option: IQuestionOptions) {
    this.answer = {
      ...option,
      questionId: this.questionId,
    };

    this.answerEvent.emit(this.answer);
  }

  public onClickContinue() {
    const id = `input-${this.inputProps.inputType}-${this.questionId}`;
    const element = document.getElementById(id);

    this.answer = {
      questionId: this.questionId,
      texto: (element as any).value,
    };

    this.answerEvent.emit(this.answer);
  }

  public onClickBack() {
    this.backButton.emiter(true);
  }

  public onChange() {
    switch (this.inputProps.inputType) {
      case InputTypesEnum.currency:
        this.currencyChange();
        break;
    }
  }

  private currencyChange() {
    const value = parseCurrency(this.form.controls.actionInput.value);

    const id = `button-${this.inputProps.inputType}-${this.questionId}`;
    const valueInRange = value > this.inputProps.max || value < this.inputProps.min;
    (document.getElementById(id) as HTMLButtonElement).disabled = valueInRange;

    const textWithOnlyNumbers = this.form.controls.actionInput.value
      .replace(/(.*){1}/, '0$1')
      .replace(/[^\d]/g, '')
      .replace(/(\d\d?)$/, '.$1');
    const localeNumber = parseFloat(textWithOnlyNumbers).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    this.form.controls.actionInput.setValue(localeNumber);
  }
}
