import { Component, Input, OnInit } from '@angular/core';
import { ChartItem } from '@components/bar-chart/bar-chart.component';
import { TranslateService } from '@ngx-translate/core';
import { DatesI18nService } from '@shared/services/dates-i18n/dates-i18n.service';
import { InvestmentService } from '@shared/services/investment/investment.service';
import { IDownloadLaminaResponse } from '@shared/services/investment/models/download-lamina.response';
import { IndicadorAcumuladoEnum } from '@shared/services/investment/models/indicador-acumulado.enum';
import { IndicatorsLabelEnum } from '@shared/services/investment/models/indicators-label.enum';
import { IndicatorsPageEnum } from '@shared/services/investment/models/indicators-page.enum';
import { PerfisDeInvestimentoIdEnum } from '@shared/services/investment/models/perfis-de-investimento.enum';
import { DescricaoPlanosEnum, PlanosEnum, PLANS } from '@shared/services/investment/models/planos.enum';
import { IIndicatorName } from '@shared/services/investment/models/medicoes.request';
import { IMedicao, IFundMedicoesByProfile } from '@shared/services/investment/models/medicoes.response';
import { ParametrizationService } from '@shared/services/parametrization/parametrization.service';
import { Router } from '@angular/router';
import { PlanProfileService } from '@shared/services/plans/plans.service';
import { PlanProfile } from '@shared/services/plans/models/profiles.response';

export type ChartFilterOption = {
  id: IndicadorAcumuladoEnum;
  name: string;
  description: string;
};

type DownloadSheetButton = {
  name: string;
  profile: number;
  color: string;
  loading: boolean;
};

@Component({
  selector: 'app-profitability-chart',
  templateUrl: 'profitability-chart.component.html',
  styleUrls: ['./profitability-chart.component.scss'],
})
export class ProfitabilityChartComponent implements OnInit {
  @Input()
  public set page(name: string) {
    this.pageName = name.toUpperCase();
  }

  @Input()
  public items: ChartItem[] = [];

  public chartFilterOptions: ChartFilterOption[] = [];

  public downloadSheetButtons: DownloadSheetButton[] = [];

  public cdiByProfile = [];

  public i18n: { profitabilityWarning: string; investmentProfilesWarning: string } = {
    profitabilityWarning: '',
    investmentProfilesWarning: '',
  };

  public pageName: string;

  public selectedChartFilter: ChartFilterOption;

  public currentMonth: string;

  public currentPlanId: PlanosEnum;

  public pageProfile: PerfisDeInvestimentoIdEnum;

  public plansPeriod: Record<string, IndicadorAcumuladoEnum>;

  public isLoading = true;

  public get planosEnum(): typeof PlanosEnum {
    return PlanosEnum;
  }

  public get isCooperativePage(): boolean {
    return this.pageName === IndicatorsPageEnum.COOPREV;
  }

  public get isLandingPage(): boolean {
    return this.pageName === IndicatorsPageEnum.LANDING_PAGE;
  }

  private profiles: IFundMedicoesByProfile;
  private planProfile: PlanProfile;

  public constructor(
    private investmentService: InvestmentService,
    private translateService: TranslateService,
    private datesI18nService: DatesI18nService,
    private parametrizationService: ParametrizationService,
    private planProfileService: PlanProfileService,
    private router: Router
  ) {
    this.renderAsPage();
  }

  public async ngOnInit() {
    await this.fetchPeriodFilter();
    this.pageProfile = this.isCooperativePage
      ? PerfisDeInvestimentoIdEnum.RENDA_FIXA
      : PerfisDeInvestimentoIdEnum.QUANTA;

    if (this.isLandingPage) {
      this.planProfile = await this.planProfileService.getPlan(this.currentPlanId);
      const response = await this.handleHomePagePlan();
      this.currentPlanId = response?.plan;
      this.profiles = response?.profiles;

      await this.setDefaultSelectedChartFilter();
      this.refreshChart();
      this.isLoading = false;

      return;
    }

    this.currentPlanId = PlanosEnum[this.pageName];
    this.planProfile = await this.planProfileService.getPlan(this.currentPlanId);
    this.handleDownloadSheetButtons();
    await this.setDefaultSelectedChartFilter();
    await this.loadChart();
  }

  public handleDownloadSheetButtons() {
    this.downloadSheetButtons = this.planProfile?.perfis.map(profile => {
      return {
        name: profile.nomePerfil,
        profile: profile.idPerfil,
        color: profile.cor,
        loading: false,
      };
    });
  }

  public downloadInvestmentSheet(button: DownloadSheetButton): void {
    if (button.loading) {
      return;
    }

    const { profile: idPerfil } = button;

    const idPlano = PlanosEnum[this.pageName] || PlanosEnum.PRECAVER;

    button.loading = true;

    this.investmentService
      .downloadInvestmentSheet({ idPlano, idPerfil })
      // TODO: remover tipo any. O tipo correto está na Domains V2
      .then((response: any) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = response.urlTemporaria;
        downloadLink.download = response.nomeArquivo;
        downloadLink.click();
      })
      .finally(() => {
        button.loading = false;
      });
  }

  public onFilterSelected(chartFilter: ChartFilterOption): void {
    this.selectedChartFilter = chartFilter;
    this.refreshChart();
  }

  private refreshChart() {
    this.updateChart();
    this.setChartVariables();
  }

  private updateChartLegend(): void {
    const planName = PLANS[this.currentPlanId];

    let profitabilityWarning = this.translateService.instant('barChart.profitabilityWarning', {
      plan: DescricaoPlanosEnum[planName],
      month: this.currentMonth,
      period: this.selectedChartFilter?.description,
    });

    profitabilityWarning =
      this.selectedChartFilter?.id === IndicadorAcumuladoEnum.Mes
        ? `${profitabilityWarning}.`
        : `${profitabilityWarning} – ${this.selectedChartFilter?.description}.`;

    this.i18n.profitabilityWarning = profitabilityWarning;
  }

  private async setDefaultSelectedChartFilter() {
    const plan = PLANS[this.currentPlanId].toLowerCase();
    const period = this.isLandingPage ? this.plansPeriod.landingPage : this.plansPeriod[plan];

    if (!period) {
      return;
    }

    this.selectedChartFilter = this.createOptionIndicator(period);
  }

  private async fetchPeriodFilter() {
    const paramsKey = 'RENTABILIDADE_SITE';
    const params = await this.parametrizationService.getParametersByKey(paramsKey);
    this.plansPeriod = params.valor;
  }

  private getLastConsolidatedDate(profileId: number): { month: string; year: string } {
    const profitability = this.getMeasurements(
      this.profiles,
      profileId,
      IIndicatorName.rentabilidade,
      this.selectedChartFilter?.id
    );
    const lastProfitability = this.getLastMeasurement(profitability);

    const [year, month] = lastProfitability?.dataMedicao?.split('-') ?? [];

    return { month, year };
  }

  private updateChart(): void {
    this.cdiByProfile = this.planProfile?.perfis
      .map(profile => {
        return {
          id: profile.idPerfil,
          name: profile.nomePerfil,
          value: this.cdiOverProfitability(profile.idPerfil),
        };
      })
      .filter(item => item?.value);

    const cdi = this.getMeasurementValue(this.pageProfile, IIndicatorName.cdi);
    const savings = this.getMeasurementValue(this.pageProfile, IIndicatorName.poupanca);
    const profiles = this.planProfile?.perfis.map(profile => {
      const value = this.getMeasurementValue(profile.idPerfil, IIndicatorName.rentabilidade);
      const color = profile.cor;
      const label = profile.nomePerfil;
      const description = profile.descricaoPerfil;

      return { label, value, color, description };
    });

    this.items = [
      ...profiles,
      {
        label: IndicatorsLabelEnum.CDI,
        value: cdi,
        color: '#747474',
      },
      {
        label: IndicatorsLabelEnum.POUPANCA,
        value: savings,
        color: '#B4B4B4',
      },
    ];
    this.items = this.items.filter(item => item?.value);
  }

  private async handleHomePagePlan() {
    const precaverMeasurements = await this.fetchAllProfilesMeasurementsByPlan(PlanosEnum.PRECAVER);
    const precaverValue = this.getPlanValueFromAllProfiles(precaverMeasurements);

    const prevcoopMeasurements = await this.fetchAllProfilesMeasurementsByPlan(PlanosEnum.PREVCOOP);
    const prevcoopValue = this.getPlanValueFromAllProfiles(prevcoopMeasurements);

    const prevcoopIsLessThanPrecaver = prevcoopValue < precaverValue;

    return {
      plan: prevcoopIsLessThanPrecaver ? PlanosEnum.PREVCOOP : PlanosEnum.PRECAVER,
      profiles: prevcoopIsLessThanPrecaver ? prevcoopMeasurements : precaverMeasurements,
    };
  }

  private getPlanValueFromAllProfiles(profileMeasurement: IFundMedicoesByProfile) {
    let value = 0;

    this.planProfile?.perfis.forEach(profile => {
      const measurement = this.getMeasurements(
        profileMeasurement,
        profile.idPerfil,
        IIndicatorName.rentabilidade,
        this.plansPeriod.landingPage
      );

      const lastMeasurement = this.getLastMeasurement(measurement);
      value += lastMeasurement?.valor;
    });

    return value;
  }

  private setChartVariables(): void {
    const { year, month } = this.getLastConsolidatedDate(this.pageProfile);
    const monthFullName = this.datesI18nService.getMonthFullName(+month);
    this.currentMonth = monthFullName;

    this.i18n.investmentProfilesWarning = this.translateService.instant('barChart.investmentProfilesWarning');
    this.updateChartLegend();

    const monthOptions = this.handleChartMonthOptions();
    this.chartFilterOptions = [
      ...monthOptions,
      { id: IndicadorAcumuladoEnum.Ano, name: year, description: `${year}` },
      { id: IndicadorAcumuladoEnum.Mes, name: monthFullName, description: monthFullName },
    ];
  }

  private handleChartMonthOptions() {
    const periods = [
      IndicadorAcumuladoEnum.M60,
      IndicadorAcumuladoEnum.M48,
      IndicadorAcumuladoEnum.M36,
      IndicadorAcumuladoEnum.M12,
    ];
    const optionsIndicators = [];

    periods.forEach(period => {
      const measurements = this.planProfile?.perfis
        ?.map(profile => this.getMeasurements(this.profiles, profile.idPerfil, IIndicatorName.rentabilidade, period))
        ?.filter(measurement => measurement?.length);

      if (measurements.length >= 1) {
        const optionIndicator = this.createOptionIndicator(period);
        optionsIndicators.push(optionIndicator);
      }
    });

    return optionsIndicators;
  }

  private async loadChart(): Promise<void> {
    this.isLoading = true;
    const measurementsByProfile = await this.fetchAllProfilesMeasurementsByPlan(this.currentPlanId);
    this.profiles = measurementsByProfile;

    this.isLoading = false;
    this.refreshChart();
  }

  private async fetchAllProfilesMeasurementsByPlan(planId: PlanosEnum): Promise<IFundMedicoesByProfile> {
    const data = {} as IFundMedicoesByProfile;

    await Promise.all(
      this.planProfile?.perfis.map(async profile => {
        const measurements = await this.fetchMeasurementsByIndicator(profile.idPerfil, planId);
        data[profile.idPerfil] = measurements;
      })
    );

    return data;
  }

  private async fetchMeasurementsByIndicator(profileId: number, planId: PlanosEnum) {
    const indicators =
      profileId === this.pageProfile
        ? [IIndicatorName.rentabilidade, IIndicatorName.cdi, IIndicatorName.poupanca]
        : [IIndicatorName.rentabilidade];

    const params = {
      idPerfil: profileId,
      tipoMedicao: indicators,
      idPlano: planId,
    };
    const measurements = await this.investmentService.getMeasurements(params);

    return measurements;
  }

  private getMeasurementValue(profileId: number, indicator: IIndicatorName): number {
    const measurements = this.getMeasurements(this.profiles, profileId, indicator, this.selectedChartFilter?.id);

    if (!measurements) {
      return 0;
    }

    const lastMeasurements = this.getLastMeasurement(measurements);
    return lastMeasurements?.valor;
  }

  private cdiOverProfitability(profile: number) {
    const cdi = this.getMeasurementValue(this.pageProfile, IIndicatorName.cdi);

    if (!cdi) {
      return null;
    }

    const profitability = this.getMeasurementValue(profile, IIndicatorName.rentabilidade);
    const value = (profitability / cdi) * 100;

    if (!value || value < 0) {
      return null;
    }

    return `${value.toFixed(2)}%`;
  }

  private getLastMeasurement(measurements: IMedicao[]) {
    const lastIndex = (measurements || []).length - 1;
    if (lastIndex === -1) {
      return null;
    }

    return measurements[lastIndex];
  }

  private getMeasurements(
    measurements: IFundMedicoesByProfile,
    profileId: number,
    indicator: IIndicatorName,
    period: IndicadorAcumuladoEnum
  ) {
    return measurements?.[profileId]?.[indicator]?.[period];
  }

  private createOptionIndicator(period: IndicadorAcumuladoEnum) {
    const months = period.slice(0, -1);

    return {
      id: period,
      name: `${months} meses`,
      description: `Últimos ${months} meses`,
    };
  }

  private renderAsPage() {
    const isChartOnlyPage = this.sliceUrl(-1) === 'rentabilidade' && !this.pageName;

    if (!isChartOnlyPage) {
      return;
    }

    this.pageName = this.sliceUrl(-2).toUpperCase();
    const isValidPlan = Object.values(PlanosEnum).includes(this.pageName);

    if (isValidPlan) {
      this.hideElement('#preloader');
      this.hideElement('[aria-label="cookieconsent"]');
    } else {
      this.router.navigate(['home']);
    }
  }

  private sliceUrl(index: number): string {
    const routes = this.router?.url?.split('/');
    return routes?.slice(index)?.[0] || '';
  }

  private hideElement(key: string) {
    const element = document.querySelector(key) as HTMLElement;

    if (element) {
      element.style.display = 'none';
    }
  }
}
