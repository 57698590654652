<app-wordpress-container class="w-100 h-100">
  <app-wordpress-content [name]="'navbar'"></app-wordpress-content>
    <div *ngIf="selectedSimulation === 'fast'">
      <app-fast-simulation (changeSimulationEvent)="changeSimulation($event)" (sendEmailEvent)="sendEmail($event)">
      </app-fast-simulation>
    </div>
    <div *ngIf="selectedSimulation === 'full'">
      <app-full-simulation (changeSimulationEvent)="changeSimulation($event)" (sendEmailEvent)="sendEmail($event)">
      </app-full-simulation>
    </div>
  <app-wordpress-content [name]="'footer'"></app-wordpress-content>
</app-wordpress-container>
