/* eslint-disable @typescript-eslint/dot-notation */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ScriptService } from '../script/script.service';
import { defaults } from 'lodash';

export const NEO_ASSIST_DOMAIN = new InjectionToken<string>('NEO_ASSIST_DOMAIN');

export type NeoAssistOptionsType = {
  cpf?: string;
  email?: string;
  nome?: string;
  phone?: string;
  plano?: string;
  domain?: string;
  tag: 'tag_vfixa' | 'tag_vouvidoriafixa';
};

const optionsDefault: NeoAssistOptionsType = {
  cpf: '',
  email: '',
  nome: '',
  phone: '',
  plano: '',
  tag: 'tag_vfixa',
};

@Injectable({
  providedIn: 'root',
})
export class NeoAssistService {
  public constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(NEO_ASSIST_DOMAIN) private domain: string,
    private scriptService: ScriptService
  ) {}

  public async initialize(options: NeoAssistOptionsType) {
    this.destroy();
    defaults(options, optionsDefault);

    const configurationScriptText = `
        (function () {
          window.NeoAssistTag = {};
          NeoAssistTag.initialize = {
            fields: {
              name: '${options.nome}',
              email: '${options.email}',
              FieldU: '${options.cpf}',
              FieldA: '${options.phone}',
              ProtocoloFieldA: '${options.plano}',
            },
          };
          var nab = document.createElement('script');
          nab.type = 'text/javascript';
          nab.async = true;
          nab.src = '';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(nab, s);
        })();
      `;
    const configurationScript = this.scriptService.createScriptFromText(configurationScriptText);
    this.tagScript(configurationScript);

    const page = `${options.domain || this.domain}/?th=${options.tag}`;
    const neoAssistScriptUrl = `https://cdn.atendimen.to/n.js?ntag=0&d=${
      options.domain || this.domain
    }&p=${encodeURIComponent(page)}`;
    const neoAssistScript = await this.scriptService.createScriptFromUrl(neoAssistScriptUrl);
    this.tagScript(neoAssistScript);
  }

  private destroy() {
    window['_NAWidgetLoaded'] = undefined;
    window['NeoAssistTag'] = undefined;
    const chatAvatar = this.document.querySelector('.neoasssist-widget-frame') as HTMLElement;
    chatAvatar?.remove();
    const scripts = this.document.querySelectorAll('script[data-neo-assist]');
    scripts.forEach(script => {
      script.remove();
    });
  }

  private tagScript(script: HTMLScriptElement) {
    script.setAttribute('data-neo-assist', '');
  }
}
