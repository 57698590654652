import { Injectable } from '@angular/core';
import * as mixPanel from 'mixpanel-browser';
import { EventMixPanel } from '../../mixpanel/event-mixpanel';

export interface IMixPanelProperties {
  [key: string]: any;
}

@Injectable({ providedIn: 'root' })
export class MixPanelService {
  public init(mixPanelToken: string): void {
    mixPanel.init(mixPanelToken);
  }

  public track(event: EventMixPanel<any>): void {
    /* eslint-disable @typescript-eslint/naming-convention */
    mixPanel.track_with_groups(event.eventName, event.props, { Grupo: event.group });
  }

  public alias(identifier: string): void {
    mixPanel.alias(identifier);
  }

  public identify(identifier: string): void {
    mixPanel.identify(identifier);
  }

  public setPersonProperties(properties: IMixPanelProperties): void {
    mixPanel.people.set(properties);
  }

  public setSuperProperties(properties: IMixPanelProperties): void {
    mixPanel.register(properties);
  }

  public reset(): void {
    mixPanel.reset();
  }
}
