<div class="w-100 h-100 simulators-wordpress-container">
  <app-wordpress-container class="w-100 h-100">
    <app-wordpress-content [name]="'navbar'"></app-wordpress-content>
    <iframe appSimulatorContentHeight
            class="w-100 simulators-content"
            frameborder="0"
            scrolling="no"
            [src]="simulatorUrl | safeResourceUrl"
            title="Simulador"></iframe>
    <app-wordpress-content [name]="'footer'"></app-wordpress-content>
  </app-wordpress-container>
</div>