const parametros = '/parametro/v1';
const simulador = '/simulador/v1';
const simuladorV2 = '/simulador/v2';
const investimento = '/site/investimento/v1';
const investimentoV2 = '/site/investimento/v2';
const analytics = '/analytics/v2';
const simularPlano = '/bff-site/v1/simulacao';
const planos = '/site/planos/v1';

export const endpoints = {
  parametros: {
    toString: () => parametros,
    listar: `${parametros}/listar`,
  },
  simulador: {
    toString: () => simulador,
    enviarEmail: `${simulador}/enviar-email`,
    tipoDeInvestidor: `${simulador}/tipo-investidor`,
    simular: `${simulador}/simular`,
    questionario: `${simulador}/questionario`,
  },
  simuladorV2: {
    toString: () => simuladorV2,
    tipoDeInvestidor: `${simuladorV2}/tipo-investidor`,
  },
  investimento: {
    toString: () => investimento,
    downloadLamina: `${investimento}/download-lamina`,
  },
  investimentoV2: {
    toString: () => investimentoV2,
    medicoes: `${investimentoV2}/medicoes`,
  },
  analytics: {
    toString: () => analytics,
    createRDStationEvent: `${analytics}/eventos/rdstation`,
  },
  planos: {
    toString: () => planos,
    listarPlanos: `${planos}/planos`,
    listarPerfis: `${planos}/perfis`,
  },
};

export const endpointsBFFSite = {
  simularPlano: {
    toString: () => simularPlano,
    enviarEmail: `${simularPlano}/enviar-email`,
    payloadEmail: `${simularPlano}/payload-email`,
  },
};
