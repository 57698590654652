import { AbstractControl } from '@angular/forms';

const invalidCpf = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
];

// http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
export const isCpfValid = (cpf: string) => {
  if (!cpf) {
    return false;
  }

  let sum = 0;
  let rest = 0;

  cpf = cpf.replace(/[^0-9]/g, '');

  if (cpf.length > 11) {
    return false;
  }

  if (invalidCpf.includes(cpf)) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function validateCpf(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null;
  }

  const valid = isCpfValid(control.value);

  if (!valid) {
    return { invalidCpf: true };
  }

  return null;
}
