import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { SimulacaoResponse } from '@pages/home/services/simulator/models/simulacao-response';
import { SimulatorService } from '@pages/home/services/simulator/simulator.service';
import { SimulatorPanelComponent } from '../simulator-panel/simulator-panel.component';
import { InvestorProfileChatComponent } from './investor-profile-chat/investor-profile-chat.component';
import { DateTime } from 'luxon';
import { AnimationOptions } from 'ngx-lottie';
import { AnswerBackButtonService } from '@pages/home/components/chat/answer/answer-back-button.service';
import { EventsMixPanelEnum } from '@shared/mixpanel/enums/events-mixpanel-enum';
import { MixPanelService } from '@shared/services/mix-panel/mix-panel.service';
import { GenericSimulatorEvent } from '@shared/mixpanel/events/simulator-events';

@Component({
  selector: 'app-simulator-chat',
  templateUrl: './simulator-chat.component.html',
  styleUrls: ['./simulator-chat.component.scss'],
})
export class SimulatorChatComponent {
  @ViewChild(SimulatorPanelComponent, { static: true })
  public simulatorPanel: SimulatorPanelComponent;

  @ViewChild(InvestorProfileChatComponent, { static: true })
  public investorProfileChat: InvestorProfileChatComponent;

  @Output()
  public navigateToPlanSelection = new EventEmitter();

  public isSimulationResultVisible: boolean;

  public investmentKnowledge: string;
  public providerAccount: string;

  public investorProfile: string;

  public isLoadingSimulation = false;
  public animationOptions: AnimationOptions = {
    path: '/assets/animation/lottie/loader-simulation.json',
  };

  public animationStyles: Partial<CSSStyleDeclaration> = {
    margin: 'auto',
    width: '100%',
    height: '100%',
    maxWidth: '300px',
    maxHeight: '300px',
  };

  private simulationResponse: SimulacaoResponse;

  public constructor(
    private readonly simulatorService: SimulatorService,
    private readonly backButton: AnswerBackButtonService,
    private readonly mixPanelService: MixPanelService
  ) {
    this.backButton.listner().subscribe(response => {
      if (response) {
        this.isSimulationResultVisible = true;
      }
    });
  }

  public initialize() {
    this.mixPanelService.track(
      new GenericSimulatorEvent(EventsMixPanelEnum.visualizouPagina, { pagina: 'SIMULADOR_EXPLORE' })
    );
  }

  public restartSimulation() {
    this.isSimulationResultVisible = false;
    this.investorProfileChat.restartChat();
  }

  public receiveSimulationResultOnEmail() {
    this.isSimulationResultVisible = false;
    this.investorProfileChat.openEmailChat(this.simulationResponse, 'send-email');
  }

  public cancel(): boolean {
    this.isSimulationResultVisible = false;
    const willCancel = this.investorProfileChat.cancelChat(this.simulationResponse);

    if (willCancel) {
      this.simulationResponse = null;
    }

    return willCancel;
  }

  public async simulate(event: Record<string, any>) {
    const {
      retirementAge,
      age,
      monthlyIncome,
      investorProfile,
      personalInfo,
      investmentKnowledge,
      providerAccount,
    } = event;

    this.investorProfile = investorProfile;
    this.providerAccount = providerAccount;
    this.investmentKnowledge = investmentKnowledge;

    const birthDate = handleSimulationBirthDate(age);

    const simulation = {
      investorProfile,
      monthlyIncome,
    };

    this.isLoadingSimulation = true;

    this.simulatorService
      .simulateAsync(birthDate, retirementAge, simulation)
      .then(async simulationResponse => {
        this.simulationResponse = simulationResponse;
        this.simulatorPanel.personalInfo = personalInfo;
        await this.simulatorPanel.showSimulation(this.simulationResponse, 'monthlyIncome');

        this.isSimulationResultVisible = true;
      })
      .finally(() => {
        this.isLoadingSimulation = false;
      });
  }
}

export const handleSimulationBirthDate = (age: number) => {
  const now = DateTime.local();
  const year = now.year - age;
  const simulationBirthDate = DateTime.local(year, 1, 1).toISODate();
  return simulationBirthDate;
};
