import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Mutex } from '../../../../core/mutex';
import { WordpressCategory } from '../models/wordpress-category';
import { WordpressPage } from '../models/wordpress-page';

export const WORDPRESS_URL = new InjectionToken<string>('WORDPRESS_URL');

@Injectable({
  providedIn: 'root',
})
export class WordPressService {
  private readonly scriptsToAppend: string[] = [
    `${this.wordpressUrl}/wp-includes/js/imagesloaded.min.js?ver=3.2.0`,
    `${this.wordpressUrl}/wp-content/plugins/elementor-pro/assets/lib/sticky/jquery.sticky.min.js?ver=2.7.3`,
    `${this.wordpressUrl}/wp-content/plugins/elementor-pro/assets/js/frontend.min.js?ver=2.7.3`,
    `${this.wordpressUrl}/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=2.7.3`,
    `${this.wordpressUrl}/wp-content/plugins/essential-addons-for-elementor-lite/assets/admin/js/admin-bar.js?ver=3.4.0`,
    `${this.wordpressUrl}/wp-content/plugins/full-site-builder-for-elementor/extensions/wp-menu/navigation.js?ver=1.2.1`,
  ];

  private readonly stylesToAppend: string[] = [
    `${this.wordpressUrl}/wp-content/plugins/full-site-builder-for-elementor/extensions/wp-menu/menu.css?ver=5.2.3`,
    'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
  ];

  private readonly httpClient: HttpClient;
  private readonly mutex = new Mutex();
  private readonly pagesByCategoryCache = new Map<number, WordpressPage>();
  private readonly pagesCache = new Map<string | number, WordpressPage>();
  private pageCategoriesCache: WordpressCategory[];
  private pageScriptsCache: string[];
  private pageStylesCache: string[];

  public constructor(@Inject(WORDPRESS_URL) private readonly wordpressUrl: string, httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async getPageStyles(): Promise<string[]> {
    return this.mutex.dispatch(async () => {
      if (this.pageStylesCache) {
        return this.pageStylesCache;
      }
      const endpoint = `${this.wordpressUrl}/wp-json/wp/v2/page_styles`;
      const pageStyles = await this.httpClient
        .get<string[]>(endpoint)
        .pipe(
          tap(response => {
            response.push(...this.stylesToAppend);
          })
        )
        .toPromise();
      this.pageStylesCache = pageStyles;
      return pageStyles;
    });
  }

  public async getPageScripts(): Promise<string[]> {
    return this.mutex.dispatch(async () => {
      if (this.pageScriptsCache) {
        return this.pageScriptsCache;
      }
      const endpoint = `${this.wordpressUrl}/wp-json/wp/v2/page_scripts`;
      const pageScripts = await this.httpClient
        .get<string[]>(endpoint)
        .pipe(
          tap(response => {
            response.push(...this.scriptsToAppend);
          })
        )
        .toPromise();
      this.pageScriptsCache = pageScripts;
      return pageScripts;
    });
  }

  public async getCategories(): Promise<WordpressCategory[]> {
    return this.mutex.dispatch(async () => {
      if (this.pageCategoriesCache) {
        return this.pageCategoriesCache;
      }
      const endpoint = `${this.wordpressUrl}/wp-json/wp/v2/categories?per_page=100`;
      const pageCategories = await this.httpClient
        .get<WordpressCategory[]>(endpoint)
        .pipe(
          map(response =>
            response.map(({ id, name, slug }) => ({
              id,
              name,
              slug,
            }))
          )
        )
        .toPromise();
      this.pageCategoriesCache = pageCategories;
      return pageCategories;
    });
  }

  public async getFirstPageByCategory(id: number): Promise<WordpressPage> {
    if (this.pagesByCategoryCache.has(id)) {
      return this.pagesByCategoryCache.get(id);
    }
    const params = new HttpParams()
      .set('_fields', 'id,content,title')
      .set('per_page', '1')
      .set('categories', id.toString());
    const endpoint = `${this.wordpressUrl}/wp-json/wp/v2/pages`;
    const pages = await this.httpClient
      .get<WordpressPage[]>(endpoint, { params })
      .toPromise();
    if (!pages?.length) {
      return null;
    }
    const page = pages[0];
    if (page) {
      this.pagesByCategoryCache.set(id, page);
    }
    return page;
  }

  public async getPage(idOrSlug: number | string): Promise<WordpressPage> {
    if (this.pagesCache.has(idOrSlug)) {
      return this.pagesCache.get(idOrSlug);
    }
    const params = new HttpParams().set('_fields', 'id,content,title').set('per_page', '1');
    const suffix: number | string = !Number.isNaN(+idOrSlug) ? `/${idOrSlug}` : `?slug=${idOrSlug}`;
    const endpoint = `${this.wordpressUrl}/wp-json/wp/v2/pages` + suffix;
    const pages = await this.httpClient
      .get<WordpressPage[]>(endpoint, { params })
      .toPromise();
    const page = pages?.length ? pages[0] : null;
    if (page) {
      this.pagesCache.set(idOrSlug, page);
    }
    return page;
  }
}
