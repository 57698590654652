<div class="mt-6" *ngFor="let question of sendedMessages">
  <div *ngFor="let extraMessage of question.extraMessages">
    <app-message
      [message]="extraMessage"
      [isQuestion]="true"
      [showAvatar]="false"
    ></app-message>
  </div>
  <app-message
    [message]="question.pergunta"
    [isQuestion]="true"
    [showAvatar]="true"
  ></app-message>
  <app-answer
    [questionId]="question.idQuestao"
    [answer]="question?.answer"
    [options]="question?.opcoes"
    [inputProps]="question?.inputProps"
    [showBackButton]="showBackButton"
    (answerEvent)="handleAnswer($event)"
  ></app-answer>
</div>
