// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  bffApiUrl: 'https://bff-plataforma-hlg.quanta-previdencia.com.br',
  quantaApiUrl: 'https://apis-hom.quanta-previdencia.com.br',
  cookieDomain: 'beta.quantaprevidenciacoop.com.br',
  neoAssistDomain: 'quantaprevidenci.neoassist.com',
  simulatorUrl: 'https://simuladores.quantaprevidencia.com.br',
  wordpressUrl: 'https://pocwp.quanta-previdencia.com.br/wordpress',
  urlSite: 'https://site.hlg.portalquanta.com.br',
  credentials: {
    mixPanelToken: '11cbd2f35dcebfc9234abc84bc2cf9c7',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
