import { AbstractControl } from '@angular/forms';

export const dayMonthYearLimitValidator = (dateLimit: Date, limit: 'min' | 'max') => {
  return (control: AbstractControl) => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const [day, month, year] = value.split('/');
    const formDate = new Date(`${month}/${day}/${year}`);

    const isInvalidDate = formDate.toString() === 'Invalid Date';
    const hasInvalidLimit = limit === 'min' ? formDate < dateLimit : formDate > dateLimit;

    return isInvalidDate || hasInvalidLimit ? { invalidDate: true } : null;
  };
};
