/* eslint-disable @typescript-eslint/naming-convention */
export enum IndicatorsLabelEnum {
  PERFIL_RENDA_FIXA = 'Perfil Renda Fixa',
  PERFIL_QUANTA = 'Perfil Quanta',
  PERFIL_RENDA_VARIAVEL = 'Perfil Renda Variável',
  COOPREV = 'Cooprev',
  CDI = 'CDI',
  POUPANCA = 'Poupança',
  PLACEHOLDER = 'Placeholder',
}
