import { EventsMixPanelEnum } from '@shared/mixpanel/enums/events-mixpanel-enum';

export const NAME_QUESTION_INDEX = 0;
export const EMAIL_QUESTION_INDEX = 1;
export const AGE_QUESTION_INDEX = 2;
export const INVESTIMENTS_QUESTION_INDEX = 3;
export const HAS_ACCOUNT_INDEX = 5;

export const BASE_KEY = 'investor-profile-chat';

export const NAME_QUESTION_ID = 1;
export const EMAIL_QUESTION_ID = 2;
export const AGE_QUESTION_ID = 3;
export const INVESTIMENTS_QUESTION_ID = 4;
export const PAYMENT_QUESTION_ID = 5;
export const HAS_ACCOUNT_ID = 6;
export const CPF_QUESTION_ID = 7;
export const SIMULATION_RESULT_ID = 8;

export const mixPanelEventsByQuestionId = {
  [NAME_QUESTION_ID]: EventsMixPanelEnum.prencheuNome,
  [EMAIL_QUESTION_ID]: EventsMixPanelEnum.prencheuEmail,
  [AGE_QUESTION_ID]: EventsMixPanelEnum.prencheuIdade,
  [INVESTIMENTS_QUESTION_ID]: EventsMixPanelEnum.sentimentoInvestimento,
  [PAYMENT_QUESTION_ID]: EventsMixPanelEnum.desejoRendaMensalFutura,
  [HAS_ACCOUNT_ID]: EventsMixPanelEnum.possuiConta,
  [CPF_QUESTION_ID]: EventsMixPanelEnum.prencheuCpf,
  [SIMULATION_RESULT_ID]: EventsMixPanelEnum.resultadoDaSimulacao,
};
