import { Component, Input } from '@angular/core';
import { IndicatorsLabelEnum } from '@shared/services/investment/models/indicators-label.enum';
import { DescricaoPlanosEnum } from '@shared/services/investment/models/planos.enum';

export interface ChartItem {
  label: string;
  value: number;
  description?: string;
  color: string;
}

const BAR_HEIGHT = 200;

@Component({
  selector: 'app-bar-chart',
  templateUrl: 'bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent {
  @Input()
  public pageName: string;

  @Input()
  public set chartItems(chartItems: ChartItem[]) {
    this.items = chartItems;
    this.updateChart();
  }

  @Input()
  public chartDescription = '';

  @Input()
  public legendDescription = '';

  public labels: ChartItem[] = [];

  public maxValue: number;

  public minValue: number;

  private items: ChartItem[] = [];

  public get planName(): string {
    return DescricaoPlanosEnum[this.pageName] || DescricaoPlanosEnum.PRECAVER;
  }

  public get hasNegative(): boolean {
    return this.labels.some(label => label.value < 0);
  }

  public updateChart(): void {
    this.labels = this.items.filter(label => label.label !== IndicatorsLabelEnum.PLACEHOLDER);

    this.maxValue = Math.max(...this.labels.map(label => label.value));
    this.minValue = Math.min(...this.labels.map(label => label.value));
  }

  public getBarHeight(item: ChartItem, negativeBar = false): number {
    if (!item) {
      return 0;
    }

    const divider = negativeBar ? this.minValue : this.maxValue;

    return (BAR_HEIGHT * ((item.value * 100) / divider)) / 100;
  }
}
