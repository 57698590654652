<div class="w-100 h-100">
  <app-investor-profile-chat
    [hidden]="isSimulationResultVisible || isLoadingSimulation"
    (simulationEvent)="simulate($event)"
  >
  </app-investor-profile-chat>
  <div class="d-flex flex-column align-items-center justify-content-center h-100" *ngIf="isLoadingSimulation">
    <img
      class="avatar"
      src="assets/images/pri-tudo-ok.svg"
      alt="Assistente virtual Pri, fazendo um sinal de positivo com sua mão esquerda."
    />
    <span class="loader-title mt-4 mx-2">{{ 'simulation.loadingSimulation' | translate }}</span>
    <ng-lottie [options]="animationOptions" [styles]="animationStyles"></ng-lottie>
  </div>
  <app-simulator-panel
    [hidden]="!isSimulationResultVisible"
    class="w-100 h-100"
    (restartSimuation)="restartSimulation()"
    (receiveSimulationResultOnEmail)="receiveSimulationResultOnEmail()"
    [investorProfile]="investorProfile"
    [investmentKnowledge]="investmentKnowledge"
    [providerAccount]="providerAccount"
  ></app-simulator-panel>
</div>
