import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { buildHttpRequestQueryParams } from '@shared/functions/functions';
import { endpoints } from '../../../api/api.endpoints';
import { BFF_API_URL } from '../../../api/api.urls';
import { ApiDataResponse } from '../../../api/data/api-data-response';
import { IDownloadLaminaRequest } from './models/download-lamina.request';
import { IDownloadLaminaResponse } from './models/download-lamina.response';
import { IInvestimentoMedicoesSiteRequest } from './models/medicoes.request';
import { IInvestimentoMedicoesResponse } from './models/medicoes.response';
import { IIndicatorName } from '@shared/services/investment/models/medicoes.request';

@Injectable({
  providedIn: 'root',
})
export class InvestmentService {
  private httpClient: HttpClient;

  public constructor(httpBackend: HttpBackend, @Inject(BFF_API_URL) private readonly apiUrl: string) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async downloadInvestmentSheet(request: IDownloadLaminaRequest): Promise<IDownloadLaminaResponse> {
    const endpoint = `${this.apiUrl}${endpoints.investimento.downloadLamina}`;

    const response = await this.httpClient
      .get<ApiDataResponse<IDownloadLaminaResponse>>(`${endpoint}?${buildHttpRequestQueryParams(request)}`)
      .toPromise();

    return response.data;
  }

  public async getMeasurements(
    request: IInvestimentoMedicoesSiteRequest
  ): Promise<Record<IIndicatorName, IInvestimentoMedicoesResponse>> {
    const endpoint = `${this.apiUrl}${endpoints.investimentoV2.medicoes}`;

    const response = await this.httpClient
      .get<ApiDataResponse<Record<IIndicatorName, IInvestimentoMedicoesResponse>>>(
        `${endpoint}?${buildHttpRequestQueryParams(request)}`
      )
      .toPromise();

    return response.data;
  }
}
