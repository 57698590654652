import { Injectable } from '@angular/core';
import { TiposDeInvestimentoNomeEnum } from '@shared/services/investment/models/perfis-de-investimento.enum';
import { ICriarSimulacaoPlanoSiteResponse } from '@quantaprevidencia/quanta-domains';
import { BehaviorSubject } from 'rxjs';
import { SimulationType } from './plan-simulation.enum';

export type PlanSimulationStoreType = {
  selectedSimulation?: SimulationType;
  simulation?: ICriarSimulacaoPlanoSiteResponse;
  isLoading?: boolean;
  profilesDescription?: {
    [TiposDeInvestimentoNomeEnum.CONSERVADOR]: string;
    [TiposDeInvestimentoNomeEnum.MODERADO]: string;
    [TiposDeInvestimentoNomeEnum.ARROJADO]: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class PlanSimulationStoreService {
  public dataSubject = new BehaviorSubject<PlanSimulationStoreType>(null);

  public data$ = this.dataSubject.asObservable();

  public setData(key: string, value: any) {
    const currentState = this.dataSubject.value;
    this.dataSubject.next({ ...currentState, [key]: value });
  }

  public getData(key: string) {
    return this.dataSubject.value[key];
  }
}
