<div class="message-container" id="chat-tab">
  <div class="pagination-start"></div>
  <app-chat [hidden]="isEmailQuestion" [sendedMessages]="askedQuestions" (setAnswer)="setAnswer($event)"> </app-chat>
  <app-chat [hidden]="!isEmailQuestion" [sendedMessages]="emailQuestions" [showBackButton]="true"></app-chat>
  <div *ngIf="isAnswerQuiz && !isEmailQuestion" class="white-lighter-background">
    <div class="row d-flex justify-content-center line-space m-0">
      <button class="btn btn-primary" (click)="simulate()">
        {{ 'investor-profile-chat.simulation-button' | translate }}
      </button>
    </div>
  </div>
</div>
