<app-wordpress-sandwich-page
  [bottomPages]="['atendimento-footer', 'footer']"
  [topPages]="['navbar', 'fale-conosco-titulo']"
  titleSearchPage="fale-conosco-titulo"
  (ready)="onContentLoaded()"
>
  <div class="contact-us my-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div id="NeoassistCentral"></div>
        </div>
      </div>
    </div>
  </div>
</app-wordpress-sandwich-page>
