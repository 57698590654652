<div
  class="mt-6"
  *ngIf="answer"
  id="answer-{{questionId}}"
>
  <app-message
    [message]="answer?.texto"
    [isQuestion]="false"
    [showAvatar]="false"
  ></app-message>
</div>
<div *ngIf="!answer" class="mt-3">
  <div *ngIf="options" class="white-lighter-background">
    <div class="row line-space">
      <button
        *ngFor="let option of options"
        id="option-{{questionId}}-{{option.idOpcao}}"
        class="btn btn-primary col-12"
        (click)="onClickOption(option)"
      >
        {{ option.texto }}
      </button>
    </div>
  </div>
  <div *ngIf="inputProps?.inputType">
    <form [formGroup]="form" autocomplete="off" class="white-lighter-background d-flex align-items-center">
      <span class="mr-2" *ngIf="inputProps?.inputType == 'currency'">R$</span>
      <input
        id="input-{{inputProps?.inputType}}-{{questionId}}"
        [type]="inputProps?.inputType == 'number' ? 'number' : 'text'"
        class="input"
        formControlName="actionInput"
        [placeholder]="inputProps.placeholder"
        (input)="onChange()"
      />
      <button
        id="button-{{inputProps?.inputType}}-{{questionId}}"
        class="btn btn-primary mr-1"
        (click)="onClickContinue()"
        [disabled]="!form.controls.actionInput.valid"
      >
        {{ 'investor-profile-chat.continue-button' | translate }}
      </button>
      <button
        class="btn btn-primary"
        *ngIf="showBackButton"
        (click)="onClickBack()"
      >
        {{ 'investor-profile-chat.back-button' | translate }}
      </button>
    </form>
  </div>
</div>
