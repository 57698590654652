import { ViewChild, Component, Output, EventEmitter, OnInit } from '@angular/core';
import { EventsMixPanelEnum } from '@shared/mixpanel/enums/events-mixpanel-enum';
import { GenericSimulatorEvent } from '@shared/mixpanel/events/simulator-events';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SITE_QUANTA_CONTRATE_PLANO_URL, ID_CANAL, RDS_SIMULATION_EVENT_ID } from '@shared/constants';
import { EmailModalComponent } from '@pages/plan-simulation/email-modal/email-modal.component';
import { IEmailModalEvent } from '@pages/plan-simulation/email-modal/email-modal.component';
import {
  ICriarSimulacaoPlanoSiteResponse,
  ICriarSimulacaoSiteRequest,
  ISimulacaoSitePayloadEmailRequest,
  IEventoRDStationMetadado,
} from '@quantaprevidencia/quanta-domains';
import { FormGroup } from '@angular/forms';
import { PlanSimulationStoreService } from '@pages/plan-simulation/plan-simulation-store.service';
import { MixPanelService } from '@shared/services/mix-panel/mix-panel.service';
import { SimulationHeaderComponent } from '@pages/plan-simulation/simulation-header/simulation-header.component';
import { SimulationResultComponent } from '@pages/plan-simulation/simulation-result/simulation-result.component';
import { PlanSimulationPayloadCreator } from '@pages/plan-simulation/plan-simulation-payload-creator';
import { SimulationInvestmentProfileComponent } from '@pages/plan-simulation/simulation-investment-profile/simulation-investment-profile.component';
import { TiposDeInvestimentoNomeEnum } from '@shared/services/investment/models/perfis-de-investimento.enum';
import { ParametrizationService } from '@shared/services/parametrization/parametrization.service';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';
import { formatDateBRToEN } from '@shared/functions/functions';
import { DEFAULT_RETIREMENT_AGE } from '@pages/plan-simulation/plan-simulation.enum';
import { ICriarEventoRDStationV2 } from '@shared/services/analytics/models/create-rds-event.request';
import { RDS_SIMULATION_EVENT_TYPE } from '@shared/constants';
import { MIN_SIMULATION_AGE } from '@pages/plan-simulation/plan-simulation.enum';

@Component({ template: '' })
export abstract class BaseSimulationComponent {
  @ViewChild(SimulationHeaderComponent)
  public simulationHeader: SimulationHeaderComponent;

  @ViewChild(SimulationInvestmentProfileComponent)
  public simulationInvestmentProfile: SimulationInvestmentProfileComponent;

  @ViewChild(SimulationResultComponent)
  public simulationResult: SimulationResultComponent;

  @Output()
  public changeSimulationEvent = new EventEmitter<ICriarSimulacaoSiteRequest>();

  @Output()
  public sendEmailEvent = new EventEmitter<ISimulacaoSitePayloadEmailRequest>();

  public simulation: ICriarSimulacaoPlanoSiteResponse;
  public isLoading: boolean;
  public form: FormGroup;
  public lastRequest: ICriarSimulacaoSiteRequest;
  public mixpanelEvent: EventsMixPanelEnum;
  public resultUrl: string;

  public constructor(
    public planSimulationStoreService: PlanSimulationStoreService,
    public mixPanelService: MixPanelService,
    public modalService: NgbModal,
    public parametrizationService: ParametrizationService,
    public analyticsService: AnalyticsService
  ) {
    this.setResultUrl();
  }

  public hire() {
    this.mixPanelService.track(new GenericSimulatorEvent(this.mixpanelEvent));
    window.open(this.resultUrl, '_blank');
  }

  public redoSimulation() {
    this.planSimulationStoreService.setData('simulation', null);
    Object.keys(this.form.controls).forEach(controlName => this.form.controls[controlName].enable());

    [this.simulationHeader.form, this.form].forEach(form => {
      form.reset();
    });
  }

  public sendEmail() {
    const modal = this.modalService.open(EmailModalComponent);
    modal.componentInstance.emailModalEvent.subscribe((modalData: IEmailModalEvent) => {
      const event = { simulacao: this.lastRequest, ...modalData };
      this.sendEmailEvent.emit(event);

      const payload = this.generateRDStationEvent(RDS_SIMULATION_EVENT_TYPE.emailCooprev, modalData);
      this.analyticsService.createRDStationEvent(payload);
    });
  }

  public setRisk(creator: PlanSimulationPayloadCreator, risk: 'invalidity' | 'death', switchEnable: boolean) {
    if (switchEnable) {
      const changedSlider = this.simulationResult?.changedSlider || '';
      const hasChange = changedSlider.includes(risk);
      const hasContribution = hasChange && changedSlider.includes('Contribution');
      const sliderControlName = hasChange ? changedSlider : `${risk}Capital`;

      const risco = risk === 'invalidity' ? 'invalidez' : 'morte';
      const field = hasContribution ? 'valorContribuicaoMensal' : 'valorCapitalSegurado';
      const value = this.simulationResult.form.value[sliderControlName];

      return creator.setRisk(risco, field, value);
    }

    return creator;
  }

  public setProponentBenefit(creator: PlanSimulationPayloadCreator) {
    if (this.simulationResult.changedSlider === 'futureIncome') {
      return creator;
    }

    const hasFutureBalance = this.simulationResult.changedSlider === 'futureTotalBalance';
    const sliderControlName = hasFutureBalance ? 'futureTotalBalance' : 'personalMonthlyContribution';

    const field = hasFutureBalance ? 'valorSaldoFuturo' : 'valorContribuicaoMensal';
    const value = this.simulationResult.form.value[sliderControlName];

    return creator.setProponentBenefit(field, Number(value));
  }

  public getInvestmentProfile() {
    return (
      this.simulationInvestmentProfile?.form?.controls?.investmentProfile?.value?.id ||
      TiposDeInvestimentoNomeEnum.MODERADO
    );
  }

  public calculateMinYear() {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - MIN_SIMULATION_AGE);

    return currentDate;
  }

  private async setResultUrl() {
    const params = await this.parametrizationService.getParametersByKey('SIMULADOR_SITE_COOPREV_URL_CONTRATAR');
    this.resultUrl = params.valor.link;
  }

  private generateRDStationEvent(tag: string, complement: IEmailModalEvent): ICriarEventoRDStationV2 {
    return {
      ...(complement || {}),
      idCanal: ID_CANAL,
      idTipoEvento: RDS_SIMULATION_EVENT_ID,
      tags: [tag],
      metadados: this.generateMetadata(),
    };
  }

  private generateMetadata(): IEventoRDStationMetadado[] {
    return [
      {
        nome: 'data_nascimento',
        valor: formatDateBRToEN(this.form.getRawValue().birthdate),
      },
      {
        nome: 'idade_aposentadoria',
        valor: String(DEFAULT_RETIREMENT_AGE),
      },
      {
        nome: 'vinculo',
        valor: this.simulationHeader.form.value.selectedBond,
      },
      {
        nome: 'renda_futura',
        valor: String(this.simulationResult.form.value.futureIncome || this.form.value.income),
      },
      {
        nome: 'perfil_investimento',
        valor: String(this.simulationInvestmentProfile.form.value.investmentProfile.id),
      },
    ];
  }
}
