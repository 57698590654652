/* eslint-disable @typescript-eslint/naming-convention */
export enum PerfisDeInvestimentoIdEnum {
  RENDA_FIXA = 4,
  QUANTA = 5,
  RENDA_VARIAVEL = 6,
}

export enum PerfisDeInvestimentoNomeEnum {
  QUANTA = 'QUANTA',
  RENDA_FIXA = 'RENDA_FIXA',
  RENDA_VARIAVEL = 'RENDA_VARIAVEL',
}

export enum TiposDeInvestimentoNomeEnum {
  CONSERVADOR = 'CONSERVADOR',
  MODERADO = 'MODERADO',
  ARROJADO = 'ARROJADO',
}

export const investmentIds = {
  [TiposDeInvestimentoNomeEnum.CONSERVADOR]: PerfisDeInvestimentoIdEnum.RENDA_FIXA,
  [TiposDeInvestimentoNomeEnum.MODERADO]: PerfisDeInvestimentoIdEnum.QUANTA,
  [TiposDeInvestimentoNomeEnum.ARROJADO]: PerfisDeInvestimentoIdEnum.RENDA_VARIAVEL,
};
