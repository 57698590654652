/* istanbul ignore file */
import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Inject,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { CookieConsentService } from '../../../services/cookie-consent/cookie-consent.service';
import { GoogleTagManagerService } from '../../../services/google-tag-manager/google-tag-manager.service';
import { PreloaderService } from '../../../services/preloader/preloader.service';
import { ScriptService } from '../../../services/script/script.service';
import { StyleService } from '../../../services/style/style.service';
import { WordpressCategory } from '../models/wordpress-category';
import { WordPressService, WORDPRESS_URL } from '../services/wordpress.service';
import { WordPressContentComponent } from '../wordpress-content/wordpress-content.component';

@Component({
  selector: 'app-wordpress-container',
  templateUrl: './wordpress-container.component.html',
  styleUrls: ['./wordpress-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WordPressContainerComponent implements AfterViewInit {
  @ContentChildren(WordPressContentComponent, { descendants: true })
  public content: QueryList<WordPressContentComponent>;

  @Output()
  public ready = new EventEmitter<WordPressContainerComponent>();

  public displayContent: boolean;
  public errorLoadingContent: boolean;
  public scripts: string[];
  public styles: string[];
  public categories: WordpressCategory[];
  public wordpressStyles: string[];

  private readonly navigationElements = new Array<HTMLElement>();

  public constructor(
    @Inject(WORDPRESS_URL) private readonly wordpressUrl: string,
    private router: Router,
    private cookieService: CookieConsentService,
    private googleTagManagerService: GoogleTagManagerService,
    private preloaderService: PreloaderService,
    private scriptService: ScriptService,
    private styleService: StyleService,
    private wordPressService: WordPressService
  ) {}

  public ngAfterViewInit(): void {
    this.initialize();
  }

  public reloadContent() {
    this.errorLoadingContent = false;
    this.initialize();
  }

  private async initialize(): Promise<void> {
    if (!this.content.length) {
      return;
    }
    await Promise.all([this.loadStyles(), this.retrieveWordPressCategories(), this.createElementorContent()]);
    const components = this.content.toArray();
    await this.loadComponents(components);
    this.loadScripts();
    this.displayContent = true;
    this.preloaderService.hide();
    this.ready.emit(this);
    this.googleTagManagerService.initialize();
    this.cookieService.checkCookieConsent();
  }

  private loadComponents(components: WordPressContentComponent[]) {
    components.sort((l, r) => l.priority - r.priority);
    const promises = components.map(async component => {
      component.container = this;
      try {
        await this.loadComponentContent(component);
      } catch (error) {
        this.handleError(error);
      }
    });
    return Promise.all(promises);
  }

  private loadComponentContent(component: WordPressContentComponent) {
    return component.load().then(() => {
      this.handleComponentNavigation(component);
      this.handleMobileComponentNavigation(component);
      this.fixMenuMobileLayout(component);
      this.fixContactButton(component);
    });
  }

  private handleError(error: Error) {
    this.preloaderService.hide();
    this.errorLoadingContent = true;
  }

  private storeNavigationElement(element: HTMLElement) {
    if (this.navigationElements.find(x => x === element)) {
      return;
    }
    this.navigationElements.push(element);
  }

  // Este método altera o z-index do botão de fale conosco na home, evitando que ele fique sobre o overlay
  private fixContactButton(component: WordPressContentComponent) {
    const element = component.element.nativeElement as HTMLElement;
    const buttons = element.querySelectorAll('.fa-envelope');
    if (!buttons.length) {
      return;
    }

    buttons.forEach(button => {
      const parent = button.closest('.elementor-widget-button') as HTMLElement;
      if (parent) {
        parent.style.zIndex = '98';
      }
    });
  }

  private fixMenuMobileLayout(component: WordPressContentComponent) {
    const menu = component.element.nativeElement.querySelector('#quadmenu') as HTMLElement;
    if (!menu) {
      return;
    }

    const parent = menu.closest('.elementor-element-populated') as HTMLElement;
    if (!parent) {
      return;
    }

    // esse estilo remove um espaçamento desnecessário no menu mobile (barra cinza na parte inferior do menu)
    parent.style.paddingBottom = '0';
  }

  private handleMobileComponentNavigation(component: WordPressContentComponent) {
    return new Promise<void>(resolve => {
      const menuToggle = component.element.nativeElement.querySelector('[data-quadmenu="collapse"]') as HTMLElement;
      if (!menuToggle) {
        resolve();
        return;
      }

      const menu = component.element.nativeElement.querySelector('#quadmenu_0') as HTMLElement;
      if (!menu) {
        resolve();
        return;
      }

      menuToggle.onclick = ($event: MouseEvent) => {
        if (menuToggle.classList.contains('collapsed')) {
          return;
        }

        $event.stopPropagation();
        $event.preventDefault();

        menuToggle.classList.add('collapsed');
        menuToggle.setAttribute('aria-expanded', 'false');

        menu.classList.remove('in');
        menu.setAttribute('aria-expanded', 'false');
      };
      resolve();
    });
  }

  private handleComponentNavigation(component: WordPressContentComponent) {
    return new Promise<void>(resolve => {
      const elements: HTMLCollection = component.element.nativeElement.getElementsByTagName('a');

      Array.from(elements).forEach((element: HTMLElement) => {
        this.storeNavigationElement(element);

        if (!element.getAttribute) {
          return;
        }

        const href = element.getAttribute('href');
        if (!href || !href.startsWith('/')) {
          return;
        }

        element.onclick = ($event: MouseEvent) => {
          $event.stopPropagation();
          $event.preventDefault();

          const target = $event.target as HTMLElement;
          const elementNavigationUrl = this.getNavigationUrlFromElement(target);
          if (!elementNavigationUrl) {
            return;
          }

          this.preloaderService.show();
          this.router.navigateByUrl('/empty', { skipLocationChange: true }).then(() => {
            const routeUrl = elementNavigationUrl.substring(1);
            this.router.navigate([routeUrl]);
          });
        };
      });
      resolve();
    });
  }

  private getNavigationUrlFromElement(target: HTMLElement) {
    const maxParentLevel = 5;
    let currentParentLevel = 0;
    while (currentParentLevel < maxParentLevel) {
      if (!target) {
        return null;
      }

      const href = target.getAttribute('href');
      if (href) {
        return href;
      }

      target = target.parentElement;
      currentParentLevel++;
    }

    return null;
  }

  private async retrieveWordPressCategories(): Promise<void> {
    this.categories = await this.wordPressService.getCategories();
  }

  private async loadScripts() {
    this.scripts = await this.wordPressService.getPageScripts();
    await this.scriptService.createScriptsFromUrls(this.scripts, 'serial');
  }

  private async loadStyles() {
    this.wordpressStyles = await this.wordPressService.getPageStyles();
    if (!this.wordpressStyles) {
      return;
    }
    const styles = this.wordpressStyles.filter(x => !x.includes('/post-') && !x.includes('theme'));
    const promises = styles.map(style => this.styleService.createStyleFromUrl(style));
    await Promise.all(promises);
  }

  private createElementorContent() {
    return new Promise<void>(resolve => {
      const elementorConfig = `
      var elementorFrontendConfig = {
        environmentMode: { edit: false, wpPreview: false },
        is_rtl: false,
        breakpoints: { xs: 0, sm: 480, md: 768, lg: 1025, xl: 1440, xxl: 1600 },
        version: '2.5.16',
        urls: { assets: '${this.wordpressUrl}/wordpress/wp-json/wp/v2/wp-content/plugins/elementor/assets/' },
        settings: {
          page: [],
          general: { elementor_global_image_lightbox: 'yes', elementor_enable_lightbox_in_editor: 'yes' },
        },
        post: { id: 163, title: '01_ Landing Page - Elementor', excerpt: '' },
        facebook_sdk: { isLoading: false, isLoaded: false },
      };

      var ElementorProFrontendConfig = elementorFrontendConfig;

      var elementorCommonConfig = elementorFrontendConfig;

      /* <![CDATA[ */
      var ElementorMenusFrontendConfig = {
        ajaxurl: '${this.wordpressUrl}/wordpress/wp-json/wp/v2/wp-admin/admin-ajax.php',
        nonce: '3321fd4dff',
      };
      var elementorScreenReaderText = { expand: 'expand child menu', collapse: 'collapse child menu' };
      var elementorSecondaryScreenReaderText = { expand: 'expand child menu', collapse: 'collapse child menu' };
      /* ]]> */
    `;
      this.scriptService.createScriptFromText(elementorConfig);
      resolve();
    });
  }
}
