import { animate, state, style, transition, trigger } from '@angular/animations';

export const toggleExploreAnimation = trigger('toggleExploreAnimation', [
  state('closed', style({ right: '{{right}}' }), { params: { right: '0' } }),
  state('open', style({ right: 0 })),
  transition('closed => open', animate('500ms 0ms cubic-bezier(0.05, 0.74, 0.2, 0.99)')),
  transition('open => closed', animate('1000ms 0ms cubic-bezier(0.05, 0.74, 0.2, 0.99)')),
]);

export const exploreToggleAnimation = trigger('exploreToggleAnimation', [
  state('closed', style({ width: '160px', left: '-160px' })),
  state('open', style({ width: '60px', left: '-60px' })),
  transition('closed => open', animate('200ms ease-out')),
  transition('open => closed', animate('200ms ease-in')),
]);

export const exploreIconAnimation = trigger('exploreIconAnimation', [
  state('closed', style({ transform: 'rotate(0)' })),
  state('open', style({ transform: 'rotate(-90deg)' })),
  transition('closed => open', animate('300ms 400ms ease-out')),
  transition('open => closed', animate('300ms 400ms ease-in')),
]);

export const exploreTextAnimation = trigger('exploreTextAnimation', [
  state('closed', style({ display: 'default', width: '115px' })),
  state('open', style({ display: 'none', width: 0 })),
  transition('closed => open', animate('100ms ease-out')),
  transition('open => closed', animate('100ms ease-in')),
]);
