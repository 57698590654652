export enum SimulationType {
  fast = 'fast',
  full = 'full',
}

export const DEFAULT_RETIREMENT_AGE = 65;

export const DEFAULT_CPF = '53821958030';

export const DEFAULT_UF = 'SC';

export const DEFAULT_CBO = '2251-25';

export const DEFAULT_GENDER = 1;

export const MIN_SIMULATION_AGE = 85;

export const MIN_MONTHLY_CONTRIBUTION = 50;
