import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimulationType } from '@pages/plan-simulation/plan-simulation.enum';
import { PlanSimulationStoreService } from '@pages/plan-simulation/plan-simulation-store.service';
import { disableFormOnLoading } from '@shared/functions/functions';

@Component({
  selector: 'app-simulation-header',
  templateUrl: './simulation-header.component.html',
  styleUrls: ['../plan-simulation.component.scss'],
})
export class SimulationHeaderComponent implements OnInit {
  public form: FormGroup;
  public selectedSimulation: SimulationType;
  public simulationType = SimulationType;

  public groupOptions = [
    { id: SimulationType.fast, name: 'Simulação rápida' },
    { id: SimulationType.full, name: 'Simulação completa' },
  ];

  public constructor(private fb: FormBuilder, private planSimulationStoreService: PlanSimulationStoreService) {
    this.form = this.fb.group({
      selectedBond: [null, Validators.required],
    });
  }

  public ngOnInit() {
    this.planSimulationStoreService.data$.subscribe(data => {
      this.selectedSimulation = data.selectedSimulation;
      disableFormOnLoading(this.form.controls, data.isLoading);
    });
  }

  public setSelectedSimulation(selectedSimulation: { id: SimulationType; name: string }) {
    this.planSimulationStoreService.setData('simulation', null);
    this.planSimulationStoreService.setData('selectedSimulation', selectedSimulation.id);
  }
}
