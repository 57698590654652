import { Injectable, Inject } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { QUANTA_API_URL } from '../../../api/api.urls';
import { endpointsBFFSite } from '../../../api/api.endpoints';
import { ApiDataResponse } from '../../../api/data/api-data-response';
import {
  ISimulacaoSitePayloadEmail,
  ICriarSimulacaoSiteRequest,
  ICriarSimulacaoPlanoSiteResponse,
  ISimulacaoSitePayloadEmailRequest,
} from '@quantaprevidencia/quanta-domains';

@Injectable({
  providedIn: 'root',
})
export class SimuladorPlanoService {
  private httpClient: HttpClient;

  public constructor(private httpBackend: HttpBackend, @Inject(QUANTA_API_URL) private readonly apiUrl: string) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async sendEmail(request: ISimulacaoSitePayloadEmail) {
    const endpoint = `${this.apiUrl}${endpointsBFFSite.simularPlano.enviarEmail}`;
    await this.httpClient.post(endpoint, request).toPromise();
  }

  public async buildEmailPayload(request: ISimulacaoSitePayloadEmailRequest): Promise<ISimulacaoSitePayloadEmail> {
    const endpoint = `${this.apiUrl}${endpointsBFFSite.simularPlano.payloadEmail}`;

    const response = await this.httpClient
      .post<ApiDataResponse<ISimulacaoSitePayloadEmail>>(endpoint, request)
      .toPromise();

    return response.data;
  }

  public async simular(request: ICriarSimulacaoSiteRequest): Promise<ICriarSimulacaoPlanoSiteResponse> {
    const endpoint = `${this.apiUrl}${endpointsBFFSite.simularPlano.toString()}`;

    const response = await this.httpClient
      .post<ApiDataResponse<ICriarSimulacaoPlanoSiteResponse>>(endpoint, request)
      .toPromise();

    return response.data;
  }
}
