<section class="profitability-graph">
  <div class="col-md-10 col-sm-8 m-auto header text-center mb-3">
    <div class="row justify-content-center">
      <span class="title">{{ 'profitabilityChart.title' | translate }}</span>
    </div>
  </div>
  <div class="col-lg-4 col-sm-8 m-auto header text-center mb-3">
    <div *ngIf="pageName === 'LANDING-PAGE'" class="row mt-3 justify-content-center">
      <span class="subtitle">{{ 'profitabilityChart.subtitle' | translate }}</span>
    </div>
    <div class="mt-3" *ngIf="pageName !== 'LANDING-PAGE'">
      <app-button-group
        [options]="chartFilterOptions"
        [selectedOption]="{ id: selectedChartFilter?.id }"
        (optionSelected)="onFilterSelected($event)"
      >
      </app-button-group>
    </div>
  </div>

  <div class="content mt-4 d-flex justify-content-center">
    <div class="row">
      <app-bar-chart
        *ngIf="!isLoading"
        [chartDescription]="i18n.profitabilityWarning"
        [legendDescription]="i18n.investmentProfilesWarning"
        [pageName]="pageName"
        [chartItems]="items"
      >
      </app-bar-chart>
      <app-skeleton class="mt-2" [isLoading]="isLoading" [width]="650" [height]="300"></app-skeleton>
    </div>
  </div>

  <div *ngIf="pageName !== 'LANDING-PAGE'" class="secondary-content mt-4 d-flex justify-content-center">
    <div class="profile-percentages">
      <div class="title mb-2">{{ 'profitabilityChart.cdiPercentages.title' | translate }}</div>
      <div class="profiles">
        <div *ngFor="let profile of cdiByProfile" class="profile">
          <div class="profile-title">{{ profile.name }}</div>
          <div *ngIf="!isLoading" class="profile-value">{{ profile.value }}</div>
          <app-skeleton [isLoading]="isLoading" [width]="89" [height]="21"></app-skeleton>
        </div>
      </div>
      <div class="legend mt-3">
        {{ 'profitabilityChart.cdiPercentages.legend' | translate }}
      </div>
    </div>
    <div class="pdf-slides">
      <div class="title mb-2">{{ 'profitabilityChart.investmentSheets.title' | translate }}</div>
      <div>
        <div
          *ngFor="let button of downloadSheetButtons"
          (click)="downloadInvestmentSheet(button)"
          class="download-button mb-2"
        >
          <i *ngIf="!button.loading" [style.color]="button.color" class="ft-download"></i>
          <div
            *ngIf="button.loading"
            [style.color]="button.color"
            class="spinner-border spinner-border-sm"
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <span [style.color]="button.color" class="profile-title"> {{ button.name }}</span>
        </div>
      </div>
    </div>
  </div>
</section>
