import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { endpoints } from '../../../api/api.endpoints';
import { BFF_API_URL } from '../../../api/api.urls';
import { ApiDataResponse } from '../../../api/data/api-data-response';

export interface Parameters {
  idParametro: string;
  chave: string;
  valor: any;
}

@Injectable({
  providedIn: 'root',
})
export class ParametrizationService {
  private httpClient: HttpClient;

  public constructor(httpBackend: HttpBackend, @Inject(BFF_API_URL) private readonly apiUrl: string) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async getParametersByKey(key: string): Promise<Parameters> {
    const endpoint = `${this.apiUrl}${endpoints.parametros.listar}`;
    const params = new HttpParams().set('chave', key);
    return this.httpClient
      .get<ApiDataResponse<Parameters>>(endpoint, {
        params,
      })
      .toPromise()
      .then(parameter => {
        return parameter.data;
      });
  }
}
