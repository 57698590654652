/* eslint-disable no-underscore-dangle */
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SimulatorPanelItemEnum } from './simulator-panel-item.enum';

@Component({
  selector: 'app-simulator-panel-item',
  templateUrl: './simulator-panel-item.component.html',
  styleUrls: ['./simulator-panel-item.component.scss'],
})
export class SimulatorPanelItemComponent {
  @Input()
  public maximumValue: number;

  @Input()
  public minimumValue: number;

  public get order() {
    return this._order;
  }

  @Input()
  public set order(value: number) {
    this._order = value;

    const html = this.element.nativeElement as HTMLElement;
    html.style.order = value.toString();

    const elementWidthBreakpoint = 360;
    const elementWidth = html.offsetWidth;
    if (elementWidth <= elementWidthBreakpoint) {
      this.fontSize = value === 0 ? 2.5 : 1.25;
    } else {
      this.fontSize = value === 0 ? 3 : 1.5;
    }
  }

  @Input()
  public title: string;

  @Input()
  public titleClass: string;

  @Input()
  public valueClass: string;

  @Input()
  public customSliderClass = 'green-slider';

  @Input()
  public sliderLineColor = '#98bf3e';

  @Input()
  public containerClass: string;

  @Input()
  public disabled = false;

  @Input()
  public type: SimulatorPanelItemEnum;

  @Output()
  public valueChange = new EventEmitter<number>();

  public get value(): number {
    return this._value;
  }

  @Input()
  public set value(value: number) {
    if (this._value === value) {
      return;
    }

    this._value = value;
    this.onValueChanged(value);
  }

  @ViewChild('input', { static: true })
  public input: ElementRef;

  @ViewChild('valueRangeTooltip', { static: true })
  public valueRangeTooltip: NgbTooltip;

  public fontSize: number;
  public get maximumLength() {
    if (this.type === SimulatorPanelItemEnum.integer) {
      return 2;
    }
    return 13;
  }
  public simulatorPanelItemEnum = SimulatorPanelItemEnum;

  private _order: number;
  private _value: number;

  public constructor(private readonly element: ElementRef, private readonly translate: TranslateService) {
    this.minimumValue = 0;
    this.maximumValue = 100;
    this.fontSize = 1.25;
  }

  public onKeyPress($event: KeyboardEvent) {
    const pattern = /[0-9]/;
    if (!pattern.test($event.key)) {
      $event.preventDefault();
    }
  }

  public onInput() {
    const inputValue = this.input.nativeElement.value;
    if (!inputValue) {
      return;
    }

    const value = this.parseTextToNumber(inputValue);
    const localeNumber = this.formatNumber(value);
    this.input.nativeElement.value = localeNumber;
    this.value = value;

    this.validateNumberRange(value);
  }

  public hide() {
    this.element.nativeElement.style.display = 'none';
  }

  public show() {
    this.element.nativeElement.style.display = '';
  }

  private validateNumberRange(value: number) {
    if (value < this.minimumValue) {
      const minimumValue = this.formatNumber(this.minimumValue);
      const message = this.translate.instant('simulationPanelItem.minimumAllowedValue', { value: minimumValue });
      this.valueRangeTooltip.ngbTooltip = message;
      this.valueRangeTooltip.open();
      return;
    }

    if (value > this.maximumValue) {
      const maximumValue = this.formatNumber(this.maximumValue);
      const message = this.translate.instant('simulationPanelItem.maximumAllowedValue', { value: maximumValue });
      this.valueRangeTooltip.ngbTooltip = message;
      this.valueRangeTooltip.open();
      return;
    }

    this.valueRangeTooltip.close();
  }

  private adjustInputWidthToContent() {
    const html = this.input.nativeElement as HTMLElement;
    if (this.type === SimulatorPanelItemEnum.integer) {
      html.style.width = '3ch';
      return;
    }

    const value: string = this.input.nativeElement.value;
    const dotsCount = value.split('.').length - 1;
    const commasCount = value.split(',').length - 1;
    const charWidth = 5;
    const offset = (dotsCount + commasCount) * charWidth;
    html.style.width = `calc(${value.length}ch - ${offset}px)`;
  }

  private onValueChanged(value: number) {
    if (value >= this.minimumValue && value <= this.maximumValue) {
      this.valueChange.emit(+value);
    }

    this.setTextFromValue(value);
    this.adjustInputWidthToContent();
  }

  private formatNumber(value: number) {
    if (this.type === SimulatorPanelItemEnum.integer) {
      return value.toString();
    }

    return value.toLocaleString('pt', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }

  private parseTextToNumber(text: string) {
    if (this.type === SimulatorPanelItemEnum.integer) {
      const textWithOnlyNumbers = text.replace(/[^\d]/g, '');
      return parseInt(textWithOnlyNumbers, 10) || 0;
    } else {
      const textWithOnlyNumbers = text
        .replace(/(.*){1}/, '0$1')
        .replace(/[^\d]/g, '')
        .replace(/(\d\d?)$/, '.$1');
      return parseFloat(textWithOnlyNumbers);
    }
  }

  private setTextFromValue(value: number) {
    const localeNumber = this.formatNumber(value);
    this.input.nativeElement.value = localeNumber;
  }
}
