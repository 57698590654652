import { Input, Output, EventEmitter, Component } from '@angular/core';
import { IQuestion, Answer } from '@pages/home/services/simulator/models/questionario-simulacao';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
})
export class ChatComponent {
  @Input() public sendedMessages: IQuestion[];
  @Input() public showBackButton = false;

  @Output() public setAnswer = new EventEmitter<Answer>();

  public handleAnswer(answer: Answer) {
    this.setAnswer.emit(answer);
  }
}
