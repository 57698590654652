/* eslint-disable no-underscore-dangle */
import { Component, DoCheck, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { PreloaderService } from '../../../services/preloader/preloader.service';
import { WordPressContentComponent } from '../wordpress-content/wordpress-content.component';

@Component({
  selector: 'app-wordpress-sandwich-page',
  templateUrl: './wordpress-sandwich-page.component.html',
})
export class WordpressSandwichPageComponent implements DoCheck {
  @Input()
  public bottomPages: string[];

  @Input()
  public topPages: string[];

  @Input()
  public titleSearchPage: string;

  @Output()
  public ready = new EventEmitter();

  @ViewChildren(WordPressContentComponent)
  public content: QueryList<WordPressContentComponent>;

  public get contentReady(): boolean {
    if (this._contentReady) {
      return this._contentReady;
    }
    if (!this.content) {
      return false;
    }
    this._contentReady =
      this.content.filter(component => this.topPages.includes(component.name.toString()) && component.contentReady)
        .length === this.topPages.length;
    if (this._contentReady) {
      this.ready.emit();
    }
    return this._contentReady;
  }

  @Input()
  public set contentReady(value: boolean) {
    this._contentReady = value;
  }

  private _contentReady?: boolean;

  public constructor(private preloaderService: PreloaderService) {}

  public ngDoCheck(): void {
    if (this?.contentReady) {
      this.preloaderService.hide();
    }
  }
}
