import { Component, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimulatorPanelItemEnum } from '@pages/home/components/simulator-panel-item/simulator-panel-item.enum';
import { ICriarSimulacaoPlanoSiteResponse } from '@quantaprevidencia/quanta-domains';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PlanSimulationStoreService } from '@pages/plan-simulation/plan-simulation-store.service';
import { SimulationType } from '@pages/plan-simulation/plan-simulation.enum';

@Component({
  selector: 'app-simulation-result',
  templateUrl: './simulation-result.component.html',
  styleUrls: ['./simulation-result.component.scss'],
})
export class SimulationResultComponent implements OnInit {
  @Output()
  public changeSimulationEvent = new EventEmitter<void>();

  @ViewChild('deathSwitch')
  public deathSwitch: ElementRef;

  @ViewChild('invaliditySwitch')
  public invaliditySwitch: ElementRef;

  public form: FormGroup;
  public formSimulation: ICriarSimulacaoPlanoSiteResponse['formulario'];
  public simulatorPanelItemEnum = SimulatorPanelItemEnum;
  public selectedSimulation: SimulationType;
  public simulationType = SimulationType;
  public changedSlider: string;
  public isLoadingSimulation = false;

  private previousRisk = {
    deathContribution: 0,
    deathCapital: 0,
    invalidityContribution: 0,
    invalidityCapital: 0,
  };
  private hasFormUpdate = false;
  private sliderChangeSubject = new BehaviorSubject<string>(null);

  public constructor(private fb: FormBuilder, private planSimulationStoreService: PlanSimulationStoreService) {
    this.form = this.fb.group({
      personalMonthlyContribution: [null, Validators.required],
      companyMonthlyContribution: [null, Validators.required],
      deathContribution: [null, Validators.required],
      deathCapital: [null, Validators.required],
      invalidityContribution: [null, Validators.required],
      invalidityCapital: [null, Validators.required],
      totalPersonalMonthlyContribution: [null, Validators.required],
      futureIncome: [null, Validators.required],
      futureTotalBalance: [null, Validators.required],
    });
  }

  public ngOnInit() {
    this.sliderChangeSubject.pipe(debounceTime(600)).subscribe(formControlName => {
      if (this.hasFormUpdate) {
        this.hasFormUpdate = false;
        return;
      }
      this.changedSlider = formControlName;
      this.changeSimulationEvent.emit();
    });

    this.planSimulationStoreService.data$.subscribe(data => {
      this.formSimulation = data.simulation.formulario;
      this.setFormData(data.simulation);
      this.isLoadingSimulation = data.isLoading;
      this.selectedSimulation = data.selectedSimulation;
    });
  }

  public onChangeSlider(formControlName: string, value: number) {
    this.form.controls[formControlName].setValue(value);
    this.sliderChangeSubject.next(formControlName);
  }

  public onChangeSwitch(risk: 'death' | 'invalidity', enable: boolean) {
    this.handlePreviousRisk(risk, enable);
    this.changeSimulationEvent.emit();
  }

  private setFormData(simulation: ICriarSimulacaoPlanoSiteResponse) {
    if (!simulation) {
      return;
    }

    this.hasFormUpdate = true;

    const benefit = simulation.simulacao.beneficio;
    const risk = benefit.riscos[0];

    const companyMonthlyContribution = benefit.previdencia.valoresEmpregador?.valorContribuicaoMensal;

    if (companyMonthlyContribution || companyMonthlyContribution === 0) {
      this.form.patchValue({
        companyMonthlyContribution,
      });
    }

    this.form.patchValue({
      personalMonthlyContribution: benefit.previdencia.valoresProponente.valorContribuicaoMensal || 0,
      deathContribution: risk?.morte?.valorContribuicaoMensal || 0,
      deathCapital: risk?.morte?.valorCapitalSegurado || 0,
      invalidityContribution: risk?.invalidez?.valorContribuicaoMensal || 0,
      invalidityCapital: risk?.invalidez?.valorCapitalSegurado || 0,
      totalPersonalMonthlyContribution:
        (benefit.previdencia.valoresProponente.valorContribuicaoMensal || 0) +
        (risk?.morte?.valorContribuicaoMensal || 0) +
        (risk?.invalidez?.valorContribuicaoMensal || 0),
      futureIncome: benefit.previdencia.totalPrevidencia.valorRendaPrazoIndeterminado || 0,
      futureTotalBalance: benefit.previdencia.totalPrevidencia.valorSaldoFuturo || 0,
    });
  }

  private handlePreviousRisk(risk: 'death' | 'invalidity', switchEnable: boolean) {
    const formControlNames =
      risk === 'death' ? ['deathContribution', 'deathCapital'] : ['invalidityContribution', 'invalidityCapital'];

    if (switchEnable) {
      this.retrievePreviousRisk(formControlNames);
    } else {
      this.savePreviousRisk(formControlNames);
    }
  }

  private savePreviousRisk(formControlNames: string[]) {
    formControlNames.forEach(formControlName => {
      this.previousRisk[formControlName] = this.form.controls[formControlName].value;
    });
  }

  private retrievePreviousRisk(formControlNames: string[]) {
    formControlNames.forEach(formControlName => {
      this.form.controls[formControlName].setValue(this.previousRisk[formControlName]);
      this.previousRisk[formControlName] = 0;
    });
  }
}
