/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slider-range',
  templateUrl: './slider-range.component.html',
  styleUrls: ['./slider-range.component.scss'],
})
export class SliderRangeComponent {
  @Input()
  public maximumValue: number;

  @Input()
  public minimumValue: number;

  @Input()
  public customClass = 'green-slider';

  @Input()
  public set lineColor(color: string) {
    this._lineColor = color;
    this.updateSliderProgressBackground(this.value);
  }

  public get lineColor(): string {
    return this._lineColor;
  }

  @Output()
  public valueChange = new EventEmitter<number>();

  public progressIndicator: string;

  public get value(): number {
    return this._value;
  }

  @Input()
  public set value(value: number) {
    this._value = value;
    this.valueChange.emit(value);
    this.updateSliderProgressBackground(value);
  }

  private _value: number;
  private _lineColor = '#98bf3e';

  public constructor() {
    this.minimumValue = 0;
    this.maximumValue = 100;
  }

  private updateSliderProgressBackground(value: number) {
    const progress = (100 / (this.maximumValue - this.minimumValue)) * (value - this.minimumValue);
    this.progressIndicator = `linear-gradient(90deg, ${this.lineColor} 0%, ${this.lineColor} ${progress}%, #d9d9d9 ${progress}%, #d9d9d9 100%)`;
  }
}
