<div class="chart d-flex row">
  <div class="values col-xs-12 col-sm-8 pr-lg-3">
    <div class="positive-values justify-content-center d-flex">
      <div *ngFor="let item of labels" class="positive-items mt-auto mx-2">
        <div *ngIf="getBarHeight(item) <= 70" class="line mb-3">
          <div style="margin-top: auto">
            <div [style.color]="item.color" class="line-value mx-1">{{ item?.value | number: '1.1-2' }}%</div>
          </div>
        </div>
        <div
          [style.backgroundColor]="item?.value >= 0 ? item.color : 'transparent'"
          [style.height.px]="getBarHeight(item)"
          class="d-flex bar line mt-auto"
        >
          <div *ngIf="getBarHeight(item) > 70" style="margin-top: auto" [style.color]="'#FFFFFF'">
            <div style="margin-bottom: 25px !important" class="line-value mx-1">
              {{ item?.value | number: '1.1-2' }}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-line"></div>
    <div class="negative-values justify-content-center d-flex" *ngIf="hasNegative">
      <div *ngFor="let item of labels" class="negative-items mx-2">
        <div class="line" [style.backgroundColor]="item?.value < 0 ? item.color : 'transparent'" [style.height.px]="getBarHeight(item, item.value < 0)"></div>
      </div>
    </div>
    <div class="chart-legend mt-4">
      {{ chartDescription }}
    </div>
  </div>
  <div class="legend col-xs-12 col-sm-4">
    <div class="legend-description mb-4">
      {{ legendDescription }}
    </div>
    <ul class="list pl-3">
      <li
        class="item-label d-flex align-items-center"
        tooltipClass="chart-tooltip"
        [ngbTooltip]="item?.description"
        *ngFor="let item of labels"
      >
        <span class="dot mr-1" [style.backgroundColor]="item.color"></span>
        {{ item?.label }}
      </li>
    </ul>
  </div>
</div>
