import { GroupMixPanelEnum } from '@shared/mixpanel/enums/group-mixpanel-enum';
import { EventMixPanel } from '@shared/mixpanel/event-mixpanel';

export class GenericSimulatorEvent extends EventMixPanel<void> {
  public constructor(event: string, props?: any) {
    if (props) {
      delete props.eventName;
      super(GroupMixPanelEnum.simulador, event, props);
    } else {
      super(GroupMixPanelEnum.simulador, event);
    }
  }
}
