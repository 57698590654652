<form [formGroup]="form">
<div class="row title bg-primary rounded text-white d-flex flex-column justify-content-center ml-4 mr-5 my-3 pl-4">
  <div class="font-large-1 font-weight-500">{{ 'planSimulation.welcome' | translate }}</div>
  <div class="font-medium-1 font-weight-500 mt-1">{{ 'planSimulation.description' | translate }}</div>
</div>
<div class="position-absolute fixed-top mt-5 d-flex justify-content-end header-image">
  <img src="assets/images/pri-ok-simulacao-rapida.png"  alt="Pri fazendo sinal de ok">
</div>
<div class="row mx-3">
  <div class="col-xs-12 col-sm-6 pr-0">
    <app-simulation-header></app-simulation-header>
    <div class="row mt-3" *ngIf="!simulation">
      <div class="col-xs-12 col-sm-6">
        <label for="birthdate" class="d-block font-weight-500 font-small-3">{{ 'common.birthdate' | translate }}</label>
        <input  formControlName="birthdate" id="birthdate" class="w-100 input-white-darker-border rounded input-line-height pl-2" mask="d0/M0/0000" [dropSpecialCharacters]="false" [leadZeroDateTime]="true">
      </div>
      <div class="col-xs-12 col-sm-6">
        <label for="income" class="d-block font-weight-500 font-small-3">{{ 'common.monthlyIncome' | translate }}</label>
        <input formControlName="income" id="income" class="w-100 input-white-darker-border rounded input-line-height pl-2" prefix="R$ " mask="separator.2" thousandSeparator="." separatorLimit="10000000">
        <span *ngIf="form.controls.income.touched && form.controls.income.invalid" class="font-small-1 text-danger">Informe valor entre R$0 e R$10.000.000</span>
      </div>
    </div>
    <div class="font-small-2 font-weight-500 mt-2"><span class="text-danger">* </span>{{ 'planSimulation.ageWarning' | translate }}</div>
    <div class="my-4" *ngIf="simulation">
      <app-simulation-result (changeSimulationEvent)="changeSimulation()">
      </app-simulation-result>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 pl-4 mb-4" *ngIf="simulation">
    <app-simulation-investment-profile (changeSimulationEvent)="changeSimulation()">
    </app-simulation-investment-profile>
    <button class="btn btn-primary w-100 mt-3 font-small-3" (click)="hire()">{{ 'planSimulation.buttons.hire' | translate }}</button>
    <button class="btn btn-outline w-100 mt-3 font-small-3" (click)="sendEmail()">{{ 'planSimulation.buttons.receiveEmail' | translate }}</button>
    <button class="btn btn-outline w-100 mt-3 font-small-3" (click)="startFullSimulation()">{{ 'planSimulation.buttons.fullSimulation' | translate }}</button>
    <button class="btn btn-outline w-100 mt-3 font-small-3" (click)="redoSimulation()">{{ 'planSimulation.buttons.redoSimulation' | translate }}</button>
  </div>
</div>
<div class="row px-4 mb-4 mt-5 mx-1" *ngIf="!simulation">
  <button class="btn btn-primary col-12 mb-3 py-2 w-100" (click)="simulate()" [disabled]="form.invalid || simulationHeader.form.invalid || isLoading">{{ (isLoading ? 'planSimulation.buttons.simulating' : 'planSimulation.buttons.simulate') | translate }}</button>
</div>
</form>
