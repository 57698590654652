import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { endpoints } from '../../../../api/api.endpoints';
import { BFF_API_URL } from '../../../../api/api.urls';
import { ApiDataResponse } from '../../../../api/data/api-data-response';
import { ParametrizationService } from '../../../../shared/services/parametrization/parametrization.service';
import { IQuestionSimulador } from './models/questionario-simulacao';
import { SimulacaoCalculoRequest } from './models/simulacao-calculo-request';
import { SimulacaoEnviarEmailRequest } from './models/simulacao-enviar-email-request';
import { SimulacaoResponse } from './models/simulacao-response';
import { SimulationParameters } from './models/simulate-parameters';
import { TipoInvestidor } from './models/tipo-investidor';
import { TipoInvestidorRequest } from './models/tipo-investidor-request';

@Injectable({ providedIn: 'root' })
export class SimulatorService {
  private httpClient: HttpClient;

  public constructor(
    httpBackend: HttpBackend,
    @Inject(BFF_API_URL) private readonly apiUrl: string,
    private parametrizationService: ParametrizationService
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async getSimulationParameters(): Promise<SimulationParameters> {
    return this.parametrizationService.getParametersByKey('simulador').then(parameters => parameters.valor);
  }

  public async getInvestorProfile(
    investmentKnowledge: number,
    investmentPreference: number,
    investmentTime: number
  ): Promise<string> {
    const request: TipoInvestidorRequest = {
      conhecimentoEmInvestimento: investmentKnowledge,
      preferenciaEmInvestimento: investmentPreference,
      tempoDeInvestimento: investmentTime,
    };
    const endpoint = `${this.apiUrl}${endpoints.simulador.tipoDeInvestidor}`;
    const response = await this.httpClient.post<ApiDataResponse<TipoInvestidor>>(endpoint, request).toPromise();
    return response.data.nome;
  }

  public async simulateAsync(
    dateOfBirth: string,
    retirementAge: number,
    simulation: {
      futureTotalBalance?: number;
      monthlyIncome?: number;
      monthlyInvestment?: number;
      investorProfile?: string;
    }
  ): Promise<SimulacaoResponse> {
    const request: SimulacaoCalculoRequest = {
      dataNascimento: dateOfBirth,
      idadeAposentadoria: retirementAge,
      dadosSimulador: {
        investimentoMensal: simulation.monthlyInvestment,
        rendaMensal: simulation.monthlyIncome,
        saldoTotalFuturo: simulation.futureTotalBalance,
        tipoInvestidor: simulation.investorProfile,
      },
    };
    const endpoint = `${this.apiUrl}${endpoints.simulador.simular}`;
    const response = await this.httpClient.post<ApiDataResponse<SimulacaoResponse>>(endpoint, request).toPromise();
    return response.data;
  }

  public async sendSimulationResultToEmail(simulacao: SimulacaoResponse, nome: string, email: string): Promise<void> {
    const request: SimulacaoEnviarEmailRequest = {
      simulacao,
      nome,
      email,
    };
    const endpoint = `${this.apiUrl}${endpoints.simulador.enviarEmail}`;
    await this.httpClient.post(endpoint, request).toPromise();
  }

  public getQuiz(idQuestionario: string = '4'): Promise<ApiDataResponse<IQuestionSimulador>> {
    return this.httpClient.get<any>(`${this.apiUrl}${endpoints.simulador.questionario}/${idQuestionario}`).toPromise();
  }

  public async getInvestorProfileV2(pontuation: number): Promise<TipoInvestidor> {
    const response = await this.httpClient
      .get<ApiDataResponse<TipoInvestidor>>(`${this.apiUrl}${endpoints.simuladorV2.tipoDeInvestidor}/${pontuation}`)
      .toPromise();
    return response.data;
  }
}
