import { OnInit, ViewChild, Component, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { SimulatorService } from '@pages/home/services/simulator/simulator.service';
import { TranslateService } from '@ngx-translate/core';
import { SimulationParameters } from '@pages/home/services/simulator/models/simulate-parameters';
import { TipoInvestidor } from '@pages/home/services/simulator/models/tipo-investidor';
import { RegexEnum, NO_WHITESPACE_REGEX, EMAIL_REGEX, CPF_REGEX } from '@shared/validations/regex.enum';
import { scrollBottom, parseCurrency } from '@shared/functions/functions';
import { MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from '@shared/constants';
import { cloneDeep } from 'lodash';
import {
  IQuestion,
  IQuestionSimulador,
  InputTypes,
  InputTypesEnum,
  Answer,
  InputProps,
} from '@pages/home/services/simulator/models/questionario-simulacao';
import { EventsMixPanelEnum } from '@shared/mixpanel/enums/events-mixpanel-enum';
import { MixPanelService } from '@shared/services/mix-panel/mix-panel.service';
import { GenericSimulatorEvent } from '@shared/mixpanel/events/simulator-events';
import { SimulacaoResponse } from '@pages/home/services/simulator/models/simulacao-response';
import { AnswerBackButtonService } from '@pages/home/components/chat/answer/answer-back-button.service';
import {
  BASE_KEY,
  NAME_QUESTION_INDEX,
  NAME_QUESTION_ID,
  EMAIL_QUESTION_INDEX,
  AGE_QUESTION_INDEX,
  AGE_QUESTION_ID,
  PAYMENT_QUESTION_ID,
  SIMULATION_RESULT_ID,
  mixPanelEventsByQuestionId,
  EMAIL_QUESTION_ID,
  CPF_QUESTION_ID,
  INVESTIMENTS_QUESTION_ID,
  INVESTIMENTS_QUESTION_INDEX,
  HAS_ACCOUNT_ID,
  HAS_ACCOUNT_INDEX,
} from './questions.enum';
import { TiposDeInvestimentoNomeEnum } from '@shared/services/investment/models/perfis-de-investimento.enum';
import { validateCpf } from '@shared/validations/cpf.validation';

@Component({
  selector: 'app-investor-profile-chat',
  templateUrl: './investor-profile-chat.component.html',
  styleUrls: ['./investor-profile-chat.component.scss'],
})
export class InvestorProfileChatComponent implements OnInit {
  @Output() public simulationEvent = new EventEmitter();

  public askedQuestions: IQuestion[];
  public emailQuestions: IQuestion[];
  public investorProfile: TipoInvestidor;
  public isEmailQuestion = false;
  public isAnswerQuiz = false;
  public quiz: IQuestionSimulador;

  private fetchedQuiz: IQuestionSimulador;
  private simulationResponse: SimulacaoResponse;
  private simulationParameters: SimulationParameters;
  private triedLead = false;
  private retirementAgeFixed = 65;

  public constructor(
    private readonly simulatorService: SimulatorService,
    private readonly translateService: TranslateService,
    private readonly mixPanelService: MixPanelService,
    private readonly backButton: AnswerBackButtonService
  ) {
    this.backButton.listner().subscribe(_ => {
      this.isEmailQuestion = false;
      this.triedLead = false;
    });
  }

  public async ngOnInit() {
    this.simulationParameters = await this.simulatorService.getSimulationParameters();
    this.fetchedQuiz = (await this.simulatorService.getQuiz()).data;
    this.quiz = cloneDeep(this.fetchedQuiz);

    this.bringNextQuestion();
  }

  public async setAnswer(answer: Answer) {
    const questionIndex = this.quiz.questoes.findIndex(question => question.idQuestao === answer.questionId);
    this.askedQuestions[questionIndex].answer = answer;
    this.trackQuestionEvent(this.askedQuestions[questionIndex]);

    await this.bringNextQuestion();

    setTimeout(() => scrollBottom('chat-tab'), 50);
  }

  public async simulate() {
    this.trackSimulationEvent(EventsMixPanelEnum.resultadoDaSimulacao);

    const event = {
      retirementAge: Number(this.retirementAgeFixed),
      age: Number(this.getAnswer(AGE_QUESTION_ID)),
      monthlyIncome: parseCurrency(this.getAnswer(PAYMENT_QUESTION_ID)),
      investorProfile: TiposDeInvestimentoNomeEnum.CONSERVADOR,
      personalInfo: {
        cpf: this.getAnswer(CPF_QUESTION_ID).replace(/[^\d]/g, ''),
        name: this.getAnswer(NAME_QUESTION_ID),
        email: this.getAnswer(EMAIL_QUESTION_ID),
      },
      providerAccount: this.getAnswer(HAS_ACCOUNT_ID)?.toLowerCase(),
      investmentKnowledge: this.getAnswer(INVESTIMENTS_QUESTION_ID)?.toLowerCase(),
    };

    this.simulationEvent.emit(event);
  }

  public restartChat() {
    this.trackSimulationEvent(EventsMixPanelEnum.queroRefazer);

    this.setAlreadyAnswered(NAME_QUESTION_INDEX);
    this.setAlreadyAnswered(AGE_QUESTION_INDEX);
    this.setAlreadyAnswered(EMAIL_QUESTION_INDEX);

    this.quiz.questoes = [...this.quiz.questoes.slice(0, 3), ...cloneDeep(this.fetchedQuiz.questoes)];
    this.askedQuestions = [] as IQuestion[];
    this.investorProfile = null;
    this.isAnswerQuiz = false;
    this.isEmailQuestion = false;

    this.bringNextQuestion();
  }

  public cancelChat(simulationResponse: SimulacaoResponse) {
    if (this.triedLead || (this.isEmailQuestion && !simulationResponse)) {
      this.resetChat();

      return true;
    }
    const answeredAllQuestions = this.askedQuestions.length === this.quiz.questoes.length;

    if (answeredAllQuestions || !simulationResponse) {
      this.trackSimulationEvent(EventsMixPanelEnum.abandonou);
      return true;
    }

    return true;
  }

  public async sendEmail(email: string) {
    this.trackSimulationEvent(EventsMixPanelEnum.receberPorEmail);
    this.triedLead = true;
    this.bringEmailReaction();
    this.isEmailQuestion = true;
    const name = this.getAnswer(NAME_QUESTION_ID);
    await this.simulatorService.sendSimulationResultToEmail(this.simulationResponse, name, email);
  }

  public openEmailChat(simulationResponse: SimulacaoResponse, key: string) {
    this.simulationResponse = simulationResponse;
    this.isEmailQuestion = false;
    const email = this.getAnswer(EMAIL_QUESTION_ID);
    this.sendEmail(email);
  }

  private async bringNextQuestion() {
    await this.processBetweenQuestions();
    this.getQuestion();
  }

  private resetChat() {
    this.quiz.questoes = cloneDeep(this.fetchedQuiz.questoes);
    this.askedQuestions = [] as IQuestion[];
    this.investorProfile = null;
    this.simulationResponse = null;

    this.isEmailQuestion = false;
    this.triedLead = false;
    this.isAnswerQuiz = false;

    this.bringNextQuestion();
  }

  private getQuestion() {
    const quizIndex = this.askedQuestions?.length || 0;

    const nextQuestion = cloneDeep(this.quiz.questoes.slice(quizIndex, quizIndex + 1));
    this.askedQuestions = [...this.askedQuestions, ...nextQuestion];
  }

  private async processBetweenQuestions() {
    const quizIndex = this.askedQuestions?.length || 0;

    switch (quizIndex) {
      case NAME_QUESTION_INDEX:
        this.bringNameQuestion();
        this.askedQuestions = [];
        break;

      case EMAIL_QUESTION_INDEX:
        this.bringEmailQuestion();
        break;

      case AGE_QUESTION_INDEX:
        this.bringAgeQuestion();
        break;

      case AGE_QUESTION_INDEX + 1:
        const extraMessage = this.handleAgeAnswerReaction();
        this.quiz.questoes[AGE_QUESTION_INDEX + 1].extraMessages = [extraMessage];

        this.bringMonthlyIncomeQuestion();
        break;

      case HAS_ACCOUNT_INDEX + 1:
        this.bringCpfQuestion();
        break;

      case INVESTIMENTS_QUESTION_INDEX + 1:
        const extraMessageInvestments = this.handleAboutInvestimentsAnswerReaction();
        this.quiz.questoes[INVESTIMENTS_QUESTION_INDEX + 1].extraMessages = [extraMessageInvestments];
        break;

      case this.quiz.questoes.length:
        this.bringSimulationResult();
        break;
    }
  }

  private bringNameQuestion() {
    if (this.alreadyAnsweredQuestion(NAME_QUESTION_INDEX)) {
      return;
    }

    const greeting = this.handleGreeting();
    const keys = ['greeting', 'question', 'placeholder'];
    const baseKey = `${BASE_KEY}.input-name`;
    const [greetingMessage, question, placeholder] = this.translateData(baseKey, keys, { greeting });

    const extraMessages = cloneDeep(this.translateService.instant(`${baseKey}.extra-messages`));

    if (Array.isArray(extraMessages)) {
      extraMessages.unshift(greetingMessage);
    }

    const inputValidations = [
      Validators.required,
      Validators.pattern(RegexEnum.ONLY_LETTERS),
      Validators.pattern(NO_WHITESPACE_REGEX),
      Validators.minLength(MIN_TEXT_LENGTH),
      Validators.maxLength(MAX_TEXT_LENGTH),
    ];

    const nameQuestion = {
      idQuestao: NAME_QUESTION_ID,
      pergunta: question,
      extraMessages,
      inputProps: {
        inputType: InputTypesEnum.text,
        validations: inputValidations,
        placeholder,
      } as InputProps,
    };

    this.quiz.questoes.unshift(nameQuestion);
  }

  private handleGreeting() {
    let translateKey = `${BASE_KEY}.input-name.good-`;
    const hours = new Date().getHours();

    if (hours >= 5 && hours < 12) {
      translateKey += 'morning';
    } else if (hours >= 12 && hours < 18) {
      translateKey += 'afternoon';
    } else {
      translateKey += 'night';
    }

    return this.translateService.instant(translateKey);
  }

  private bringAgeQuestion() {
    if (this.alreadyAnsweredQuestion(AGE_QUESTION_INDEX)) {
      return;
    }

    const name = this.getAnswer(NAME_QUESTION_ID);

    const ageQuestion = this.createNumberQuestion(
      AGE_QUESTION_ID,
      InputTypesEnum.typeNumber,
      this.simulationParameters.simulationAgeMin,
      this.simulationParameters.simulationAgeMax,
      'input-age',
      { name }
    );

    this.quiz.questoes.splice(2, 0, ageQuestion);
  }

  private bringEmailQuestion() {
    if (this.alreadyAnsweredQuestion(EMAIL_QUESTION_INDEX)) {
      return;
    }

    const baseKey = `${BASE_KEY}.input-email.tried-lead`;
    const keys = [`${baseKey}.question`, `${BASE_KEY}.input-email.placeholder`];

    const translatedValues = this.translateService.instant(keys, {});
    const [question, placeholder] = Object.values(translatedValues);

    const inputValidations = [
      Validators.required,
      Validators.pattern(EMAIL_REGEX),
      Validators.minLength(MIN_TEXT_LENGTH),
      Validators.maxLength(MAX_TEXT_LENGTH),
    ];

    const emailQuestion = {
      idQuestao: EMAIL_QUESTION_ID,
      pergunta: String(question),
      inputProps: {
        inputType: InputTypesEnum.text,
        validations: inputValidations,
        placeholder,
      } as InputProps,
    };

    this.quiz.questoes.splice(1, 0, emailQuestion);
  }

  private handleAgeAnswerReaction() {
    let translateKey = `${BASE_KEY}.input-age.reaction-`;
    const age = Number(this.getAnswer(AGE_QUESTION_ID));

    if (age <= 30) {
      translateKey += 'young';
    } else if (age <= 45) {
      translateKey += 'adult';
    } else {
      translateKey += 'elderly';
    }

    return this.translateService.instant(translateKey);
  }

  private handleAboutInvestimentsAnswerReaction() {
    let translateKey = `${BASE_KEY}.input-investiments.reaction-`;
    const idAnswer = Number(this.getIdAnswer(INVESTIMENTS_QUESTION_ID));

    if (idAnswer === 1) {
      translateKey += 'know';
    } else if (idAnswer === 2) {
      translateKey += 'risk';
    } else {
      translateKey += 'not-know';
    }

    return this.translateService.instant(translateKey);
  }

  private bringCpfQuestion() {
    const baseKey = `${BASE_KEY}.input-cpf`;
    const keys = [`${baseKey}.question`, `${baseKey}.placeholder`];
    const translatedValues = this.translateService.instant(keys, {});
    const [question, placeholder] = Object.values(translatedValues);
    const inputValidations = [Validators.required, Validators.pattern(CPF_REGEX), validateCpf];

    const cpfQuestion = {
      idQuestao: CPF_QUESTION_ID,
      pergunta: String(question),
      inputProps: {
        inputType: InputTypesEnum.text,
        validations: inputValidations,
        placeholder,
      } as InputProps,
    };

    this.quiz.questoes.splice(6, 0, cpfQuestion);
  }

  private bringMonthlyIncomeQuestion() {
    const monthlyIncomeQuestion = this.createNumberQuestion(
      PAYMENT_QUESTION_ID,
      InputTypesEnum.currency,
      this.simulationParameters.simulationMonthlyIncomeMin,
      this.simulationParameters.simulationMonthlyIncomeMax,
      'input-monthly-income'
    );

    this.quiz.questoes.splice(4, 0, monthlyIncomeQuestion);
  }

  private bringSimulationResult() {
    const name = this.getAnswer(NAME_QUESTION_ID);
    const params = {
      name,
    };

    const keys = ['all-done'];
    const [question] = this.translateData(`${BASE_KEY}.conclusion`, keys, params);

    const questionData = {
      idQuestao: SIMULATION_RESULT_ID,
      pergunta: question,
    };

    this.quiz.questoes.push(questionData);
    this.isAnswerQuiz = true;
  }

  private bringEmailReaction() {
    const baseKey = `${BASE_KEY}.input-email.reaction`;
    const keys = [`${baseKey}.extraMessages`, `${baseKey}.question`];
    const translatedValues = this.translateService.instant(keys);
    const [extraMessages, question] = Object.values(translatedValues);

    const reaction = {
      idQuestao: EMAIL_QUESTION_ID,
      pergunta: String(question),
      extraMessages: [String(extraMessages)],
    };

    this.emailQuestions = [reaction];
  }

  private createNumberQuestion(
    questionId: number,
    inputType: InputTypes,
    min: number,
    max: number,
    translateKey: string,
    translateParams: Record<string, string> = {}
  ) {
    const keys = ['question', 'placeholder'];
    const [question, placeholder] = this.translateData(`${BASE_KEY}.${translateKey}`, keys, translateParams);

    const inputValidations =
      inputType === InputTypesEnum.typeNumber
        ? [Validators.required, Validators.pattern(RegexEnum.ONLY_NUMBERS), Validators.min(min), Validators.max(max)]
        : [Validators.required];

    const questionData = {
      idQuestao: questionId,
      pergunta: question,
      inputProps: {
        inputType,
        validations: inputValidations,
        placeholder,
      } as InputProps,
    };

    if (inputType === InputTypesEnum.currency) {
      questionData.inputProps.min = min;
      questionData.inputProps.max = max;
    }

    return questionData;
  }

  private getAnswer(questionId: number) {
    const questionIndex = this.askedQuestions?.findIndex(question => question?.idQuestao === questionId);
    const value = this.askedQuestions?.[questionIndex]?.answer.texto;

    return value;
  }

  private getIdAnswer(questionId: number) {
    const questionIndex = this.askedQuestions?.findIndex(question => question?.idQuestao === questionId);
    const value = this.askedQuestions?.[questionIndex]?.answer.idOpcao;

    return value;
  }

  private alreadyAnsweredQuestion(questionIndex: number): boolean {
    return (this.quiz?.questoes[questionIndex] || {})?.inputProps?.defaultValue ? true : false;
  }

  private setAlreadyAnswered(questionIndex: number) {
    this.quiz.questoes[questionIndex].inputProps.defaultValue = this.askedQuestions[questionIndex].answer.texto;
  }

  private trackSimulationEvent(event: EventsMixPanelEnum, question?: Record<string, any>) {
    this.mixPanelService.track(new GenericSimulatorEvent(event, question));
  }

  private trackQuestionEvent(question: IQuestion) {
    const questionEvent = mixPanelEventsByQuestionId[Number(question.idQuestao)];

    if (questionEvent) {
      const cleanQuestion = {
        resposta: question.answer.texto,
      };

      this.trackSimulationEvent(questionEvent, cleanQuestion);
    }
  }

  private translateData(baseKey: string, keys: string[], params?: Record<string, string>): string[] {
    const newKeys = keys.map(value => `${baseKey}.${value}`);
    const translatedValues = this.translateService.instant(newKeys, params);

    return Object.values(cloneDeep(translatedValues));
  }
}
