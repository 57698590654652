import { ChangeDetectorRef, Component } from '@angular/core';
import { NeoAssistService } from '@shared/services/neo-assist/neo-assist.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent {
  public constructor(private changeDetector: ChangeDetectorRef, private neoAssistService: NeoAssistService) {}

  public onContentLoaded() {
    this.changeDetector.detectChanges();
    this.neoAssistService.initialize({ tag: 'tag_vfixa' });
  }
}
