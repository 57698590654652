<div
  #explore
  class="explore border-left-blue-grey border-left-lighten-4 d-block"
  [style.right.px]="right"
  [style.width.px]="width"
  [ngClass]="{ open: isOpen }"
  [@toggleExploreAnimation]="{ value: state, params: { right: right + 'px' } }"
  (@toggleExploreAnimation.start)="onOpenAnimationStart($event)"
  (@toggleExploreAnimation.done)="onOpenAnimationDone($event)"
>
  <a *ngIf="showCloseButton" class="explore-close" (click)="closeExplore(true)">
    <i class="ft-x font-medium-3" aria-hidden="true"></i>
  </a>

  <a
    class="explore-toggle-content"
    id="explore-toggle-icon"
    [@exploreToggleAnimation]="state"
    [ngClass]="{ 'explore-toggle-content-open': isOpen }"
    (click)="toggleExplore()"
  >
    <div class="explore-toggle">
      <i
        aria-hidden="true"
        class="explore-icon ft-x align-middle"
        [ngClass]="{ 'ft-compass': !isOpen, 'ft-x': isOpen, 'explore-icon-animation': !isOpen }"
      ></i>
    </div>
    <span class="explore-toggle-text" [@exploreTextAnimation]="state">{{ 'exploreComponent.tryIt' | translate }}</span>
  </a>

  <ng-content class="w-100 h-100"></ng-content>
</div>
