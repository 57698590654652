<div class="d-flex flex-column">
  <app-wordpress-container #wordpress (ready)="onContentLoaded()">
    <div>
      <app-wordpress-content [name]="'navbar'"></app-wordpress-content>
      <app-wordpress-content #content [category]="pageCategory" [name]="pageName" [titleSearchPage]="pageName"></app-wordpress-content>
      <app-wordpress-content #footer [name]="'footer'"></app-wordpress-content>
      <app-explore (opening)="onExploreOpen()" [hidden]="pageCategory !== 'landing-page' && router.url !== '/simulador'">
        <app-simulator-chat></app-simulator-chat>
      </app-explore>
    </div>
  </app-wordpress-container>
  <app-version *ngIf="wordpress.displayContent"></app-version>
</div>
