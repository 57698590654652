import { ChangeDetectorRef, Component } from '@angular/core';
import { NeoAssistService } from '../../shared/services/neo-assist/neo-assist.service';

@Component({
  selector: 'app-ombudsman',
  templateUrl: './ombudsman.component.html',
})
export class OmbudsmanComponent {
  public constructor(private changeDetector: ChangeDetectorRef, private neoAssistService: NeoAssistService) {}

  public onContentLoaded() {
    this.changeDetector.detectChanges();
    this.neoAssistService.initialize({ domain: 'quantaouvidoria.neoassist.com', tag: 'tag_vouvidoriafixa' });
  }
}
