<div class="row ml-3 mr-1">
  <div class="col-1 p-1 d-flex align-items-center justify-content-center">
    <img
      class="avatar"
      src="assets/images/pri-principal.svg"
      alt="Pri 3D"
      [hidden]="!showAvatar"
    />
  </div>
  <div class="col-11" [ngClass]="isQuestion  ? 'pt-1' : 'pt-3 pb-3'">
    <div
      class="message"
      [ngClass]="isQuestion  ? 'question' : 'answer'"
    >
    <span [innerHTML]="message"></span>
    </div>
  </div>
</div>
