<div>
  <p>{{ 'planSimulation.experimentInvestmentProfile' | translate }}</p>
  <div class="my-5" *ngIf="isLoading">
    <app-loader></app-loader>
  </div>
  <div class="my-4">
    <app-button-group
      [options]="groupOptions"
      [selectedOption]="{ id: form?.controls?.investmentProfile?.value?.id }"
      (optionSelected)="onClickInvestmentProfile($event)"
      [customOptionClass]="selectedSimulation === simulationType.fast ? 'button-group-green-base-body-bg' : 'button-group-purple-base-body-bg'"
      [disabled]="isLoading"
    >
    </app-button-group>
  </div>
  <p>{{ profilesDescription[form?.controls?.investmentProfile?.value?.id] || '' }}</p>
</div>
