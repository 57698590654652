import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TiposDeInvestimentoNomeEnum } from '@shared/services/investment/models/perfis-de-investimento.enum';
import { PerfisDeInvestimentosNomesEnum } from '@quantaprevidencia/quanta-domains';
import { PlanSimulationStoreService } from '@pages/plan-simulation/plan-simulation-store.service';
import { SimulationType } from '@pages/plan-simulation/plan-simulation.enum';
import { Profile } from '@shared/services/plans/models/profiles.response';
import { PlanProfileService } from '@shared/services/plans/plans.service';
import { PlanosEnum } from '@shared/services/investment/models/planos.enum';

@Component({
  selector: 'app-simulation-investment-profile',
  templateUrl: './simulation-investment-profile.component.html',
})
export class SimulationInvestmentProfileComponent implements OnInit {
  @Output()
  public changeSimulationEvent = new EventEmitter<void>();

  public profiles: Profile[];

  public groupOptions: Array<{ id: string; name: string }> = [];

  public profilesDescription = {};

  public form: FormGroup;
  public selectedSimulation: SimulationType;
  public simulationType = SimulationType;
  public isLoading: boolean;

  public constructor(
    private fb: FormBuilder,
    private planSimulationStoreService: PlanSimulationStoreService,
    private planProfileService: PlanProfileService
  ) {
    this.form = this.fb.group({
      investmentProfile: [{ id: TiposDeInvestimentoNomeEnum.MODERADO }, Validators.required],
    });

    this.loadProfiles();
  }

  public ngOnInit() {
    this.planSimulationStoreService.data$.subscribe(data => {
      this.selectedSimulation = data.selectedSimulation;
    });
  }

  public onClickInvestmentProfile(selectedInvestmentProfile: {
    id: TiposDeInvestimentoNomeEnum;
    name?: PerfisDeInvestimentosNomesEnum;
  }) {
    this.form.controls.investmentProfile.setValue(selectedInvestmentProfile);
    this.changeSimulationEvent.emit();
  }

  private async loadProfiles() {
    this.isLoading = true;
    const plano = await this.planProfileService.getPlan(PlanosEnum.COOPREV);
    this.isLoading = false;
    this.profiles = plano.perfis;
    this.groupOptions = this.profiles.map(profile => {
      const profileId = profile.perfilInvestidor.toUpperCase();
      this.profilesDescription[profileId] = profile.descricaoPerfil;
      return {
        id: profileId,
        name: `Perfil ${profile.nomePerfil}`,
      };
    });
  }
}
