<div class="w-100 h-100 d-flex flex-column">
  <div class="w-100 h-100 d-flex flex-column overflow-auto">
    <div class="w-100 h-100 d-flex flex-column">
      <div class="w-100 d-flex flex-column simulator-panel-top-container">
        <label class="simulator-panel-title">{{ title }}</label>
      </div>
      <div class="d-flex flex-column justify-content-center w-100 h-100 simulator-panel-item-container">
        <div class="d-flex flex-wrap">
          <app-simulator-panel-item
            #monthlyIncomeComponent
            containerClass="align-items-center"
            class="simulator-panel-item align-items-center"
            [type]="simulatorPanelItemEnum.currency"
            [title]="'simulation.future-monthly-income' | translate"
            [(value)]="monthlyIncome"
            (valueChange)="onMonthlyIncomeChange($event)"
          >
          </app-simulator-panel-item>
          <app-simulator-panel-item
            #futureTotalBalanceComponent
            containerClass="align-items-center"
            class="simulator-panel-item align-items-center"
            [type]="simulatorPanelItemEnum.currency"
            [title]="'common.futureTotalBalance' | translate"
            [(value)]="futureTotalBalance"
            (valueChange)="onFutureTotalBalanceChange($event)"
          ></app-simulator-panel-item>
          <app-simulator-panel-item
            #retirementAgeComponent
            containerClass="align-items-center"
            class="simulator-panel-item align-items-center"
            [type]="simulatorPanelItemEnum.integer"
            [title]="'simulation.retirement-age' | translate"
            [(value)]="retirementAge"
            (valueChange)="onRetirementAgeChange($event)"
          ></app-simulator-panel-item>
          <app-simulator-panel-item
            #monthlyInvestmentComponent
            containerClass="align-items-center"
            class="simulator-panel-item align-items-center"
            [type]="simulatorPanelItemEnum.currency"
            [title]="'common.monthlyContribution' | translate"
            [(value)]="monthlyInvestment"
            (valueChange)="onmonthlyInvestmentChange($event)"
          ></app-simulator-panel-item>
          <app-simulator-panel-item
            #contributionTimeComponent
            containerClass="align-items-center"
            class="simulator-panel-item align-items-center"
            [type]="simulatorPanelItemEnum.integer"
            [title]="'simulation.contribution-time' | translate"
            [(value)]="contributionTime"
            (valueChange)="onContributionTimeChange($event)"
          ></app-simulator-panel-item>
        </div>
      </div>
    </div>
    <div class="w-100 d-flex flex-column simulator-panel-bottom-container pb-4" *ngIf="profiles">
      <div class="text-center pb-4">
        <label class="simulator-panel-subtitle font-weight-bold">{{ 'simulation.perfis.text' | translate }}</label>
      </div>

      <div class="btn-group btn-group-toggle button-perfis" data-toggle="buttons">
        <label
          *ngFor="let profile of profiles; let first = first"
          [ngClass]="{ active: first }"
          class="btn btn-perfis font-weight-bold"
          (click)="onInvestorProfileChange(profile.perfilInvestidor)"
        >
          <input type="radio" name="options" id="option1" autocomplete="off" checked />
          {{ profile.nomePerfil }}
        </label>
      </div>
    </div>
    <div class="w-100 d-flex flex-column simulator-panel-bottom-container">
      <label class="simulator-panel-subtitle">{{ 'simulation.disclaimer' | translate }}</label>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center simulator-panel-button-container">
    <button class="btn btn-primary" (click)="restartSimulationButtonClick()">Quero refazer</button>
    <button class="btn btn-primary" (click)="hirePlanButtonClick()">Gostei, quero contratar</button>
    <button class="btn btn-primary" (click)="receiveSimulationResultOnEmailButtonClick()">Receber por e-mail</button>
  </div>
</div>
