import { Component, OnInit } from '@angular/core';
import { SimulationType } from '@pages/plan-simulation/plan-simulation.enum';
import { PlanSimulationStoreService } from '@pages/plan-simulation/plan-simulation-store.service';
import { MixPanelService } from '@shared/services/mix-panel/mix-panel.service';
import { FormBuilder, Validators } from '@angular/forms';
import { dayMonthYearLimitValidator } from '@components/forms/date.validator';
import { PlanSimulationPayloadCreator } from '@pages/plan-simulation/plan-simulation-payload-creator';
import { TiposDeInvestimentoNomeEnum } from '@shared/services/investment/models/perfis-de-investimento.enum';
import { formatDateBRToEN } from '@shared/functions/functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseSimulationComponent } from '@pages/plan-simulation/base-simulation.component';
import { EventsMixPanelEnum } from '@shared/mixpanel/enums/events-mixpanel-enum';
import { ParametrizationService } from '@shared/services/parametrization/parametrization.service';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';
import { disableFormOnLoading } from '@shared/functions/functions';

@Component({
  selector: 'app-full-simulation',
  templateUrl: './full-simulation.component.html',
  styleUrls: ['../plan-simulation.component.scss'],
  providers: [PlanSimulationPayloadCreator],
})
export class FullSimulationComponent extends BaseSimulationComponent implements OnInit {
  public constructor(
    private fb: FormBuilder,
    private planSimulationPayloadCreator: PlanSimulationPayloadCreator,
    public planSimulationStoreService: PlanSimulationStoreService,
    public mixPanelService: MixPanelService,
    public modalService: NgbModal,
    public parametrizationService: ParametrizationService,
    public analyticsService: AnalyticsService
  ) {
    super(planSimulationStoreService, mixPanelService, modalService, parametrizationService, analyticsService);

    this.form = this.fb.group({
      companyContribution: [null, [Validators.min(1), Validators.max(10000000)]],
      immediateContribution: [null, [Validators.min(1), Validators.max(100000000)]],
      retirementAge: [null, [Validators.required, Validators.min(18), Validators.max(100)]],
      birthdate: [
        null,
        [
          Validators.required,
          dayMonthYearLimitValidator(this.calculateMinYear(), 'min'),
          dayMonthYearLimitValidator(new Date(), 'max'),
        ],
      ],
      income: [null, [Validators.required, Validators.min(0), Validators.max(10000000)]],
    });
  }

  public ngOnInit() {
    this.mixpanelEvent = EventsMixPanelEnum.gosteiQueroContratarCompleta;
    this.planSimulationStoreService.setData('selectedSimulation', SimulationType.full);

    this.planSimulationStoreService.data$.subscribe(data => {
      this.isLoading = data.isLoading;
      disableFormOnLoading(this.form.controls, this.isLoading);
      this.simulation = data.simulation;
    });
  }

  public simulate() {
    if (this.form.invalid) {
      return;
    }

    const values = this.form.value;
    const birthdate = formatDateBRToEN(this.form.getRawValue().birthdate);
    const defaultRisk = values.income * 60;

    const creator = this.planSimulationPayloadCreator
      .setProponent(Number(values.income), birthdate, TiposDeInvestimentoNomeEnum.MODERADO)
      .setBaseBenefit(Number(values.retirementAge))
      .setEmployerBenefit(values.companyContribution)
      .setProponentBenefit('valorSaldoAtual', values.immediateContribution)
      .setRisk('morte', 'valorCapitalSegurado', defaultRisk)
      .setRisk('invalidez', 'valorCapitalSegurado', defaultRisk);

    const request = creator.create();

    this.lastRequest = request;
    this.changeSimulationEvent.emit(request);
  }

  public changeSimulation() {
    if (!this.simulationResult?.form) {
      return;
    }

    const resultForm = this.simulationResult.form.value;
    const values = this.form.value;
    const birthdate = formatDateBRToEN(this.form.getRawValue().birthdate);
    const investmentProfile = this.getInvestmentProfile();

    let creator = this.planSimulationPayloadCreator
      .setProponent(resultForm.futureIncome, birthdate, investmentProfile)
      .setBaseBenefit(values.retirementAge)
      .setEmployerBenefit(resultForm.companyMonthlyContribution)
      .setProponentBenefit('valorSaldoAtual', values.immediateContribution);
    creator = this.setProponentBenefit(creator);
    creator = this.setRisk(creator, 'death', this.simulationResult.deathSwitch.nativeElement.checked);
    creator = this.setRisk(creator, 'invalidity', this.simulationResult.invaliditySwitch.nativeElement.checked);

    const request = creator.create();

    this.lastRequest = request;
    this.changeSimulationEvent.emit(request);
  }
}
