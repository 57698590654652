export interface IInvestimentoMedicoesSiteRequest {
  idPerfil: string | number;
  idPlano: string | number;
  tipoMedicao: string[];
}
export enum IIndicatorName {
  indice = 'indice',
  rentabilidade = 'rentabilidade',
  cdi = 'cdi',
  poupanca = 'poupanca',
  ipca = 'ipca',
  ibovespa = 'ibovespa',
}
