/* eslint-disable @typescript-eslint/naming-convention */
export enum PlanosEnum {
  PRECAVER = 1,
  PREVCOOP = 3,
  COOPREV = 4,
}

export const PLANS = {
  1: 'PRECAVER',
  3: 'PREVCOOP',
  4: 'COOPREV',
};

export enum DescricaoPlanosEnum {
  PRECAVER = 'Precaver',
  PREVCOOP = 'Prevcoop',
  COOPREV = 'Cooprev',
}

export enum ProviderId {
  UNICRED = 1,
  AILOS = 3,
}
