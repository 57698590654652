import { Component } from '@angular/core';
import { VersionService } from './services/version/version.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent {
  public version: string;

  public constructor(versionService: VersionService) {
    this.version = versionService.version;
  }
}
