import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private readonly preloader: HTMLElement;

  public constructor(@Inject(DOCUMENT) document: Document) {
    this.preloader = document.getElementById('preloader');
  }

  public show() {
    if (this.preloader) {
      this.preloader.style.display = '';
    }
  }

  public hide() {
    if (this.preloader) {
      this.preloader.style.display = 'none';
    }
  }
}
