import { Component, OnInit } from '@angular/core';
import { SimulationType } from '@pages/plan-simulation/plan-simulation.enum';
import { SimuladorPlanoService } from '@shared/services/simulador-plano/simulador-plano.service';
import {
  ICriarSimulacaoPlanoSiteResponse,
  ICriarSimulacaoSiteRequest,
  ISimulacaoSitePayloadEmailRequest,
} from '@quantaprevidencia/quanta-domains';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PlanSimulationStoreService } from '@pages/plan-simulation/plan-simulation-store.service';
import { MIN_MONTHLY_CONTRIBUTION } from '@pages/plan-simulation/plan-simulation.enum';

@Component({
  selector: 'app-plan-simulation',
  templateUrl: './plan-simulation.component.html',
})
export class PlanSimulationComponent implements OnInit {
  public selectedSimulation: SimulationType;
  public isLoading: boolean;

  public constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private simuladorPlanoService: SimuladorPlanoService,
    private planSimulationStoreService: PlanSimulationStoreService
  ) {}

  public ngOnInit() {
    this.planSimulationStoreService.setData('selectedSimulation', SimulationType.fast);

    this.planSimulationStoreService.data$.subscribe(data => {
      this.selectedSimulation = data.selectedSimulation;
      this.isLoading = data.isLoading;
    });
  }

  public async changeSimulation(request: ICriarSimulacaoSiteRequest) {
    this.planSimulationStoreService.setData('isLoading', true);

    await this.simuladorPlanoService
      .simular(request)
      .then(async response => {
        const wasResimulated = await this.handleResimulation(request, response);
        if (wasResimulated) {
          return;
        }

        this.adjustResponse(response, request);
        this.planSimulationStoreService.setData('simulation', response);
      })
      .catch(() => this.showToast('planSimulation.simulationErrorToast', 'error'))
      .finally(() => this.planSimulationStoreService.setData('isLoading', false));
  }

  public sendEmail(payloadRequest: ISimulacaoSitePayloadEmailRequest) {
    this.showToast('planSimulation.emailModal.sendingEmail', 'info');

    this.simuladorPlanoService
      .buildEmailPayload(payloadRequest)
      .then(payload =>
        this.simuladorPlanoService
          .sendEmail(payload)
          .then(() => this.showToast('planSimulation.emailModal.sendEmailSuccess', 'success'))
      )
      .catch(() => this.showToast('planSimulation.emailModal.sendEmailError', 'error'));
  }

  private async handleResimulation(request: ICriarSimulacaoSiteRequest, response: ICriarSimulacaoPlanoSiteResponse) {
    if (response.simulacao.beneficio.previdencia.totalPrevidencia.valorContribuicaoMensal < MIN_MONTHLY_CONTRIBUTION) {
      request.simulacao.beneficio.previdencia = {
        ...request.simulacao.beneficio.previdencia,
        valoresProponente: { valorContribuicaoMensal: MIN_MONTHLY_CONTRIBUTION },
      };
      request.tipoSimulacao = 'Contribuição Mensal';
      await this.changeSimulation(request);
      return true;
    }

    return false;
  }

  private adjustResponse(response: ICriarSimulacaoPlanoSiteResponse, request: ICriarSimulacaoSiteRequest) {
    const proponentRequest = request.simulacao.beneficio?.previdencia?.valoresProponente;
    const companyRequest = request.simulacao.beneficio?.previdencia?.valoresEmpregador;
    const proponentResponse = response.simulacao.beneficio.previdencia.valoresProponente;
    const companyResponse = response.simulacao.beneficio.previdencia.valoresEmpregador;

    if (!proponentRequest?.valorContribuicaoMensal && companyRequest?.valorContribuicaoMensal) {
      proponentResponse.valorContribuicaoMensal =
        proponentResponse.valorContribuicaoMensal - companyRequest?.valorContribuicaoMensal;
      proponentResponse.valorContribuicaoMensal =
        proponentResponse.valorContribuicaoMensal > 0 ? proponentResponse.valorContribuicaoMensal : 0;
    }

    if (!companyResponse) {
      response.simulacao.beneficio.previdencia.valoresEmpregador = {};
    }

    if (companyRequest?.valorContribuicaoMensal) {
      companyResponse.valorContribuicaoMensal = companyRequest?.valorContribuicaoMensal;
    }
  }

  private showToast(key: string, level: 'error' | 'success' | 'info') {
    const { title, description } = this.translateService.instant(key);
    this.toastr.show(description, title, { timeOut: 8000 }, `toast-${level}`);
  }
}
