<div [class]="customClass">
  <input
    #slider
    [style.background]="progressIndicator"
    type="range"
    [min]="minimumValue"
    [max]="maximumValue"
    [(ngModel)]="value"
  />
</div>
