import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  public constructor(private modal: NgbModal) {}

  public setOverlayVisibility(visible: boolean) {
    if (visible) {
      this.show();
    } else {
      this.dismiss();
    }
  }
  public show() {
    this.modal.open(null, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  public dismiss() {
    this.modal.dismissAll();
  }
}
