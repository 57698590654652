import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { endpoints } from '@api/api.endpoints';
import { BFF_API_URL } from '@api/api.urls';
import { PlanosEnum } from '../investment/models/planos.enum';
import { PlanProfile, ProfilesResponse } from './models/profiles.response';

@Injectable({
  providedIn: 'root',
})
export class PlanProfileService {
  private httpClient: HttpClient;

  public constructor(httpBackend: HttpBackend, @Inject(BFF_API_URL) private readonly apiUrl: string) {
    this.httpClient = new HttpClient(httpBackend);
  }

  public async getProfiles(): Promise<ProfilesResponse> {
    return this.httpClient
      .get(`${this.apiUrl}${endpoints.planos.listarPerfis}`)
      .toPromise()
      .then((response: any) => response.data);
  }

  public async getPlan(planId = PlanosEnum.PRECAVER): Promise<PlanProfile> {
    return this.httpClient
      .get(`${this.apiUrl}${endpoints.planos.listarPlanos}?ativo=true&idPlano=${planId}`)
      .toPromise()
      .then((response: any) => response.data[0]);
  }
}
