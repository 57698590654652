<div class="button-group d-flex flex-sm-row flex-column">
  <ng-container *ngFor="let option of options; let index = index">
    <div *ngIf="index" class="divider d-none "></div>
    <div
      class="option flex-grow-1 text-truncate {{ customOptionClass }}"
      [ngClass]="{ 'pointer-events-none': disabled, selected: selectedOption ? option[valueProperty] == selectedOption[valueProperty] : false }"
      (click)="onOptionSelected(option)"
    >
      {{ option[displayProperty] }}
    </div>
  </ng-container>
</div>
