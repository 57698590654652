<form [formGroup]="form">
  <app-button-group
    [options]="groupOptions"
    [selectedOption]="{ id: selectedSimulation }"
    (optionSelected)="setSelectedSimulation($event)"
    [customOptionClass]="'font-medium-1 ' + (selectedSimulation === simulationType.fast ? '' : 'button-group-purple-base')"
  >
  </app-button-group>
  <div class="font-small-3 font-weight-500 mt-3 mb-1">{{ 'planSimulation.selectBond' | translate }}</div>
  <div class="row">
    <span *ngFor="let bond of ['colaborador', 'cooperado', 'familiar']" class="col-xs-12 col-sm-4">
      <input formControlName="selectedBond" type="radio" [id]="bond" class="d-none" [value]="bond">
      <label
        class="btn w-100 font-small-3"
        [for]="bond"
        [ngClass]="{
          'btn-primary': form.value.selectedBond === bond && selectedSimulation === simulationType.fast,
          'btn-outline': form.value.selectedBond !== bond && selectedSimulation === simulationType.fast,
          'btn-purple-base': form.value.selectedBond === bond && selectedSimulation === simulationType.full,
          'btn-outline-purple-base': form.value.selectedBond !== bond && selectedSimulation === simulationType.full
        }"
      >
        {{ 'planSimulation.' + bond | translate }}
      </label>
    </span>
  </div>
</form>
