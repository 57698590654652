import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfitabilityChartComponent } from '@components/profitability-chart/profitability-chart.component';
import { timer } from 'rxjs';
import { ExploreComponent } from './components/explore/explore.component';
import { SimulatorChatComponent } from './components/simulator-chat/simulator-chat.component';
import { IndicatorsPageEnum } from '@shared/services/investment/models/indicators-page.enum';
import { PROJECT_NAME } from '@shared/constants';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild(ExploreComponent, { static: true })
  public explore: ExploreComponent;

  @ViewChild(SimulatorChatComponent, { static: true })
  public simulator: SimulatorChatComponent;

  @ViewChild('content', { read: ElementRef, static: true })
  public content: ElementRef;

  public pageCategory: string;
  public pageName: string;
  public userAgent = navigator.userAgent;

  public get isCooprevPage(): boolean {
    return this.pageName?.toUpperCase()?.includes(IndicatorsPageEnum.COOPREV);
  }

  public constructor(
    public router: Router,
    private readonly route: ActivatedRoute,
    private componentFactory: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  public ngOnInit() {
    window.document.title = PROJECT_NAME;

    this.pageName = this.route.firstChild?.snapshot.paramMap.get('pageName');

    if (!this.pageName) {
      this.pageCategory = 'landing-page';
    }
  }

  public ngAfterViewInit(): void {
    this.explore.closeExploreHandler = () => {
      return this.simulator.cancel();
    };
  }

  public onContentLoaded() {
    this.loadComponent(this.pageName || 'landing-page');
    this.checkSimulatorRoute();
  }

  public onExploreOpen() {
    this.simulator.initialize();
  }

  private loadComponent(pageName: string) {
    if (pageName?.toLowerCase() === 'mobile-quanta') {
      this.redirectToStore();
      return;
    }
    const htmlElement = this.content.nativeElement as HTMLElement;

    const newPageName = this.isCooprevPage ? IndicatorsPageEnum.COOPREV : pageName;

    const selectedElement = htmlElement.querySelector(`#rentabilidade-${newPageName?.toLowerCase()}`) as HTMLElement;

    selectedElement.style.backgroundColor = pageName === 'landing-page' ? '#f5f7fa' : 'white';
    selectedElement.style.borderRadius = '0';
    selectedElement.style.backgroundImage = 'none';
    selectedElement.innerHTML = null;

    const componentFactory = this.componentFactory.resolveComponentFactory(ProfitabilityChartComponent);

    const componentRef = componentFactory.create(this.injector);

    componentRef.instance.page = newPageName;

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    selectedElement.appendChild(domElem);
  }

  private checkSimulatorRoute() {
    const routeUrl = this.route.firstChild?.snapshot.url;
    if (!routeUrl?.length) {
      return;
    }
    const lastUrlSegment = routeUrl[routeUrl.length - 1];
    if (lastUrlSegment.path === 'simulador') {
      timer(400).subscribe(() => {
        this.explore.openExplore();
      });
    }
  }

  private redirectToStore() {
    const userAgent = navigator.userAgent;

    switch (true) {
      case /iPad|iPhone|iPod/.test(userAgent):
        window.location.href = 'https://apps.apple.com/br/app/quanta-previd%C3%AAncia-cooperativa/id1145518121';
        break;
      case /android/i.test(userAgent):
        window.location.href = 'https://play.google.com/store/apps/details?id=br.com.quantaPrevidencia&hl=pt_BR';
        break;
      default:
        window.location.href = `${environment.urlSite}/home/entre`;
        break;
    }
  }
}
