export const MIN_TEXT_LENGTH = 2;
export const MAX_TEXT_LENGTH = 70;
export const PROJECT_NAME = 'Quanta Previdência';

export const ID_CANAL = 11;
export const NOME_CANAL = 'Site';

export const RDS_SIMULATION_EVENT_ID = 157;
export const RDS_SIMULATION_EVENT_TYPE = {
  simulou: 'gerou-simulacao',
  contratar: 'clicou-gostei-quero-contratar',
  email: 'clicou-receber-simulacao-por-email',
  emailCooprev: 'clicou-receber-simulação-cooprev-por-email',
};

export const SITE_QUANTA_CONTRATE_PLANO_URL = 'https://pages.quantaprevidencia.com.br/contrate';
