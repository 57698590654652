<form [formGroup]="form">
  <div class="position-relative">
    <div class="row" [ngClass]="{ 'opacity-disabled': isLoadingSimulation }">
      <div class="col-12 col-md-3 p-1">
        <div class="card p-2 card-height h-100 bg-white-base gray-card-border">
          <span class="font-small-3 font-weight-bold text-black-lighter-30">{{
            'planSimulation.result.foresight' | translate
          }}</span>
          <div class="d-flex flex-column justify-content-end h-100">
            <app-simulator-panel-item
              titleClass="font-small-3"
              valueClass="font-small-3 mt-0"
              [type]="simulatorPanelItemEnum.currency"
              [title]="'planSimulation.result.personalContribution' | translate"
              [value]="form?.controls?.personalMonthlyContribution?.value || 0"
              (valueChange)="onChangeSlider('personalMonthlyContribution', $event)"
              [maximumValue]="100000"
              [minimumValue]="50"
              customSliderClass="dark-slider"
              sliderLineColor="#4D4D4D"
              [disabled]="isLoadingSimulation"
            >
            </app-simulator-panel-item>
            <app-simulator-panel-item
              titleClass="font-small-3"
              valueClass="font-small-3 mt-0"
              class="mt-2"
              [type]="simulatorPanelItemEnum.currency"
              [title]="'planSimulation.result.companyContribution' | translate"
              [value]="form?.controls?.companyMonthlyContribution?.value || 0"
              (valueChange)="onChangeSlider('companyMonthlyContribution', $event)"
              [maximumValue]="100000"
              [minimumValue]="0"
              customSliderClass="dark-slider"
              sliderLineColor="#4D4D4D"
              [disabled]="isLoadingSimulation"
            >
            </app-simulator-panel-item>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 p-1">
        <div class="card p-2 card-height h-100 bg-white-base gray-card-border">
          <div class="d-flex justify-content-between">
            <span class="font-small-3 font-weight-bold text-black-lighter-30">{{
              'planSimulation.result.deathCoverage' | translate
            }}</span>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="deathSwitch"
                #deathSwitch
                (input)="onChangeSwitch('death', deathSwitch.checked)"
                [disabled]="isLoadingSimulation"
                checked
              />
              <label class="custom-control-label" for="deathSwitch"></label>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-end h-100">
            <app-simulator-panel-item
              titleClass="font-small-3"
              valueClass="font-small-3 mt-0"
              [type]="simulatorPanelItemEnum.currency"
              [title]="'common.monthlyContribution' | translate"
              [value]="deathSwitch.checked ? form?.controls?.deathContribution?.value || 0 : 0"
              (valueChange)="onChangeSlider('deathContribution', $event)"
              [maximumValue]="formSimulation.morteMensal.max"
              [minimumValue]="formSimulation.morteMensal.min"
              [disabled]="!deathSwitch.checked || isLoadingSimulation"
              customSliderClass="dark-slider"
              sliderLineColor="#4D4D4D"
            >
            </app-simulator-panel-item>
            <app-simulator-panel-item
              titleClass="font-small-3"
              valueClass="font-small-3 mt-0"
              class="mt-2"
              [type]="simulatorPanelItemEnum.currency"
              [title]="'planSimulation.result.securedCapital' | translate"
              [value]="deathSwitch.checked ? form?.controls?.deathCapital?.value || 0 : 0"
              (valueChange)="onChangeSlider('deathCapital', $event)"
              [maximumValue]="formSimulation.morteCapitalSegurado.max"
              [minimumValue]="formSimulation.morteCapitalSegurado.min"
              [disabled]="!deathSwitch.checked || isLoadingSimulation"
              customSliderClass="dark-slider"
              sliderLineColor="#4D4D4D"
            >
            </app-simulator-panel-item>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 p-1">
        <div class="card p-2 card-height h-100 bg-white-base gray-card-border">
          <div class="d-flex justify-content-between">
            <span class="font-small-3 font-weight-bold text-black-lighter-30">{{
              'planSimulation.result.invalidityCoverage' | translate
            }}</span>
            <span class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="invaliditySwitch"
                #invaliditySwitch
                (input)="onChangeSwitch('invalidity', invaliditySwitch.checked)"
                [disabled]="isLoadingSimulation"
                checked
              />
              <label class="custom-control-label" for="invaliditySwitch"></label>
            </span>
          </div>
          <div class="d-flex flex-column justify-content-end h-100">
            <app-simulator-panel-item
              titleClass="font-small-3"
              valueClass="font-small-3 mt-0"
              [type]="simulatorPanelItemEnum.currency"
              [title]="'common.monthlyContribution' | translate"
              [value]="invaliditySwitch.checked ? form?.controls?.invalidityContribution?.value || 0 : 0"
              (valueChange)="onChangeSlider('invalidityContribution', $event)"
              [maximumValue]="formSimulation.invalidezMensal.max"
              [minimumValue]="formSimulation.invalidezMensal.min"
              [disabled]="!invaliditySwitch.checked || isLoadingSimulation"
              customSliderClass="dark-slider"
              sliderLineColor="#4D4D4D"
            >
            </app-simulator-panel-item>
            <app-simulator-panel-item
              titleClass="font-small-3"
              valueClass="font-small-3 mt-0"
              class="mt-2"
              [type]="simulatorPanelItemEnum.currency"
              [title]="'planSimulation.result.securedCapital' | translate"
              [value]="invaliditySwitch.checked ? form?.controls?.invalidityCapital?.value || 0 : 0"
              (valueChange)="onChangeSlider('invalidityCapital', $event)"
              [maximumValue]="formSimulation.invalidezCapitalSegurado.max"
              [minimumValue]="formSimulation.invalidezCapitalSegurado.min"
              [disabled]="!invaliditySwitch.checked || isLoadingSimulation"
              customSliderClass="dark-slider"
              sliderLineColor="#4D4D4D"
            >
            </app-simulator-panel-item>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 p-1">
        <div
          class="card p-2"
          [ngClass]="
            selectedSimulation === simulationType.fast
              ? 'bg-green-lighter-100 green-card-border'
              : 'bg-purple-light-10 purple-card-border'
          "
        >
          <span class="font-small-2 font-weight-bold text-center text-black-lighter-30">
            {{ 'common.result' | translate | uppercase }}
          </span>
          <div class="text-center font-small-3 mt-3">
            <div>
              {{ 'planSimulation.result.totalPersonalContribution' | translate }}
            </div>
            <div>R$ {{ form?.controls?.totalPersonalMonthlyContribution?.value?.toFixed(2) || 0 }}</div>
          </div>
          <app-simulator-panel-item
            titleClass="font-small-3 d-flex justify-content-center"
            valueClass="font-small-3 mt-0 justify-content-center"
            class="mt-2"
            [type]="simulatorPanelItemEnum.currency"
            [title]="'planSimulation.result.futureIncome' | translate"
            [value]="form?.controls?.futureIncome?.value || 0"
            (valueChange)="onChangeSlider('futureIncome', $event)"
            [maximumValue]="100000"
            [minimumValue]="500"
            [customSliderClass]="selectedSimulation === simulationType.fast ? 'green-slider' : 'purple-slider'"
            [sliderLineColor]="selectedSimulation === simulationType.fast ? '#98BF3E' : '#6B4DFF'"
            [disabled]="isLoadingSimulation"
          >
          </app-simulator-panel-item>

          <div
            #ngbTooltip
            class="position-absolute right-0 tooltip-margin mr-2"
            [ngbTooltip]="'planSimulation.result.futureIncomeTooltip' | translate"
            [autoClose]="false"
            [tooltipClass]="'tooltip'"
          >
            <i class="ft-info" aria-hidden="true"></i>
          </div>
          <app-simulator-panel-item
            titleClass="font-small-3 d-flex justify-content-center"
            valueClass="font-small-3 mt-0 justify-content-center"
            class="mt-2"
            [type]="simulatorPanelItemEnum.currency"
            [title]="'common.futureTotalBalance' | translate"
            [value]="form?.controls?.futureTotalBalance?.value || 0"
            (valueChange)="onChangeSlider('futureTotalBalance', $event)"
            [maximumValue]="100000000"
            [minimumValue]="1"
            [customSliderClass]="selectedSimulation === simulationType.fast ? 'green-slider' : 'purple-slider'"
            [sliderLineColor]="selectedSimulation === simulationType.fast ? '#98BF3E' : '#6B4DFF'"
            [disabled]="isLoadingSimulation"
          >
          </app-simulator-panel-item>
        </div>
      </div>

    </div>
    <app-loader-spinner loaderClass="black-loader bg-white-base" *ngIf="isLoadingSimulation"></app-loader-spinner>
  </div>
</form>
