import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnswerBackButtonService {
  private event = new BehaviorSubject<boolean>(false);

  public constructor() {}

  public emiter(value: boolean) {
    this.event.next(value);
  }

  public listner() {
    return this.event.asObservable();
  }
}
